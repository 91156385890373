//aca es un componene te donde va  a ir el grafico de velocimetro en base a las notificaciones
import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { Dropdown, Button } from "react-bootstrap";
import { getListOfAllNotifications,getDetailsNotification } from "../../../store/slices/notifications/notificationThunks";
import {Spinner} from "react-bootstrap";

//apexcharts
import Chart from "react-apexcharts";
const SpeedoMeter = () => {

  const dispatch = useDispatch();

  const { idTenant } = useSelector((state) => state.costing);
  const { listOfNotifications,detailNotification,notificationState,actualValueAmount } = useSelector((state) => state.notifications);

 
// va a buscar el listado de notificaciones activas para ponerlo en el dropdown
  useEffect(() => {
    
    dispatch(getListOfAllNotifications({tenantid:idTenant}));

  }, [])

  

  const [notificationSelected, setSelectedNotification] = useState(listOfNotifications[0]?.notificationName || ""); 
  const [notificationId, setNotificationId] = useState(listOfNotifications[0]?.idNotification || 0);
  const [actualValue, setActualValue] = useState(parseInt(detailNotification.amount || 0));
  const [maxValue, setMaxValue] = useState(parseInt(detailNotification.threshold || 0));
  const [informationNotification, setInformationNotification] = useState(0);
  const [warningNotification, setWarningNotification] = useState(0);
  const [criticalNotification, setCriticalNotification] = useState(0);



  useEffect(() => {

    setSelectedNotification(detailNotification.notificationName);

    
    let actualValueVar = parseInt(detailNotification.amount || 0)
    setActualValue(actualValueVar);
   
    let maxValueVar = parseInt(detailNotification.threshold || 0)
    setMaxValue(maxValueVar);
    let informationNotificationVar = maxValueVar * (parseInt(detailNotification.information) / 100) || 0
    setInformationNotification(informationNotificationVar)
    let warningNotificationVar = maxValueVar * (parseInt(detailNotification.warning) / 100)|| 0
    setWarningNotification(warningNotificationVar)
    let criticalNotificationVar = maxValueVar * (parseInt(detailNotification.alert) / 100) || 0
    setCriticalNotification(criticalNotificationVar)   

    
  }, [detailNotification]); // Este efecto se ejecutará cada vez que cambie detailNotification


  //esta funcion es para cambiar el color del grafico en base a los valores de las notificaciones
  const getColorByValue = () => {
    if (actualValueAmount >= criticalNotification) {
      return '#FF4F4F'; // Color para crítico
    } else if (actualValueAmount >= warningNotification) {
      return '#F6C633'; // Color para advertencia
    } else if (actualValueAmount >= informationNotification) {
      return '#20E647'; // Color para información
    } else {
      return '#3a57e8'; // Color predeterminado
    }
  };

  let chart2 = {
    options: {
      chart: {
        height: 280,
        type: 'radialBar',
      },
      position: 'relative', 
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: '70%',
            background: '#293450',
          },
          track: {
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              blur: 4,
              opacity: 0.15,
            },
          },
          dataLabels: {
            name: {
              offsetY: -10,
              color: '#fff',
              fontSize: '13px',
            },
            value: {
              color: '#fff',
              fontSize: '30px',
              show: true,
              // fomatter: ,
              formatter: (val) =>{
                
                return val + '%';
                },
            },
          },
        },
      },
      fill: {
        type: [ 'solid'],
        colors: [getColorByValue()],
        
      },  
      stroke: {
        lineCap: 'round',
      },
      labels: ['Consumption: $'+ actualValue],
    },
    series: [parseInt(actualValue ? (parseInt (actualValue) *100) / maxValue : 0)],
  };





      const seleccionarOpcion = (event,opcion,idNotification) => {
       
        const body = {
          idNotification: idNotification,
        };

        dispatch(getDetailsNotification(body));
       
      };



    return (
  <div className="card" data-aos="fade-up" data-aos-delay="900" style={{ height: '75vh' }}>
    <div className="flex-wrap card-header d-flex justify-content-between">
      <div className="header-title d-flex align-items-center"> {/* Contenedor para el título y el spinner */}
        <h4 className="card-title">Notifications</h4>

        {notificationState !== 'ready' && (
          <div className="ms-3"> {/* Espaciado a la izquierda del spinner para separarlo del título */}
            <Spinner animation="grow" className="text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>

      <Dropdown>
        <Dropdown.Toggle
          as={Button}
          variant="text-secondary"
          id="dropdownMenuButton1"
          aria-expanded="false"
          
        >
          {notificationSelected}
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu-end"
          aria-labelledby="dropdownMenuButton1"
        >
          {listOfNotifications?.length > 0 &&
            listOfNotifications.map((opcion, indice) => (
              <li key={indice}>
                <Dropdown.Item
                  onClick={(event) =>
                    seleccionarOpcion(event, opcion.notificationName, opcion.idNotification)
                  }
                >
                  {opcion.notificationName}
                </Dropdown.Item>
              </li>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>

    <div className="card-body">
      {Array.isArray(detailNotification)&& ( 
        <div className="d-flex align-items-center">
                  
            <img
               style={{ width: 50, marginTop: -5 }}
               src={`/${detailNotification?.cloudProvider}.png`}

               alt={detailNotification?.cloudProvider}
               className="me-3"
            /> 

            <p>{detailNotification?.accountName}</p>
        </div>
      )}

      <div className="flex-wrap d-flex align-items-center justify-content-between">
        <Chart
          className="col-md-8 col-lg-8"
          options={chart2.options}
          series={chart2.series}
          type="radialBar"
          height="280"
        />
        <div className="d-grid gap col-md-4 col-lg-4">
          <div className="d-flex align-items-start">
            <svg
              className="mt-2"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              viewBox="0 0 24 24"
              fill="#3a57e8"
            >
              <g>
                <circle cx="12" cy="12" r="8" fill="#3a57e8"></circle>
              </g>
            </svg>
            <div className="ms-3">
              <span className="text-secondary">Treshold</span>
              <h6>${maxValue} </h6>
            </div>
          </div>
          <div className="d-flex align-items-start">
            <svg
              className="mt-2"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              viewBox="0 0 24 24"
              fill="#4bc7d2"
            >
              <g>
                <circle cx="12" cy="12" r="8" fill="#20E647"></circle>
              </g>
            </svg>
            <div className="ms-3">
              <span className="text-secondary">Information %{detailNotification?.information}</span>
              <h6>${informationNotification}</h6>
            </div>
          </div>
          <div className="d-flex align-items-start">
            <svg
              className="mt-2"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              viewBox="0 0 24 24"
              fill="#4bc7d2"
            >
              <g>
                <circle cx="12" cy="12" r="8" fill="#F6C633"></circle>
              </g>
            </svg>
            <div className="ms-3">
              <span className="text-secondary">Warning %{detailNotification?.warning}</span>
              <h6>${warningNotification}</h6>
            </div>
            
          </div>
          <div className="d-flex align-items-start">
            <svg
              className="mt-2"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              viewBox="0 0 24 24"
              fill="#4bc7d2"
            >
              <g>
                <circle cx="12" cy="12" r="8" fill="#FF4F4F"></circle>
              </g>
            </svg>
            <div className="ms-3">
              <span className="text-secondary">Critical %{detailNotification?.alert}</span>
              <h6>${criticalNotification}</h6>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
    );
};

export default SpeedoMeter;

