import {createStore, combineReducers,applyMiddleware } from 'redux'
import Mode from './setting/setting'
import { costingSlice } from './slices/costing/costingSlice'
import { modalSlice } from './slices/modal/modalSlice';
import thunk from 'redux-thunk';
import { authSlice } from './slices/auth/authSlice';
import { driversSlice } from './slices/drivers/driverSlice';
import { accountSlice } from './slices/accounts/accountSlice';
import { notificationsSlice } from './slices/notifications/notificationSlice';
import { usersSlice } from './slices/users/usersSlices';
import { profileSlice } from './slices/profile/profileSlices';
import { toastSlice } from './slices/toasts/toastSlice';
import {contractsSlice} from './slices/contracts/contractsSlices'
import { rolesSlices } from './slices/roles/rolesSlices';
import { forecastSlice } from './slices/forecast/forecastSlice';
import {advisorSlice} from './slices/advisor/advisorSlice'
import { reportSlices } from './slices/reports/reportsSlices';
const rootReducer = combineReducers({
    mode: Mode,
    costing: costingSlice.reducer,
    modal: modalSlice.reducer,
    auth: authSlice.reducer,
    drivers: driversSlice.reducer,
    accounts: accountSlice.reducer,
    notifications: notificationsSlice.reducer,
    users:usersSlice.reducer,
    contracts:contractsSlice.reducer,
    roles:rolesSlices.reducer,
    profile:profileSlice.reducer,
    toast: toastSlice.reducer,
    forecast:forecastSlice.reducer,
    advisor:advisorSlice.reducer,
    reports:reportSlices.reducer
});

export default createStore(
    rootReducer,
    applyMiddleware(thunk)
)
