import { fetchBackend } from "./fetch";

const verifyContractsInDb = async (body) => {
    const resp = await fetchBackend("contracts/verifycontractdates", body, "POST");
    if (resp.status === 409 || resp.status === 500)
        return {
        status: resp.status,
        data: null,
        };
    else {
        const respJson = await resp.json();        
        return respJson
    }
};


const addNewContractInDb = async (body) => {
    const resp = await fetchBackend("contracts/addcontract", body, "POST");
    if (resp.status === 409 || resp.status === 500)
        return {
        status: resp.status,
        data: null,
        };
    else {
        const respJson = await resp.json();
        return respJson
    }
};


const getContractsFromDb = async (body) => {
    const resp = await fetchBackend("contracts/getcontracts", body, "POST");
    if (resp.status === 409 || resp.status === 500)
      return {
        status: resp.status,
        data: null,
      };
    else {
      const respJson = await resp.json();
      return respJson
    }
  };

const editContractInDb = async (body) => {
    const resp = await fetchBackend("contracts/editcontract", body, "POST");
    if (resp.status === 409 || resp.status === 500)
        return {
        status: resp.status,
        data: null,
        };
    else {
        const respJson = await resp.json();
        return respJson
    }
};


const deleteContractInDb = async (body) => {
    const resp = await fetchBackend("contracts/deletecontract", body, "POST");
    if (resp.status === 409 || resp.status === 500)
        return {
        status: resp.status,
        data: null,
        };
    else {
        const respJson = await resp.json();
        return respJson
    }
};

const getRegionsAndPlatforms = async (body) => {
    const resp = await fetchBackend("contracts/regionandplatform", body, "POST");
    if (resp.status === 409 || resp.status === 500)
        return {
        status: resp.status,
        data: null,
        };
    else {
        const respJson = await resp.json();
        return respJson
    }
}

const platforms = ['Amazon Web Services(AWS)','Microsoft Azure','Google Cloud Platform']

  export {getContractsFromDb,editContractInDb,addNewContractInDb,deleteContractInDb,verifyContractsInDb,getRegionsAndPlatforms,platforms  };
    