import { React, useState,useEffect } from "react";
import { Modal, Button,Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../store/slices/modal/modalSlice";
import { allTenantsInDb,changeTenant,getAllSuperAdm,createTenant } from "../../../store/slices/roles/rolesThunks";
const SuperAdminStatus = () => {
    const dispatch = useDispatch();
    const { allTenants, requestStatus ,allSuperAd} = useSelector((state) => state.roles);
    const  {user} = useSelector( (state) => state.profile );
    const [addTenant, setAddTenant] = useState(false);
    const [messageError,setMessageError] = useState(false);
    const [input, setInput] = useState({
      createdUser:'',
      tenantName:'',
      country:''
    });
    const currentTenant = user[0].tenantname
    const userAuth = useAuth0();
    const adminEmail = userAuth.user.email
    const [selectedOption, setSelectedOption] = useState("default");

  useEffect(()=> {
    dispatch(allTenantsInDb())
    dispatch(getAllSuperAdm())
  },[])

  const handleTenantStatus = () => {    
     dispatch(changeTenant(selectedOption,adminEmail))       
  };
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.id]: e.target.value,
    });
  
  };
  const submitNewTenant = () => {
   // dispatch(addTenantInDb(input))
   if(input.country&&input.tenantName){
    dispatch(createTenant(input.tenantName ,input.country,adminEmail))
    setAddTenant(false)
   }else{
    setMessageError(true)
    setTimeout(() => {
      setMessageError(false)
    }, 3000);
   }
  
  }

  return ( <>
    <Modal.Header closeButton>
      <Modal.Title>Super Admin Menu</Modal.Title>
    </Modal.Header>
    <div style={{marginTop:'10px'}} >
    <Button variant={addTenant ? "secondary":"success"} style={{marginLeft:'5px',marginRight:'5px'}}onClick={() => setAddTenant(false)}>
       Select tenant
      </Button>
      <Button variant={addTenant ? "success":"secondary"} onClick={() => setAddTenant(true)}>
       create Tenant
      </Button>  
    </div>
   
    {addTenant ? (
    <Modal.Body>
          <h5>Complete the form for add new Tenant</h5>
      <Form>
        <Form.Group controlId="tenantName" style={{ paddingTop: '8px', color:'black' }}>
          <Form.Label>Tenant Name:</Form.Label>
          <Form.Control
            type="text"
            value={input.tenantName}
            onChange={(e) => handleChange(e)}
            placeholder="Name for the new tenant"
            style={input.tenantName? { borderColor: "#899AF1",color:'black' }:{borderColor: "#899AF1"}}
          />
        </Form.Group>
        <Form.Group controlId="country" style={{  color:'black' }}>
          <Form.Label>Country:</Form.Label>
          <Form.Control
            type="text"
            value={input.country}
            onChange={(e) => handleChange(e)}
            placeholder="tenant country "
            style={input.country? { borderColor: "#899AF1",color:'black' }:{borderColor: "#899AF1"}}
          />
        </Form.Group>
       {messageError ?  <Form.Label style={{paddingTop:'5px',color:'red',fontWeight:'bold'}}>Complete the fields</Form.Label> : null}
      </Form>
    </Modal.Body>
    ) : (
<>
    <Modal.Body style={{ color: 'black' }}>
          <h5>Select the tenant you want to watch</h5>
          <div style={{ display: 'flex', alignItems: 'center' }}>
  <p style={{ paddingTop: '8px', marginRight: '10px' }}>Current tenant:</p>
  <p style={{ paddingTop: '8px',fontWeight: 'bolder' }}>{currentTenant}</p>
</div>
          <div>
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              style={{ borderColor: '#899AF1', borderRadius: '5px', height: '35px', width: '50%' }}
            >
              <option value="default" disabled>
                Select your tenant
              </option>
              {allTenants !== undefined ? (
                allTenants.map((el) => (
                  <option key={el.idTenant} value={el.idTenant}>
                    {el.tenantName} 
                  </option>
                ))
              ) : (
                <option value="default" disabled>
                  {' '}
                </option>
              )}
            </select>
          </div>
        </Modal.Body>
        <Modal.Body style={{ color: 'black' }}>
          <h5 style={{ paddingBottom: '15px' }}>SuperAdmins in Tenants</h5>
          <table className="superadmin-table" style={{ width: '50%', borderCollapse: 'collapse', border: '1px solid #899AF1', borderRadius: '3px' }}>
            <thead style={{ backgroundColor: '#f2f2f2' }}>
              <tr>
                <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }}>Email</th>
                <th style={{ padding: '10px', textAlign: 'center', borderBottom: '1px solid #ccc' }}>Tenant</th>
                <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }}>Tenant Name</th>
              </tr>
            </thead>
            <tbody>
              {allSuperAd && allSuperAd.map((superAdmin) => (
                <tr key={superAdmin.idTenant} style={{ borderBottom: '1px solid #ccc' }}>
                  <td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>{superAdmin.userEmail}</td>
                 
                  <td className="tenant-cell" style={{ padding: '5px', textAlign: 'center', borderBottom: '1px solid #ccc' }}>
                    {superAdmin.idTenant}
                  </td>
                  <td style={{ padding: '15px', borderBottom: '1px solid #ccc' }}>{superAdmin.nameTenant}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </>
      )}

    <Modal.Footer>
      <Button variant="secondary" onClick={() => dispatch(closeModal())}>
        Close
      </Button>
      <Link>
     {  
       addTenant ? <Button variant="primary"  onClick={() => submitNewTenant()}>
        Add new tenant
        </Button> : <Button variant="primary" onClick={() => handleTenantStatus()}>
         Apply changes
        </Button>}
      </Link>
    </Modal.Footer>
  </>
);
};
export default SuperAdminStatus;