//componente donde puedo mostrar un toast con un mensaje que va a llegar como un prop desde el componente padre
//el toast se va a mostrar por 5 segundos y luego se va a ocultar


import React, { Component } from 'react';
import {toast , Toaster} from 'react-hot-toast';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorMessage,userStatusToast,handleAuth0Messages,handleRolMessages } from '../../../store/slices/users/usersSlices';
const ToastUsers = (message) => {

    const dispatch = useDispatch();
    const { errorUserMessage ,statusUserMessage,auth0Messages,rolMessages} = useSelector((state) => state.users);

    const styleToastSuccess = {
        border: '1px solid #FFFFFF',
        padding: '16px',
        color: '#FFFFFF',
        backgroundColor: '#6991f5',
      };
    const duration = 5000;

    useEffect(() => {
        if (errorUserMessage.error === false) {
          toast.success(errorUserMessage.message,{
            duration : duration,
            style: styleToastSuccess,
        });
        dispatch(errorMessage({error:undefined,message:undefined}));
        }else if(errorUserMessage.error === true){    
          toast.error(errorUserMessage.message,{
            duration : duration,
            style: styleToastSuccess,
        });
        dispatch(errorMessage({error:undefined,message:undefined}));
        }else{  }
      }, [errorUserMessage]);


      useEffect(() => {
        
        if (statusUserMessage) {
          toast.success('User active',{
            duration : duration,
            style: styleToastSuccess,
        });
          dispatch(userStatusToast(undefined));
        }else if(statusUserMessage === false){
          toast.success('User desactivate',{
            duration : duration,
            style: styleToastSuccess,
        });
          dispatch(userStatusToast(undefined));
        }
      }, [statusUserMessage]);

      useEffect(() => {
       
        if (auth0Messages) {
          toast.success(auth0Messages,{
            duration : duration,
            style: styleToastSuccess,
        });
        dispatch(handleAuth0Messages(undefined));
        }
      }, [auth0Messages]);

      useEffect(() => {    
        if (rolMessages) {
          toast.success(rolMessages,{
            duration : duration,
            style: styleToastSuccess,
        });
        dispatch(handleRolMessages(undefined));
        }
      }, [rolMessages]);

      
    return (
        <div>
            <Toaster/>
        </div>
    );
}

export default ToastUsers;