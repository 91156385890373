import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
//img
import topHeader from "../../../../assets/images/dashboard/top-header.png";
import topHeader1 from "../../../../assets/images/dashboard/top-header1.png";
import topHeader2 from "../../../../assets/images/dashboard/top-header2.png";
import topHeader3 from "../../../../assets/images/dashboard/top-header3.png";
import topHeader4 from "../../../../assets/images/dashboard/top-header4.png";
import topHeader5 from "../../../../assets/images/dashboard/top-header5.png";

import { useAuth0 } from '@auth0/auth0-react'

import { useSelector, useDispatch } from "react-redux";
import {
  editCredentialsFalse,
  flagToResetDataDistributionTable,
} from "../../../../store/slices/costing/costingSlice.js";

import { openModal } from "../../../../store/slices/modal/modalSlice";

import {
  setDataforDistribution,
  getDataforDistribution,
  updateDataforDashbords,
  updateDataforAccounts
} from "../../../../store/slices/costing/costingThunks.js";


import { setNotificaionViewTrue } from "../../../../store/slices/costing/costingSlice.js";
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import EventRepeatRoundedIcon from '@mui/icons-material/EventRepeatRounded';
// store
import {
  NavbarstyleAction,
  getDirMode,
  SchemeDirAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../../../store/setting/setting";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
    },
    dispatch
  ),
});

const SubHeader = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // navbarstylemode
    const navbarstyleMode = sessionStorage.getItem("Navbarstyle-mode");
    props.NavbarstyleAction(navbarstyleMode);
  });

//Logica de rol 
const  {user} = useSelector( (state) => state.profile );
const { rolConfig } = useSelector((state) => state.roles);



//-----



  const userAuth = useAuth0();
  const location = useLocation();
  let pxDiv = 215;
  // location.pathname == '/cloudcosting/distribution' ? pxDiv=20 : pxDiv=215

  const { dataDistribution, dataToSave, idAccount,idTenant } = useSelector(
    (state) => state.costing
  );

  const myFunction = () => {
    dispatch(editCredentialsFalse());
  };

  let columnWithId = dataDistribution.columnsWithId;

  function transformData(dataAbsolute, dataPercentaje, centroIndices) {
    // Inicializar el array de resultados
    const results = [];

    // Recorrer los datos y crear los objetos con el formato deseado
    for (let i = 0; i < dataAbsolute.length; i++) {
      const item = dataAbsolute[i];
      const percent = dataPercentaje[i];
      let keys = Object.keys(item);
      let indices = Object.keys(centroIndices);
      for (let x = 0; x < keys.length; x++) {
        for (let z = 0; z < indices.length; z++) {
          if (keys[x] === indices[z]) {
            if (item.idHiredService !== undefined) {
              const newObject = {
                dimensionDescription: indices[z],
                idDistributionDimension: centroIndices[indices[z]],
                idHiredService: item.idHiredService,
                resultAmount: parseFloat(item[indices[z]]),
                resultsPercentaje: parseFloat(percent[indices[z]]),
              };
              results.push(newObject);
            }
          }
        }
      }
    }

    return results;
  }

  //////////////////////////////////////////////////////////////////////

  //TERMINAR DE PASAR LOS BOTONES DE GUARDAR Y CANCELAR ACA
  //PASAR LA FUNCION DE CANCELAR Y LA LOGICA DE DISTRIBUTIUONTABLE ACA

  //////////////////////////////////////////////////////////////////////
  //funcion con la que se guarda o actualiza la info en la bd sobre el tablero

  const saveData = () => {
    let results = transformData(
      dataToSave.absolute,
      dataToSave.percentage,
      columnWithId
    );
    const body = {
      tenantId: idTenant,
      accountId: dataToSave.accountId,
      data: results,
      month: dataToSave.month, //monthToDb + "-01",
    };
    dispatch(setDataforDistribution(body));
  };

  const cancelButton = () => {
    dispatch(flagToResetDataDistributionTable());
  };

  //aca hago la actualziacion por varios meses para el dashboard
  const updateDataDashboard = () => {
    const body = {
      tenantId: idTenant,
      accountId: idAccount,
      user: userAuth.user.email,
    };

    dispatch(updateDataforDashbords(body));
  };


  const sheduleExtract = () => {

    dispatch(openModal("SheduleRefresh"));
    
  };


  const uploadDataAccounts = ()=>{

    dispatch(updateDataforAccounts( {tenantId: idTenant,yearly:0,user:userAuth.user.email}))

    dispatch(setNotificaionViewTrue())

  }

  return (
    <>
      <div className="iq-navbar-header" style={{ height: pxDiv }}>
        <>
          <Container fluid className=" iq-container">
            <Row>
              <Col md="12">
                <div className="d-flex justify-content-between flex-wrap ">
                  <div>
                    {location.pathname === "/" && (
                      <>
                        <h1>Cloud Costing</h1>
                        <p>Manage and optimize your costs in the cloud</p>
                      </>
                    )}
                    {location.pathname === "/accounts" && (
                      <>
                        <h1>Accounts</h1>
                      </>
                    )}
                        {location.pathname === "/contracts" && (
                      <>
                        <h1>Snowflake History Contracts</h1>
                      </>
                    )}
                    {location.pathname === "/newaccounts" && (
                      <>
                        <h1>Create or update an Account</h1>
                      </>
                    )}

                    {location.pathname === "/dashboard" && (
                      <>
                        <h1>Usage Dashboard</h1>
                      </>
                    )}
                    {location.pathname === "/distribution" && (
                      <>
                        <h1>Distribution </h1>
                      </>
                    )}
                    {location.pathname === "/analytics" && (
                      <>
                        <h1>Analytics </h1>
                      </>
                    )}
                    {location.pathname === "/drivers" && (
                      <>
                        <h1>Drivers </h1>
                      </>
                    )}
                    {location.pathname === "/notifications"  && (
                      <>
                        <h1>Notifications </h1>
                      </>
                    )}
                     { location.pathname ==='/newnotification' && (
                      <>
                        <h1>Notifications </h1>
                      </>
                    )}
                     {  location.pathname ==='/notificationsettings' && (
                      <>
                        <h1>Notifications </h1>
                      </>
                    )}
                    {location.pathname === "/forecast" && (
                      <>
                        <h1>Forecast </h1>
                      </>
                    )}
                    {location.pathname === "/advisor" && (
                      <>
                        <h1>Advisor </h1>
                      </>
                    )}
                    {location.pathname === "/useradd" && (
                      <>
                        <h1>Add a new user </h1>
                      </>
                    )}
                    {location.pathname === "/userlist" && (
                      <>
                        <h1>User List </h1>
                      </>
                    )}
                    {location.pathname === "/admin" && (
                      <>
                        <h1>Admin</h1>
                      </>
                    )}
                    {location.pathname === "/help" && (
                      <>
                      
                        <h1>Help</h1>
                    
                      </>
                    )}
                         {location.pathname === "/reports" && (
                      <>
                      
                        <h1>Reports</h1>
                    
                      </>
                    )}
                  </div>
                  <div className="d-flex">
                    {location.pathname === "/distribution" && (
                      <>
                       {  user && rolConfig?.VIEWS?.DASHBOARD?.SAVE_DATA.includes(user[0]?.iduserrole) &&(<div className="d-flex align-items-center p-1">
                          <Link
                            to="#"
                            className="btn btn-link btn-soft-light"
                            onClick={() => saveData()}
                          >
                            <svg
                              width="25"
                              className="me-3"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.4"
                                d="M16.3405 1.99976H7.67049C4.28049 1.99976 2.00049 4.37976 2.00049 7.91976V16.0898C2.00049 19.6198 4.28049 21.9998 7.67049 21.9998H16.3405C19.7305 21.9998 22.0005 19.6198 22.0005 16.0898V7.91976C22.0005 4.37976 19.7305 1.99976 16.3405 1.99976Z"
                                fill="currentColor"
                              />
                              <path
                                d="M10.8134 15.248C10.5894 15.248 10.3654 15.163 10.1944 14.992L7.82144 12.619C7.47944 12.277 7.47944 11.723 7.82144 11.382C8.16344 11.04 8.71644 11.039 9.05844 11.381L10.8134 13.136L14.9414 9.00796C15.2834 8.66596 15.8364 8.66596 16.1784 9.00796C16.5204 9.34996 16.5204 9.90396 16.1784 10.246L11.4324 14.992C11.2614 15.163 11.0374 15.248 10.8134 15.248Z"
                                fill="currentColor"
                              />
                            </svg>
                            Save
                          </Link>
                        </div>)}

                     {  user && rolConfig?.VIEWS?.DASHBOARD?.CANCEL_DATA.includes(user[0]?.iduserrole) && (<div className="d-flex align-items-center p-1">
                          <Link
                            to="#"
                            className="btn btn-link btn-soft-light"
                            onClick={() => cancelButton()}
                          >
                            <svg
                              width="25"
                              className="me-3"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.4"
                                d="M16.34 1.99976H7.67C4.28 1.99976 2 4.37976 2 7.91976V16.0898C2 19.6198 4.28 21.9998 7.67 21.9998H16.34C19.73 21.9998 22 19.6198 22 16.0898V7.91976C22 4.37976 19.73 1.99976 16.34 1.99976Z"
                                fill="currentColor"
                              />
                              <path
                                d="M15.0158 13.7703L13.2368 11.9923L15.0148 10.2143C15.3568 9.87326 15.3568 9.31826 15.0148 8.97726C14.6728 8.63326 14.1198 8.63426 13.7778 8.97626L11.9988 10.7543L10.2198 8.97426C9.87782 8.63226 9.32382 8.63426 8.98182 8.97426C8.64082 9.31626 8.64082 9.87126 8.98182 10.2123L10.7618 11.9923L8.98582 13.7673C8.64382 14.1093 8.64382 14.6643 8.98582 15.0043C9.15682 15.1763 9.37982 15.2613 9.60382 15.2613C9.82882 15.2613 10.0518 15.1763 10.2228 15.0053L11.9988 13.2293L13.7788 15.0083C13.9498 15.1793 14.1728 15.2643 14.3968 15.2643C14.6208 15.2643 14.8448 15.1783 15.0158 15.0083C15.3578 14.6663 15.3578 14.1123 15.0158 13.7703Z"
                                fill="currentColor"
                              />
                            </svg>
                            Cancel
                          </Link>
                        </div>)}
                      </>
                    )}
                   {location.pathname === "/reports"  && (
                      <>
               
                    {  user && rolConfig?.VIEWS?.NOTIFICATIONS?.ADD_NOTIFICATION.includes(user[0]?.iduserrole) && 
                    (<div className="d-flex align-items-center me-2" onClick={() => { dispatch(openModal('SelectRecipients'))}}>
                        <Link                          
                          className="btn btn-link btn-soft-light"
                        >
                          <AddTwoToneIcon sx={{ fontSize: "25px" , marginRight:"20px" }}/>                      
                          Select recipients
                        </Link>
                      </div>)}                
                   </>
                    )}





                    {location.pathname === "/notificationsettings"  && (
                      <>
                    {  user && rolConfig?.VIEWS?.NOTIFICATIONS?.ADD_NOTIFICATION.includes(user[0]?.iduserrole) && (<div className="d-flex align-items-center me-2">
                        <Link
                          to="/newnotification"
                          className="btn btn-link btn-soft-light"
                        >
                          <AddAlertIcon sx={{ fontSize: "25px" , marginRight:"20px" }}/>
                      

                          Add Notification
                        </Link>
                      </div>)}

                
                   </>
                    )}






                    {location.pathname === "/drivers" && (
                     <>
                {   user && rolConfig?.VIEWS?.DRIVERS?.DRIVERS_LIST.NEW_DRIVER.includes(user[0]?.iduserrole) && (<div className="d-flex align-items-center">
                       <Link
                         to="/newdriver"
                         className="btn btn-link btn-soft-light"
                         
                       >
                         <svg
                           width="25"
                           className="me-3"
                           viewBox="0 0 24 24"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                         >
                           <path
                             opacity="0.4"
                             d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z"
                             fill="currentColor"
                           />
                           <path
                             d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z"
                             fill="currentColor"
                           />
                         </svg>
                         New Driver
                       </Link>
                     </div>)
                     }
                   </>
                    )}

                    {location.pathname === "/dashboard" && user && rolConfig?.VIEWS?.DASHBOARD?.UPDATE_DATA.includes(user[0]?.iduserrole) && (
                      <div className="d-flex align-items-center">
                        <Link
                          to="#"
                          className="btn btn-link btn-soft-light"
                          onClick={() => updateDataDashboard()}
                        >
                          <RefreshTwoToneIcon sx={{ fontSize: "25px", marginRight:"15px" }} />

                          {"Update Data"}
                        </Link>
                      </div>
                    )}

                    {location.pathname === "/accounts" &&  user && rolConfig?.VIEWS?.DASHBOARD?.UPDATE_DATA.includes(user[0]?.iduserrole) && (
                      <>
                       {user && rolConfig?.VIEWS?.ACCOUNTS?.UPDATE_DATA.includes(user[0]?.iduserrole) && (  <div className="d-flex align-items-center me-2" >
                          <Link
                            to="#"
                            className="btn btn-link btn-soft-light"
                            // {//ACA ESTOY ACTALIZANDO LOS DATOS DE LA TABLA DE ACCOUNTS PERO POR SOO 2 MESES POR ESO EL PARAMETRO YEARLY ES 0}
                            onClick={() =>uploadDataAccounts() }
                          >
                            <RefreshTwoToneIcon sx={{ fontSize: "25px" , marginRight:"15px" }} />

                            {"Update Data"}
                          </Link>
                        </div>)}

                        {user && rolConfig?.VIEWS?.ACCOUNTS?.UPDATE_DATA.includes(user[0]?.iduserrole) && (  <div className="d-flex align-items-center me-2" >
                          <Link
                            to="#"
                            className="btn btn-link btn-soft-light"
                          
                            onClick={sheduleExtract}
                          >
                            <EventRepeatRoundedIcon sx={{ fontSize: "25px" , marginRight:"15px" }} />

                            {"Schedule Update"}
                          </Link>
                        </div>)}

                        {user && rolConfig?.VIEWS?.ACCOUNTS?.NEW_ACCOUNT.includes(user[0]?.iduserrole) && (<div className="d-flex align-items-center me-2">
                          <Link
                            to="/newaccounts"
                            className="btn btn-link btn-soft-light"
                            onClick={() => myFunction()}
                          >
                            <svg
                              width="25"
                              className="me-3"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.4"
                                d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z"
                                fill="currentColor"
                              />
                              <path
                                d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z"
                                fill="currentColor"
                              />
                            </svg>
                            New Account
                          </Link>
                        </div>)}

                       

                      </>
                    )}
                     {location.pathname === "/listdimesionbydrivers" && (
                      <>
                      
                        {user && rolConfig?.VIEWS?.DRIVERS?.DIMENSION_LIST.NEW_MEMBER.includes(user[0]?.iduserrole) &&(<div className="d-flex align-items-center">
                          <Link
                            to="/newmember"
                            className="btn btn-link btn-soft-light"
                            onClick={() => myFunction()}
                          >
                            <svg
                              width="25"
                              className="me-3"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                opacity="0.4"
                                d="M16.6667 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0622 3.92 22 7.33333 22H16.6667C20.0711 22 22 20.0622 22 16.6667V7.33333C22 3.92889 20.0711 2 16.6667 2Z"
                                fill="currentColor"
                              />
                              <path
                                d="M15.3205 12.7083H12.7495V15.257C12.7495 15.6673 12.4139 16 12 16C11.5861 16 11.2505 15.6673 11.2505 15.257V12.7083H8.67955C8.29342 12.6687 8 12.3461 8 11.9613C8 11.5765 8.29342 11.2539 8.67955 11.2143H11.2424V8.67365C11.2824 8.29088 11.6078 8 11.996 8C12.3842 8 12.7095 8.29088 12.7495 8.67365V11.2143H15.3205C15.7066 11.2539 16 11.5765 16 11.9613C16 12.3461 15.7066 12.6687 15.3205 12.7083Z"
                                fill="currentColor"
                              />
                            </svg>
                            New Member
                          </Link>
                        </div>)}
                      </>
                    )}


                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <div className="iq-header-img">
            <img
              src={topHeader}
              alt="header"
              className="theme-color-default-img img-fluid w-100 h-100 animated-scaleX"
            />
            <img
              src={topHeader1}
              alt="header"
              className=" theme-color-purple-img img-fluid w-100 h-100 animated-scaleX"
            />
            <img
              src={topHeader2}
              alt="header"
              className="theme-color-blue-img img-fluid w-100 h-100 animated-scaleX"
            />
            <img
              src={topHeader3}
              alt="header"
              className="theme-color-green-img img-fluid w-100 h-100 animated-scaleX"
            />
            <img
              src={topHeader4}
              alt="header"
              className="theme-color-yellow-img img-fluid w-100 h-100 animated-scaleX"
            />
            <img
              src={topHeader5}
              alt="header"
              className="theme-color-pink-img img-fluid w-100 h-100 animated-scaleX"
            />
          </div>
        </>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);