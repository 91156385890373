import React from 'react'
import {Row,Col,Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Card from '../../components/Card'
import { useSelector,useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { getContracts } from '../../store/slices/contracts/contractsThunks'
import { setStatusFormEdit,setSelectedContract,handleContractAdd,setShowAddModal } from '../../store/slices/contracts/contractsSlices'
import ClearIcon from '@mui/icons-material/Clear';
import Loader from '../../layouts/Loader'
import ToastUsers from '../../components/cco/toasts/ToastUsers'
import { openModal,closeModal } from '../../store/slices/modal/modalSlice'
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import SnowflakeEditContract from '../../components/cco/forms/SnowflakeEditContract';

const ContractsViews =() =>{
   const  {contracts,contractsStatus,idCurrentAccount,editForm,addContract} = useSelector( (state) => state.contracts );
    const dispatch = useDispatch();

    useEffect(()=> {
      dispatch(getContracts(idCurrentAccount.idAccount))         
    },[editForm,contractsStatus,addContract])

   const editFormView = (contract)=> {
    dispatch(setSelectedContract(contract));
       dispatch(setStatusFormEdit(true))
   } 
   const deleteContractInView = (contract) => {
      dispatch(setSelectedContract(contract));
      dispatch(openModal("SnowflakeDeleteContract"))
   }
 
   return(
     <> 
    <ToastUsers/>
    {editForm ? (<SnowflakeEditContract/>):
     (  contractsStatus !== 'ready' ? (<Loader/>):

       (
           <div>
         <Row>          
            <Col sm="12">
               <Card>
                  <Card.Header className="d-flex justify-content-between">
                     <div  style={{color:'black'}}>
                     Account: {contracts[0] && contracts[0].accountName} <br/> Hosted Region: {contracts[0]&& contracts[0].region}
                     </div>
              
                  </Card.Header>
                  <Card.Body className="px-0">
                     <div className="table-responsive">
                        <table id="user-list-table" className="table table-striped" role="grid" data-toggle="data-table">
                           <thead>
                              <tr className="ligth">
                                 <th min-width= "100px">Actions</th>
                                 <th>From</th>
                                 <th>To</th>
                                 <th>Platform</th>
                                 <th>Region</th>
                                 <th>Plan</th>
                                 <th>Credit price</th>
                                 <th>Credit list Price</th>
                                 <th>Storage price</th>
                                 <th>List storage price</th>                              
                                 <th>Total Credits</th>
                                 <th>Creation User</th>
                            
                              </tr>
                           </thead>
                           <tbody>
                        
                        {   
                        
                        contracts?.length > 0 ?(   contracts?.map((item) => (
                              <tr key={item.contractIdInDb}>
                                <td>
                                     <div className="flex align-items-center list-user-action">
                                    <Tooltip title="Edit" position="top"  trigger="mouseenter" delay={200}  >
                                    
                                          <span className="btn btn-sm btn-icon btn-primary" onClick={()=>{editFormView(item)}}>
                                             <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                             </svg>
                                          </span>
                                     
                                    </Tooltip>
                                  
                                    <Tooltip title="Delete contract"  position="top"  trigger="mouseenter" delay={200}  >
                                       <Link className="btn btn-sm btn-icon btn-warning ms-1" to="#">
                                          <span className="btn-inner" onClick={()=>{deleteContractInView(item)}}>
                                         <ClearIcon/>
                                          </span>
                                       </Link>
                                    </Tooltip>
                                    
                                 </div>

                                 </td>

                              
                                
                                 <td>{item.from}</td>
                                 <td>{item.to}</td>
                                 <th>{item.platform}</th>
                                 <th>{item.platformRegion}</th>
                                 <th>{item.plan}</th>
                                 <td>{item.creditPrice}</td>
                                 <th>{item.listPrice}</th>
                                 <td>{item.storagePrice}</td>
                                 <td>{item.storageListPrice}</td>
                                 <td>{item.creditAmount}</td>
                                 <td>{item.creationUser}</td>
                                                      
                              </tr>))) : (<tr><td colSpan="11" className="text-center">No data available in table</td></tr>)
                                       }
                           </tbody>
                        </table>
                        
                     </div>
                     <div style={{display: 'flex', justifyContent: 'flex-end', margin: '15px 10px 0 0' }}>
                   <Link to='/newaccounts'>
                   <Button style={{marginRight:'15px' }}>
                        Back to account
                    </Button>
                   </Link>
                   
                    <Link to='/addcontract'>                 
                     <Button >
                        Add Contract
                    </Button>
                    </Link>
                     </div>        
                    
                  </Card.Body>                
               </Card>
      
            </Col>
         </Row>
      </div>)
       ) }
     </>

)}

export default ContractsViews;