import React, { useRef,useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from "react-router-dom";
import moment from 'moment';
import 'moment-timezone';

const NotificationHeaderList = () => {
    const user = useAuth0();
    const { notifications } = useSelector((state) => state.notifications);
    const dispatch = useDispatch();
console.log('notificationsfromnavbar',notifications)
     return (
        <div className="m-0 shadow-none card">
        <div className="py-3 card-header d-flex justify-content-between bg-primary">
            <div className="header-title">
            <h5 className="mb-0 text-white">All Notifications</h5>
            </div>
        </div>
        {notifications?.length > 0 ?
       (  <div className="p-0 card-body">
        {notifications?.length > 0 && notifications.slice(0, 3).map((notification, index) => (
            notification.serviceNotification === true ?
            <Link to="#" className="iq-sub-card" key={index}>
            <div className="d-flex align-items-center">
                <img src={`/${notification.cloudProvider}.png`} alt={notification.cloudProvider} className="p-1 avatar-40 rounded-pill bg-soft-primary" />
                <div className="ms-3 w-100">
                    <h6 className="mb-0 ">{notification.notificationType.charAt(0).toUpperCase() + notification.notificationType.slice(1)}</h6>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0">Service {notification.serviceName} has reached the limit</p>
                        {/* <small className="float-right font-size-12">{notification.creationDate}</small> */}
                        <small className="float-right font-size-12">{moment(notification.creationDate).fromNow()}</small>

                    </div>
                </div>
            </div>
        </Link>
        :
         <Link to="#" className="iq-sub-card" key={index}>
                <div className="d-flex align-items-center">
                    <img src={`/${notification.cloudProvider}.png`} alt={notification.cloudProvider} className="p-1 avatar-40 rounded-pill bg-soft-primary" />
                    <div className="ms-3 w-100">
                        <h6 className="mb-0 ">{notification.notificationType.charAt(0).toUpperCase() + notification.notificationType.slice(1)}</h6>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-0">The account {notification.accountName} has reached the limit</p>
                            {/* <small className="float-right font-size-12">{notification.creationDate}</small> */}
                            <small className="float-right font-size-12">{moment(notification.creationDate).fromNow()}</small>

                        </div>
                    </div>
                </div>
            </Link> 
            
           ))}
          
        </div>):  (  <div s style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',margin:'60px' }}> 
                      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="30" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6"/>
</svg>
No notifications sent ...
                      </div> )}
    </div>
  );
};

export default NotificationHeaderList;
