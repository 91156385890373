
import { React, useEffect, useState } from "react";
import Chart  from  "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Dropdown, Button, Spinner } from "react-bootstrap";
import { getAccountsNames,getAccountAndDatesById } from "../../store/slices/forecast/forecastThunks";
import LastUpdateAlert from "../../components/cco/alerts/LastUpdateAlert";
import { setStateForecast } from "../../store/slices/forecast/forecastSlice";
import {Tooltip} from 'react-tippy';

import InfoIcon from '@mui/icons-material/Info';
import CountUp from "react-countup";

import { useAuth0 } from '@auth0/auth0-react'
const moment = require("moment");

const Forecast = () => {
  
  const dispatch = useDispatch();

    const { cardUsageAccount, idTenant  } = useSelector((state) => state.costing);
    const {accountsNamesAndIds,oneAccountData,forecastState } = useSelector((state) => state.forecast);
    const [noData, setNoData] = useState(false);
    const [enableFiltering, setEnableFiltering] = useState(true);
    const [enableLegend, setEnableLegend] = useState(false);
    const [selectedChartType, setSelectedChartType] = useState("bar");
    const [selectedTypeCosts, setSelectedTypeCosts] = useState("Accumulated Costs");
    const [shouldRenderChart, setShouldRenderChart] = useState(false);
    const [seleccionado, setSeleccionado] = useState(Array.isArray(accountsNamesAndIds) ? accountsNamesAndIds[0]?.accountName : "")

useEffect(() => {
  if(idTenant){
    dispatch(getAccountsNames(idTenant))
  }
}, []);
    useEffect(() => {
      if(cardUsageAccount === undefined){
        setNoData(true)
      }else{
        setNoData(false)
        if ( Array.isArray(accountsNamesAndIds) && idTenant&& accountsNamesAndIds[0]?.accountid && accountsNamesAndIds[0]?.cloudprovider && accountsNamesAndIds[0]?.accountName)  {
            dispatch(getAccountAndDatesById(userEmail,accountsNamesAndIds[0]?.accountid,accountsNamesAndIds[0]?.cloudprovider,idTenant)) 
            setSeleccionado(Array.isArray(accountsNamesAndIds) ? accountsNamesAndIds[0]?.accountName : "")
        }
      }
    }, [accountsNamesAndIds]);
    

    useEffect(() => {
    if(oneAccountData && oneAccountData.data && oneAccountData.dates){
      //dispatch(setStateForecast('ready'))
      setShouldRenderChart(true)
    }
    }, [oneAccountData ]);
    
    useEffect(() => {
      // Cuando selectedChartType cambia, permitir que el gráfico se renderice nuevamente
      //dispatch(setStateForecast('processing'))      
      if(oneAccountData && oneAccountData.data && oneAccountData.dates){
        setShouldRenderChart(true);
       // dispatch(setStateForecast('ready'))
    }
    }, [selectedChartType,selectedTypeCosts]);
    useEffect(() => {
      // Cuando selectedChartType cambia, permitir que el gráfico se renderice nuevamente
      //dispatch(setStateForecast('processing'))      
      if(oneAccountData && oneAccountData.data && oneAccountData.dates){
        setShouldRenderChart(true);
       // dispatch(setStateForecast('ready'))
    }
    }, [selectedTypeCosts]);

    const filterNonZeroData= (dataArray) => {
      // Utilizamos el método filter para mantener solo los objetos con costos diferentes de cero
      const filteredData = dataArray?.filter((item) => {
        // Utilizamos el método some para verificar si al menos un elemento en el array data es diferente de cero
        return item.data?.some((cost) => cost !== 0);
      });
      return filteredData;
    }

    const user = useAuth0();
    const userEmail = user.user.email
    const dayOfMonth = getDayOfMonth();
    let dataCost =   selectedTypeCosts === "Accumulated Costs" ? oneAccountData.dataAcc || [] : oneAccountData.data || [];
    let dataFilterCost = selectedTypeCosts === "Accumulated Costs" ? filterNonZeroData(oneAccountData.dataAcc) : filterNonZeroData(oneAccountData.data)
    let totalCostsOfAccount = oneAccountData.totalCost || 0;
    let dailyCostsOfAccount = oneAccountData.dailyCosts || 0;
    let daysInMonths = oneAccountData.dates  || [];
    

    function getDayOfMonth() {
      const currentDate = new Date();
      const dayOfMonth = currentDate.getDate();
      return dayOfMonth;
    }

    const dateForDashboard = oneAccountData.lastUpdated
    let formattedDate = moment(dateForDashboard).format(
      "MMM DD YYYY HH:mm z"
    );



    const handleChartTypeChange = (chartType) => {
        setSelectedChartType(chartType);
        setShouldRenderChart(false); // Evitar que el gráfico se renderice inmediatamente
      };
    
    const selectAndGetAccountData =(accountName,accountId,cloud,tenantId) => {
        setSeleccionado(accountName)
        dispatch(getAccountAndDatesById(userEmail,accountId,cloud,tenantId))
      }
    const selectConsolidatedAccountData =() => {
        setSeleccionado("Consolidated")
        dispatch(getAccountAndDatesById(userEmail,-1,"Consolidated",idTenant,))
      }






      const handleTypeCostsChange = (typeCosts) => {
          setSelectedTypeCosts(typeCosts);
          setShouldRenderChart(false);
      };

      const handleToggleChange = (event) => {
        setEnableFiltering(event.target.checked);
      };

    const handleToggleLegendChange = (event) => {
        setEnableLegend(event.target.checked);
      };

    const chartoptionsLines = {
      series:enableFiltering ? dataFilterCost :  dataCost,
      colors: [
        "#03a9f4",
        "#80d8ff",
        "#00bcd4",
        "#00acc1",
        "#008c9e",
        "#00796b",
        "#004d40",
        "#2e7d32",
        "#558b2f",
        "#9e9d24",
        "#f9a825",
        "#ff8f00",
        "#ef6c00",
      ],
      chart: {
      zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
      height: 350,
      type: 'line',
    },
    legend: {
      show: enableLegend,
    },
    forecastDataPoints: {
      count:( daysInMonths?.length + 1 ) - dayOfMonth
    },
    stroke: {
      width: 5,
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
      categories: daysInMonths,
      tickAmount: daysInMonths?.length,
      labels: {
                  minHeight: 30,
                  maxHeight: 40,
                  style: {
                    colors: "#8A92A6",
                  },
                },
    },
    title: {
      text: 'Forecast',
      align: 'left',
      style: {
        fontSize: "16px",
        color: '#666'
      }
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'dark',
    //     gradientToColors: [ '#FDD835'],
    //     shadeIntensity: 1,
    //     type: 'horizontal',
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 100, 100, 100]
    //   },
    // },
    // yaxis: {
    //   min: -1,
    //   max: graphicValueLimit
    // }
    };


    const chartoptionsbar = {
    
      series: enableFiltering ? dataFilterCost :  dataCost,
    options: {
      forecastDataPoints: {
        count:( daysInMonths?.length + 1 )- dayOfMonth
      },
      // markers: {
      //   size: 6, // Tamaño de los marcadores
      // },
      colors: [
        "#03a9f4",
        "#80d8ff",
        "#00bcd4",
        "#00acc1",
        "#008c9e",
        "#00796b",
        "#004d40",
        "#2e7d32",
        "#558b2f",
        "#9e9d24",
        "#f9a825",
        "#ff8f00",
        "#ef6c00",
      ],
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val;
          },
        },
      },
      legend: {
        show: enableLegend,
      },
     
      chart: {
        height: 350,
        stacked: true,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
       
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'Services Trends by Month',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
     
      xaxis: {
        type: "datetime",
        categories: daysInMonths,
        labels: {
          minHeight: 30,
          maxHeight: 40,
          style: {
            colors: "#8A92A6",
          },
        },
      },
   
      yaxis: {
        // min: 0,
        // max: barGraphicValueLimit + 100,
        labels: {
          show: true,
          style: {
            colors: "#a68a90",
          },
          formatter: function (val) {
            return Math.round(val);
          },
        },
      },
    },
  
  
  };
    return (
        <>
        <Row>
          <Col md="12"
            className="d-flex justify-content-end align-items-center">
              <LastUpdateAlert formattedDate={formattedDate} dashboard={true} />
          </Col>
        <Col  md="12">
        <div style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",borderRadius:"10px"}}>
            <div className="flex-wrap card-header d-flex justify-content-start">
                 
              <Dropdown>
                <Dropdown.Toggle
                  as={Button}
                  variant=" text-secondary"
                  id="dropdownMenuButton3"
                  aria-expanded="false"
                >
                  {seleccionado}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton3"
                >
              { noData   ?null : <li className="card-title">
                    <Dropdown.Item onClick={() =>
                         selectConsolidatedAccountData() }>
                      Consolidated
                        
                    </Dropdown.Item>
                  </li>}
                  {accountsNamesAndIds && accountsNamesAndIds?.map((el, indice) => (
                    <li className="card-title" key={indice}>
                      <Dropdown.Item
                        onClick={() =>
                          selectAndGetAccountData(el.accountName,el.accountid,el.cloudprovider,el.tenantid)
                        }   
                      >
                        {el.accountName}
                      </Dropdown.Item>
                    </li>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  as={Button}
                  variant=" text-secondary"
                  id="dropdownMenuButton3"
                  aria-expanded="false"
                  >
                    {selectedTypeCosts}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton3">
                 <li className="card-title">
                 {selectedTypeCosts === "Accumulated Costs" ? <Dropdown.Item
                        onClick={() =>
                          handleTypeCostsChange("Daily Costs")
                        }   
                      >
                      Daily Costs
                      </Dropdown.Item> : <Dropdown.Item onClick={() =>
                      handleTypeCostsChange("Accumulated Costs")
                                                  }>
                      Accumulated Costs
                        
                    </Dropdown.Item>}
                 </li>
                 
                </Dropdown.Menu>

              </Dropdown>
             <Dropdown>
                <Dropdown.Toggle
                    as={Button}
                    variant=" text-secondary"
                    id="dropdownMenuButton3"
                    aria-expanded="false"
                    >
                    {selectedChartType === "bar" ? "Columns" : "Line"}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton3">
        <li className="card-title">
          {selectedChartType === "line" ? 
            <Dropdown.Item onClick={() => handleChartTypeChange("bar")}>Columns</Dropdown.Item>: <Dropdown.Item onClick={() => handleChartTypeChange("line")}>Line</Dropdown.Item> }        
        </li>     
      </Dropdown.Menu>
               </Dropdown>

               <div className="d-flex justify-content-center " style={{marginTop:'10px'}}>
                <div  className="me-3 dropdownContainer" >
                    <label className="form-check-label "  style={{ whiteSpace: 'nowrap'}} >
                    Enable filtering zeros
                    </label>
                </div>
                  <div className="form-check form-switch form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="switch1"
                      onChange={handleToggleChange}
                      checked={enableFiltering}
                    />
                  </div>
              </div>
              <div className="d-flex justify-content-center " style={{marginTop:'10px',marginLeft:'10px'}}>
                <div  className="me-3 dropdownContainer" >
                    <label className="form-check-label "  style={{ whiteSpace: 'nowrap'}} >
                    Services Labels
                    </label>
                </div>
                  <div className="form-check form-switch form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="switch1"
                      onChange={handleToggleLegendChange}
                      checked={enableLegend}
                    />
                  </div>
                 

              <div className="p-1" style={{ display: 'flex', marginLeft: '20px' }}>
  <p className="mb-0">End of month forecast:{"   "}</p>
  <h5  style={{marginLeft:'10px',marginBottom:'20px'}}>
  $
    <CountUp start={0} end={totalCostsOfAccount} duration={3} className="me-2" />
  </h5>
</div>
<div className="p-1" style={{ display: 'flex', marginLeft: '20px' }}>
  <p className="mb-0">Average daily cost:{"   "}</p>
  <h5  style={{marginLeft:'10px',marginBottom:'20px'}}>
  $
    <CountUp start={0} end={dailyCostsOfAccount} duration={3} className="me-2" />
  </h5>
</div>
              {forecastState === "processing"  ? (
                <Spinner
                  animation="grow"
                  className="text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner> ): 
                
              (
                <div style={{textAlign:'center'}}>
                <Tooltip title="The data provided in this report is forecasted and is presented for informational purposes only. Actual data always refers to the day before the current date. This implies that the forecasts represent information from today onward. Unlike what is observed in the Cards and the Usage Dashboard, where the costs reflect real-time data at the moment of their update.">
                  <InfoIcon fontSize="large" style={{
                   margin: '0 auto'}} />
                </Tooltip>
              </div>
             
              
              )
             
             
             }
            </div>
            
              </div>

             
        {!accountsNamesAndIds ? (
          <div>Cargando datos...</div>
        ) : noData ? <div style={{  display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',fontSize:'25px',
        height: '100vh',  }}>No accounts to analyze your forecast </div>:shouldRenderChart && oneAccountData.data?.length > 0  ?  (
          <Chart
            className="d-activity"
            options={selectedChartType === "line"? chartoptionsLines :chartoptionsbar.options}
            series={selectedChartType === "line"? chartoptionsLines.series :chartoptionsbar.series}
            type={selectedChartType}
            height="500"
          />
        ) : <div style={{  display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',fontSize:'25px',
        height: '100vh',  }}>There are not enough costs available to perform the forecast analysis.</div>}      
        </div>
        </Col>
        </Row>
  
        </>
     
    
    )
}

export default Forecast;