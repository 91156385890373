import { createSlice } from '@reduxjs/toolkit'




const initialState = {
    cloudProvider:'AWS', //guardo el proveedor seleccionado
    stateAccount: 'ready',  // manejo el estado de la cuenta, si esta ready o processing
    validCredentials: false, //guardo el estado de las credenciales, si son validas o no, arranca en false porque no se ingresaron credenciales
    alertCredentialsInvalid: false, //guardo el estado de las credenciales, si son validas o no, arranca en false porque no se ingresaron credenciales
    nameNewAccount: '', //guardo el nombre de la cuenta que se esta creando
    editAccount: false, //uso esta bandera para saber si estoy editando una cuenta o creando una nueva
    indexOfAccountToEdit: null, //guardo el indice de la cuenta que estoy editando
    dataAccountToEdit: {}, //guardo los datos de la cuenta que estoy editando
    accountSelectedForDistributionTable:{
        accountName:null,
        accountId:null
    },
    accountSavedSuccessfully: false,
    servicesByAccount: [], //tengo los datos de los servicios por cuenta en un top para el grafico en el componente serviceBar
    dataToConsolidatedCard: {},
    scheduleRefresh: null,
  }
  
  export const accountSlice = createSlice({
      name: 'account',
      initialState,
      reducers: {
          //cambio el estado ya que esta procesando una peticion
        processingRequest: (state) => {
            state.stateAccount = 'processing';
        },
        //vuelvo a cambiar el estado ya que termino de procesar la peticion
        readyRequest: (state) => {
            state.stateAccount = 'ready';
        },
        credentialsValid: (state)=>{
            state.validCredentials = true
            state.stateAccount = 'ready';
        },
        credentialsInvalid: (state)=>{
            state.validCredentials = false
            state.alertCredentialsInvalid = true
            state.stateAccount = 'ready';
        },
        resetStateCredentials: (state)=>{
            state.validCredentials = false
            state.alertCredentialsInvalid = false
            state.stateAccount = 'ready';
        },
        updateNameNewAccount: (state,{payload})=>{
            state.nameNewAccount = payload
        },
        //actualizo el estado de la bandera que uso para saber si estoy editando una cuenta o creando una nueva
        updateEditAccount: (state,{payload})=>{
            state.editAccount = payload.edit
            if (payload.hasOwnProperty('index')) {
                // Se encontró payload.index, realizar la tarea correspondiente
                state.indexOfAccountToEdit = payload.index
              }
        },
        //guardo los datos de la cuenta que estoy editando
        updateDataAccountToEdit: (state,{payload})=>{
            state.dataAccountToEdit = payload
            state.nameNewAccount = payload.accountName
            state.stateAccount = 'ready';
        },
        setAccountSelectedForDistribution: (state, {payload})=>{
            state.accountSelectedForDistributionTable.accountId = payload.accountId
            state.accountSelectedForDistributionTable.accountName = payload.accountName

        },
        setStateAccountSavedSuccessfully: (state,{payload})=>{
            state.accountSavedSuccessfully = payload.state
        },
        setTopService: (state,{payload})=>{
            state.servicesByAccount = payload
        },
        deleteDataTopService: (state)=>{
            state.servicesByAccount = []
        },
        setDataToConsolidatedCard: (state,{payload})=>{
            state.dataToConsolidatedCard = payload
        },
        setScheduleRefreshSlice: (state,{payload})=>{
            state.scheduleRefresh = payload
        },
      },
  })
  
  // Action creators are generated for each case reducer function
  export const {setScheduleRefreshSlice,setDataToConsolidatedCard,deleteDataTopService,setTopService,setStateAccountSavedSuccessfully,setAccountSelectedForDistribution,updateDataAccountToEdit,updateEditAccount,updateNameNewAccount,resetStateCredentials,processingRequest,readyRequest,credentialsValid,credentialsInvalid } = accountSlice.actions