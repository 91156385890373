import { React } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { passwordReset,changeUserStatus } from "../../../store/slices/users/usersThunks";
import { closeModal } from "../../../store/slices/modal/modalSlice";
import { deleteContract } from "../../../store/slices/contracts/contractsThunks";
const SnowflakeDeleteContract = () => {
  const dispatch = useDispatch();
  const  {selectedContract} = useSelector( (state) => state.contracts );

  const handleDeleteContract = () => {
    dispatch(deleteContract(selectedContract))
    dispatch(closeModal())
    }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Delete Contract</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{color:'black'}}>
      are you sure you want to delete contract:  <br/> Region: {selectedContract.region}  <br/> From: {selectedContract.accountName} account?
           
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModal())
          }}
        >
          Close
        </Button>
        <Link to="/contracts">
          <Button variant="primary"  onClick={()=>handleDeleteContract()}>
          Delete contract
          </Button>
        </Link>
      </Modal.Footer>
    </>
  );
};

export default SnowflakeDeleteContract;
