import React, { useRef,useState } from "react";
import { Form, Button,Spinner} from "react-bootstrap";
import '../../../styles/containerPrincipal.css' 
import { useSelector,useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from "react-router-dom";
import { testAzureConnection,setNewAWSCredentials } from "../../../store/slices/accounts/accountThunks";
import { resetStateCredentials } from "../../../store/slices/accounts/accountSlice";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';



const AccountAzureForm = () => {
    const user = useAuth0();
    const userFinal=user.user.email
    const dispatch = useDispatch();

    const [emptyFields,setEmptyFields]=useState(true)
    const { idTenant } = useSelector((state) => state.costing);
 
    const { validCredentials,stateAccount,nameNewAccount } = useSelector((state) => state.accounts);

    const [showPassword, setShowPassword] = useState(false);


    const clientIdRef = useRef('');
    const tenantIdAzureRef = useRef('');
    const clientSecretRef = useRef('');
    const subscriptionIdRef = useRef('');
    const billingAccountRef = useRef('');

    //Estilos para el formulario 
    const styleLabel = {color:'black'}
    const styleInput = {borderColor: '#899AF1', color: '#000'}
    const botonSinFondo = { backgroundColor: 'transparent',border: 'none',padding: 0}


    const handleTestConnection=()=>{

      let clientId = clientIdRef.current.value.trim();
      let tenantIdAzure = tenantIdAzureRef.current.value.trim();
      let clientSecret = clientSecretRef.current.value.trim();
      // let userAzure = userRef.current.value

        const body = { clientId, tenantIdAzure,clientSecret,user: userFinal};

        dispatch(testAzureConnection(body))
    }
    
    

    const submitForm=()=>{
       
        let clientId = clientIdRef.current.value.trim();
        let tenantIdAzure = tenantIdAzureRef.current.value.trim();
        let clientSecret = clientSecretRef.current.value.trim();
        let subscriptionId = subscriptionIdRef.current.value.trim();
        let billingAccount = billingAccountRef.current.value.trim();

        const body = {
            data: {
            cloudProvider: 'Azure',
            accountName: nameNewAccount,
            clientId,
            tenantIdAzure,
            clientSecret,
            subscriptionId,
            billingAccount,
            },
            idtenant: idTenant,
            user: userFinal,
          };
    
          const bodyJson = JSON.stringify(body);
          dispatch(setNewAWSCredentials(bodyJson));
    }

    const resetStateAccunt=()=>{
        dispatch(resetStateCredentials())
    }

    const handleOnChange = () => {

      if(billingAccountRef.current.value === '' || subscriptionIdRef.current.value === ''){
        setEmptyFields(true)
      }
      else{
        setEmptyFields(false)
      }
      
    }
     return (
        <Form>
          
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="clientID">Client ID</Form.Label>
            <Form.Control
              type="text"
              ref={clientIdRef}
              id="clientID"
              disabled={validCredentials}
              placeholder="Enter Client ID"
              style={styleInput}
            />
          </Form.Group>
          
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="tenantIdAzure">Tenant ID</Form.Label>
            <Form.Control
              type="text"
              ref={tenantIdAzureRef}
              id="tenantIdAzure"
              disabled={validCredentials}
              placeholder="Enter Tenant ID"
              style={styleInput}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="clientSecret">Client Secret</Form.Label>
            <Button variant="light" style={botonSinFondo} className="ms-3" onClick={()=>{setShowPassword(!showPassword)}}> 
                {showPassword ? 
                    <VisibilityOutlinedIcon /> :
                    <VisibilityOffOutlinedIcon  />
                }
            </Button>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              ref={clientSecretRef}
              id="clientSecret"
              disabled={validCredentials}
              placeholder="Enter Client Secret"
              style={styleInput}
            />
          </Form.Group>
          

        {validCredentials === false && (
          <Button
              type="button"
              onClick={()=>{handleTestConnection()}}
              variant="outline-success"
              className="m-2"
            >
              {stateAccount === "ready" ? "Test connection" : "Loading..."}
              {stateAccount !== "ready" && (
                <Spinner
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
            )}

            {validCredentials === true && (
            <>
              <Form.Group className="form-group">
                <Form.Label style={styleLabel} htmlFor="subscriptionID">Subscription ID</Form.Label>
                <Form.Control
                  type="text"
                  ref={subscriptionIdRef}
                  id="subscriptionID"
                  // disabled={validCredentials}
                  placeholder="Enter Subscription ID"
                  style={styleInput}
                />
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label style={styleLabel} htmlFor="billingAccountInput">Billing Account</Form.Label>
                <Form.Control
                  type="text"
                  ref={billingAccountRef}
                  id="billingAccountInput"
                  onChange={handleOnChange}
                  // disabled={validCredentials}
                  placeholder="Enter Billing Account"
                  style={styleInput}
                />
              </Form.Group>




              <Link to='/accounts'>
                  <Button type="button" className="m-2" disabled={nameNewAccount === '' || emptyFields } variant="btn btn-primary" onClick={()=>{submitForm()}} >
                    Submit
                  </Button>
              </Link>
            </>
            )}
          <Link to='/accounts' > 
          <Button type="button" variant="btn btn-danger" onClick={()=>{resetStateAccunt()}}>
            cancel
          </Button>
          </Link>
        </Form>
      
  );
};

export default AccountAzureForm;
