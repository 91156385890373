import { fetchBackend } from "./fetch";


export const getAwsResults = async(body) => {
    const resp = await fetchBackend('accounts/differencepermonth', body ,'POST')
    if (resp.status === 204 || resp.status === 500)
        return {
            status: resp.status,
            data: []
        };
    else {
        const respJson = await resp.json();
        return {
            status: resp.status,
            data: respJson
        };
    }

}

export const getConnection = async(body) =>{
    const resp = await fetchBackend('aws/testcredentials',body , 'POST')
    const respJson =  resp.json()
    return respJson
}

export const setCredentials = async(body) =>{
    const resp = await fetchBackend('aws/setnewcredentials',body , 'POST')
    const respJson =  resp.json()
    return respJson
}

export const updateCredentials = async(body) =>{
    const resp = await fetchBackend('aws/updatecredentials',body , 'POST')
    const respJson =  resp.json()
    return respJson
}


export const getDataDashbords = async(body) =>{
    const resp = await fetchBackend('accounts/getyearlydata',body, 'POST')
    const respJson =  resp.json()
    return respJson
}



export const getDataDistributionFetch = async(body) =>{
    const resp = await fetchBackend('aws/getdistribution',body, 'POST')
    const respJson =  resp.json()
    return respJson
}


export const setDataDistributionFetch = async(body) =>{
    const resp = await fetchBackend('aws/setdistributionresults',body, 'POST')
    const respJson =  resp.json()
    return respJson
}


export const setDeleteAccount = async(body) =>{
    const resp = await fetchBackend('accounts/deleteaccount',body, 'POST')
    const respJson =  resp.json()
    return respJson
}


export const updateDataForAccountCard = async(body) =>{
    const resp = await fetchBackend('accounts/setcostallaccounts',body, 'POST')
    const respJson =  resp.json()
    return respJson
}


export const setTenanFromDB = async(body) =>{
    const resp = await fetchBackend('accounts/gettenantbyuser',body, 'POST')
    const respJson =  resp.json()
    return respJson
}