import { getDrivers,setNewDriverToDb,setNewDimensionToDb,deleteDriverInDb,deleteDimensionInDb,updateDimensionToDb } from "../../../helpers/drivers"
import { updateDriversWithDimension,processingRequest,readyRequest } from "./driverSlice"




export const getDriversToComponent = (body) => {
    return async( dispatch ) => {
        // dispatch( processingRequest() )
        const respJson = await getDrivers(body)
        if(respJson.status === 'true'){
//            console.log('respJson',respJson)
            dispatch(updateDriversWithDimension(respJson))
        }
        else{
            
            console.log('nada')
        }    
    }
}


export const setNewDriver = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const respJson = await setNewDriverToDb(body)
        if(respJson.status === 'true'){
            dispatch(readyRequest())
        }
        else{
            
            console.log('nada')
        }
        
    }
}


export const setNewDimension = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const respJson = await setNewDimensionToDb(body)
        if(respJson.status === 'true'){
            dispatch(readyRequest())
        }
        else{
            
            console.log('nada')
        }
        
    }
}


export const updateDimension = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const respJson = await updateDimensionToDb(body)
        if(respJson.status === 'true'){
            dispatch(readyRequest())
        }
        else{
            
            console.log('nada')
        }
        
    }
}



export const deleteDriver = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const respJson = await deleteDriverInDb(body)
        if(respJson.status === 'true'){
            dispatch(readyRequest())
        }
        else{
            
            console.log('nada')
        }
        
    }
}


export const deleteDimension = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const respJson = await deleteDimensionInDb(body)
        if(respJson.status === 'true'){
            dispatch(readyRequest())
        }
        else{
            
            console.log('nada')
        }
        
    }
}