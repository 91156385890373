import React, { useRef } from "react";
import { Form, Button} from "react-bootstrap";
import Card from "../../../components/Card";
import '../../../styles/containerPrincipal.css' 
import { useSelector,useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react'
import { setNewDimension } from "../../../store/slices/drivers/driverThunks";
import Loader from '../../../layouts/Loader'
import DimensionEditForm from "./DimensionEditForm";


const DimensionForm = () => {

    const dimensionNameRef = useRef("");

    const dimensionCodeRef = useRef("");

    const weightRef = useRef(0);

    const user = useAuth0();

    const dispatch = useDispatch();

    const { drivers, index, driverState , editDimesion  } = useSelector((state) => state.drivers);

    //Estilos para el formulario 
    const styleLabel = {color:'black'}
    const styleInput = {borderColor: '#899AF1', color: '#000'}

    const {  idTenant } = useSelector(
        (state) => state.costing
      );
   

    const handleSubmit=()=>{
      
      const body={
        tenantid:idTenant,
        dimensionCode:dimensionCodeRef.current.value,
        dimensionName: dimensionNameRef.current.value,
        weight:weightRef.current.value || 0 ,
        idDriver:drivers[index].idDriver,
        user: user.user.email
      }
      
      dispatch(setNewDimension(body))
    }
  

    if(driverState === 'processing'){
      return <Loader center={true}/>
    }

    if(editDimesion){
      return <DimensionEditForm/>
    }

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">New Member</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <p>Create new Member for {drivers[index].driverName}</p>
        <Form>
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Member Code </Form.Label>
            <Form.Control
              type="text"
              id="inputNameDriver"
              ref={dimensionCodeRef}
              placeholder="Enter Code"
              style={styleInput}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Member Name </Form.Label>
            <Form.Control
              type="text"
              id="inputNameDriver"
              ref={dimensionNameRef}
              placeholder="Enter Name"
              style={styleInput}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="inputWeight">Weight Distribution </Form.Label>
            <Form.Control
              type="number"
              id="inputWeight"
              ref={weightRef}
              placeholder="Weight %"
              style={styleInput}
              max={100}
            />
          </Form.Group>
          
          <Link to='/listdimesionbydrivers' > 
          <Button type="button" className="m-2" onClick={()=>{handleSubmit()}} variant="btn btn-primary" >

            Submit
          </Button>
          </Link>
          <Link to='/listdimesionbydrivers' > 
          <Button type="button" variant="btn btn-danger">
            cancel
          </Button>
          </Link>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default DimensionForm;
