import { getDetailNotificationDB,setNotificationInDb,getNotificationFromDb,getListNotificationFromDb,deleteNotificationFromDB,deactiveNotificationFromDB,getListsOfServices } from "../../../helpers/notifications" 
import { processingRequest,readyRequest,setNotifications,setListOfNotifications,setDetailNotification,setListsOfServices } from "./notificationSlice"
import { noDataToast,changeStateToast,changeStateMessageCustom,setMessageToShow } from "../toasts/toastSlice"


export const  setNewNotification = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const respJson = await setNotificationInDb(body)
        if(respJson.status === 'true'){
            dispatch(readyRequest())
        }
        else{
            dispatch(readyRequest())

            console.log('nada')
        }
        
    }
}


export const getListNotifications = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const respJson = await getNotificationFromDb(body)
        if(respJson.status === 200){
            dispatch(readyRequest())
            dispatch(setNotifications(respJson.data.data))
        }
        else if(respJson.status === 204){
            dispatch(readyRequest())
            
        }
        else{
            dispatch(readyRequest())
            dispatch(changeStateToast({state: true}))

        }
        
    }
}



export const getListOfAllNotifications = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const resp = await getListNotificationFromDb(body)
        if(resp.status === 200){
            const respJson = await resp.json()
            dispatch(readyRequest())
            dispatch(setListOfNotifications(respJson.data))
            dispatch(getDetailsNotification({ idNotification: respJson.data[0].idNotification} ))

        }
        else if(resp.status === 204){
            dispatch(readyRequest())
            dispatch(setMessageToShow('No notifications to show'))
            dispatch(changeStateMessageCustom({state: true}))
        }
        else{
            dispatch(readyRequest())
            dispatch(changeStateToast({state: true}))
        }
        
    }
}



export const deleteNotification = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        const respJson = await deleteNotificationFromDB({idNotification:body.idNotification,user:body.user})
        if(respJson.status === 'true'){
            const resp = await getListNotificationFromDb({tenantid:body.tenantid})
            if(resp.status === 200){
                const respJson = await resp.json()
                dispatch(readyRequest())
                dispatch(setListOfNotifications(respJson.data))
            }
            else if(resp.status === 204){
                dispatch(readyRequest())
            }
        }
        else{
            dispatch(readyRequest())
            dispatch(changeStateToast({state: true}))

        }
        
    }
}


export const deactivateNotification = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        const respJson = await deactiveNotificationFromDB({idNotification:body.idNotification,state:body.state,user:body.user})
        if(respJson.status === 'true'){
            const resp = await getListNotificationFromDb({tenantid:body.tenantid})
            if(resp.status === 200){
                const respJson = await resp.json()
                dispatch(readyRequest())
                dispatch(setListOfNotifications(respJson.data))
            }
            else if(resp.status === 204){
                dispatch(readyRequest())
            }
        }
        else{
            
            dispatch(changeStateToast({state: true}))

        }
        
    }
}





export const getDetailsNotification = (body) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const respJson = await getDetailNotificationDB(body)
        if(respJson.status === 200){
            dispatch(readyRequest())
            dispatch(setDetailNotification(respJson.data.data))
        }
        else if(respJson.status === 204){
            dispatch(readyRequest())
            dispatch(setMessageToShow('No data to show'))
            dispatch(changeStateMessageCustom({state: true}))
            
        }
        else{
            dispatch(readyRequest())
            dispatch(changeStateToast({state: true}))

        }
        
    }
}

//------Notifiaciones por servicio -----//

export const listOfServices  = (idAccount,idTenant) => {
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const respJson = await getListsOfServices({idAccount:idAccount,idTenant:idTenant})
        if(respJson.status === 'true'){
            dispatch(readyRequest())
            dispatch(setListsOfServices(respJson.data))
        }
        else{
            dispatch(readyRequest())

            console.log('nada')
        }
        
    }
}