import { React } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { passwordReset,changeUserStatus } from "../../../store/slices/users/usersThunks";
import { closeModal } from "../../../store/slices/modal/modalSlice";
const ChangeStatusUser = () => {
  const dispatch = useDispatch();
  const { userModalData } = useSelector((state) => state.users);

  const resetPassword = () => {
    dispatch(changeUserStatus(userModalData.tenant,userModalData.email))
    dispatch(closeModal())
    }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Change status user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
             Are you sure you want to change status of {userModalData.name} {userModalData.lastname}?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModal())
          }}
        >
          Close
        </Button>
        <Link to="/userlist">
          <Button variant="primary"  onClick={()=>resetPassword()}>
          Change Status
          </Button>
        </Link>
      </Modal.Footer>
    </>
  );
};

export default ChangeStatusUser;
