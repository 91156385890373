//aca es un componene te donde va  a ir el grafico de velocimetro en base a las notificaciones
import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { Dropdown, Button, Spinner,Col } from "react-bootstrap";

import {getTopServiceByAccount} from "../../../store/slices/accounts/accountThunks"


//apexcharts
import Chart from "react-apexcharts";




const ServiceBar = () => {

  const dispatch = useDispatch();

  const { cardUsageAccount } = useSelector((state) => state.costing);

  const { servicesByAccount ,stateAccount} = useSelector((state) => state.accounts);

//Array.isArray(cardUsageAccount) &&
  const [accountId, setIdAccount] = useState(Array.isArray(cardUsageAccount) ? cardUsageAccount[0]?.accountId : 0);
 
  const [seleccionado, setSeleccionado] = useState(Array.isArray(cardUsageAccount) ? cardUsageAccount[0].accountName : "")

  const [services, setServices] = useState([]);

  const [amounts, setAmounts] = useState([]);

  const [months, setMonths] = useState([]);

  
  function getLast13Months() {
    const today = new Date();
    const months = [];
    for (let i = 0; i < 13; i++) {
      const month = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const year = month.getFullYear();
      const monthNumber = month.getMonth() + 1;
      const monthString =
        monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
      const dateString = `${year}-${monthString}`;
      months.push(dateString);
    }
    setMonths(months);
    setmonthToDb(months[0]);
  }
  
   
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    return year + "-" + month;
  };


  const [monthToDb, setmonthToDb] = useState(getTodayDate());


   
  // va a buscar el listado de notificaciones activas para ponerlo en el dropdown
  useEffect(() => {

    getLast13Months();

    const month = monthToDb.slice(-2); 

    const year = monthToDb.slice(0, 4);
  
    let body = {
      data : {
        accountId: accountId,
        year: year,
        month: month,
        }
      };
    
    dispatch(getTopServiceByAccount(body));

  }, [])

  

  useEffect(() => {
    if (servicesByAccount) {
      setServices(servicesByAccount?.services);
      setAmounts(servicesByAccount?.amounts);
    }
  }, [servicesByAccount]);

 
 
  
    


      const seleccionarOpcion = (opcion, accountId) => {
        setSeleccionado(opcion);
        setIdAccount(accountId);

        const month = monthToDb.slice(-2); 

        const year = monthToDb.slice(0, 4);
        

        let body = {
          data : {
            accountId: accountId,
            year: year,
            month: month,
            }
          };
        
        dispatch(getTopServiceByAccount(body));

      };

      const changeDate = (month) => {
        setmonthToDb(month);
        const monthOnly = month.slice(-2); 

        const year = month.slice(0, 4);
        
        let body = {
          data : {
            accountId: accountId,
            year: year,
            month: monthOnly,
            }
          };
          
        
        dispatch(getTopServiceByAccount(body));
        
      };
      

      let chart2 = {
        options : {
            chart: {
            type: 'bar',
            height: 350,
            width: '100%'
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: true
          },
          xaxis: {
            categories: services,
          }
          },
          series: [{
            data: amounts
          }],
          }


      
    return (
  <div className="card" data-aos="fade-up" data-aos-delay="900" style={{ height: '75vh' }}>
    <div className="flex-wrap card-header d-flex justify-content-between">
      <div className="header-title d-flex align-items-center"> {/* Contenedor para el título y el spinner */}
        <h4 className="card-title">Top 5 most consumed services</h4>

        {stateAccount !== 'ready' && (
          <div className="ms-3"> {/* Espaciado a la izquierda del spinner para separarlo del título */}
            <Spinner animation="grow" className="text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
      </div>

      <Dropdown>
                <Dropdown.Toggle
                  as={Button}
                  variant=" text-secondary"
                  id="dropdownMenuButton3"
                  aria-expanded="false"
                >
                  {seleccionado}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton3"
                >
                  {Array.isArray(cardUsageAccount) && cardUsageAccount.map((opcion, indice) => (
                    <li className="card-title" key={indice}>
                      <Dropdown.Item
                        onClick={() =>
                          seleccionarOpcion(
                            opcion.accountName,
                            opcion.accountId
                          )
                        }
                        
                      >
                        {opcion.accountName}
                      </Dropdown.Item>
                    </li>
                  ))}
                </Dropdown.Menu>
              </Dropdown>



              <Dropdown>
                    <Dropdown.Toggle
                      as={Button}
                      variant=" text-secondary"
                      id="dropdownMenuButton3"
                      aria-expanded="false"
                    >
                      {monthToDb}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton3"
                     
                    >
                      {months.map((opcion, indice) => (
                        <li className="card-title" key={indice}  style={{zIndex:"9999"}}>
                          <Dropdown.Item
                          style={{  fontSize: '0.75rem'}}
                            onClick={() => {
                              changeDate(opcion);
                            }}

                           
                          >
                            {opcion}
                          </Dropdown.Item>
                        </li>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
    </div>

    
      <div >
     
        <Chart
          className="col-md-8 col-lg-8"
          options={chart2.options}
          series={chart2.series}
          type="bar"
          height="280"
          // width="550"
        />
 
      </div>
    
  </div>
    );
};

export default ServiceBar;

