import React, { useRef, useState,useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css'
import Card from "../../../Card";
import "../../../../styles/containerPrincipal.css";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import InfoIcon from '@mui/icons-material/Info';

import { useAuth0 } from "@auth0/auth0-react";
import { setNewNotification,listOfServices} from "../../../../store/slices/notifications/notificationThunks";
import { Link } from "react-router-dom";
import { getusers } from "../../../../store/slices/users/usersThunks";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import DangerousTwoToneIcon from "@mui/icons-material/DangerousTwoTone";
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import EditNotificationForm from "./EditNotificationForm";


const NewNotificationForm = () => {
  const dispatch = useDispatch();

  const alertNameRef = useRef("");
  const thresHoldRef = useRef(0);
  const smsRef = useRef(false);
  const whatsappRef = useRef(false);
  const emailRef = useRef(false);
  const appMobileRef = useRef(false);
  const informationRef = useRef(0);
  const warningRef = useRef(0);
  const alertRef = useRef(0);
  const user = useAuth0();


  //Estilos para el formulario 
  const styleLabel = {color:'black'}
  const styleInput = {borderColor: '#899AF1', color: '#000'}

  const { cardUsageAccount, idTenant } = useSelector((state) => state.costing);
  const  {users} = useSelector( (state) => state.users );
  const {isBeingEdited,accountServicesLists } = useSelector((state) => state.notifications);

//Logica de notification subs ---
  useEffect(()=> {
    dispatch(getusers({ tenantId: idTenant }))
    

      dispatch(listOfServices(cardUsageAccount && cardUsageAccount[0].accountId,idTenant))
    

  },[])

  const [selectedUsers, setSelectedUsers] = useState();
  const [allUsersSub, setAllUsersSub] = useState(false);


  const [notificationPerService, setNotificationPerService] = useState(false);
  const [selectedServices, setselectedServices] = useState();
  
  
  const [isServiceNotification, setIsServiceNotification] = useState(false);

  let usersInNotification = []
  const usersEmail = []
  users.map((data)=>{
     let obj = {
       value: data.iduser,
       label: `${data.name} ${data.lastname}`,
     };
     usersEmail.push(obj);
    })
//-------------------------------------//
  const handleCheckboxChange = (event) => {
    if(event.target.checked){
      setAllUsersSub(true)
      setIsServiceNotification(true)
      setSelectedUsers(usersEmail)
    }else{
      setAllUsersSub(false)
      setIsServiceNotification(false)

      setSelectedUsers([])
    }
  };
  function handleSelectSubscriptionUser(data) {
    setAllUsersSub(false)
    setSelectedUsers(data);
  }
//----------------- Notificaciones por servicio --------------------------//
const servicesForNotification = []

accountServicesLists.map((data)=>{
  let obj = {
    value: data.idAccount,
    label: data.serviceName,
  };
  servicesForNotification.push(obj);
})

  function handleNotificationPerService(event) {
    if(event.target.checked){
      setNotificationPerService(true)
      
     // setselectedServices(data);
    }else{
      setNotificationPerService(false)
      setselectedServices("")

    //  setselectedServices([])
    }
  };
   
function handleSelectedService(data){
  setselectedServices(data)
}
  function dataForNotificationPerService (idAccount,idTenant) {
    dispatch(listOfServices(idAccount,idTenant))
  }

//-------------------------------------------------//
  let dataToSelect = [];
  cardUsageAccount?.map((data) => {
    let obj = {
      value: data.accountId,
      label: data.accountName,
    };
    dataToSelect.push(obj);
  });
  const [selectedOption, setSelectedOption] = useState(dataToSelect[0]?.value || -1);
  
  const handleSubmit = () => {
    //Se envia el id del usuario en el body para almacenarlo en la db 
    selectedUsers.map((data)=>{
      usersInNotification.push(data.value);
    })
    const body = {
      edit:false,
      tenantid: idTenant,
      alertName: alertNameRef.current.value,
      accountid: selectedOption === -5 ? dataToSelect[0]?.value : selectedOption ,
      consolidated: selectedOption === -5  ? true : false,
      user: user.user.email,
      usersSubscriptions:{users:usersInNotification},
      details: {
        serviceNotification:notificationPerService,
        serviceName: notificationPerService  === true ? selectedServices.label : "All Services",
         threshold: thresHoldRef.current.value,
         information: informationRef.current.value,
         warning: warningRef.current.value,
         alert: alertRef.current.value,
        //  sms: smsRef.current.checked,
        //  whatsapp: whatsappRef.current.checked,
        //  appmobile: appMobileRef.current.checked,
         appmobile:false,
         sms: false,
         whatsapp:false,
         email: emailRef.current.checked,
        },
    };
    dispatch(setNewNotification(body));
  };


  const [emptyField, setEmptyField] = useState(true);


  const handleChangeEmpty = (event) => {
    const inputRef = event.target;
    if(inputRef.id !== "notificationNameInput"){
      const newValue = parseInt(inputRef.value);

      if (isNaN(newValue)) {
        inputRef.value = 1
        return;
      }
      const minValue = parseFloat(inputRef.min);
      const maxValue = parseFloat(inputRef.max);
      if (newValue < minValue) {
        inputRef.value = minValue;
      } else if (newValue > maxValue) {
        inputRef.value = maxValue;
      }
    }if(alertNameRef.current.value !=="" && selectedOption !== -1 && thresHoldRef.current.value !=="" && informationRef.current.value !=="" && warningRef.current.value !=="" && alertRef.current.value !==""){
      setEmptyField(false)
    }else{
      setEmptyField(true)
  }
};



  const handleChange = (event) => {
dataForNotificationPerService(event,idTenant)
    setSelectedOption(parseInt(event))
  }

  if(isBeingEdited){
    return <EditNotificationForm />
  }



  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">New Notification</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <p style={styleLabel}>Configure your notifications to be aware of your consumption</p>
        <Form>
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="notificationNameInput">Notification Name </Form.Label>
            <Form.Control
              type="text"
              id="notificationNameInput"
              ref={alertNameRef}
              placeholder="Enter the name of the notification"
              maxLength={30}
              style={styleInput}
              onChange={handleChangeEmpty}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label style={styleLabel}>Select Account</Form.Label>
            <select
              style={styleInput}
              className="form-select mb-3 shadow-none"
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            >
           

              {Array.isArray(dataToSelect) &&  (dataToSelect.map((prov, index) => (
                <option key={index} value={prov.value}>
                  {prov.label}
                </option>
              )))}

              { notificationPerService !== true &&dataToSelect?.length > 0 && (
               <option key={-1} value={-5} >
                  Consolidated
                </option>
              )}

            </select>

       

          </Form.Group>
<Form.Group  className="form-group">

{notificationPerService && (
  <>
    <Form.Label style={styleLabel} htmlFor="exampleInputText1">
      Service Notification
    </Form.Label>
    <Select
      options={servicesForNotification}
      placeholder="Select the services you want to be notified"
      value={selectedServices}
      style={{
        ...styleInput,
        backgroundColor: allUsersSub ? styleInput.backgroundColor : '#899AF1'
      }}
      onChange={handleSelectedService}
      isSearchable={true}
      isMulti={false}
    />
  </>
)}

            
<div className="d-flex align-items-center ms-3">
<Form.Check className="d-block form-group">
              <Form.Check.Input
                className="me-2"
                type="checkbox"
                defaultValue=""
                id="emailCheck"
                style={{marginTop:'19px'}}
                checked={notificationPerService} // Establece el estado del checkbox
                onChange={handleNotificationPerService} // Maneja el cambio del checkbox             
              />

<Form.Check.Label style={{ ...styleLabel, marginTop: '15px' }} htmlFor="emailCheck">
        Send notification by service
      </Form.Check.Label>
    </Form.Check>

    <Tooltip title="By selecting this option you will be notified only of the selected service in the chosen account">
      <InfoIcon fontSize="small" style={{ marginLeft: '5px' }} />
    </Tooltip>
  </div>  
             
  </Form.Group>              




          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Users Subscriptions </Form.Label>
            <Select  
            options={usersEmail}
            placeholder="Select users for send notifications email"
            value={selectedUsers}
            style={{
              ...styleInput,
              backgroundColor: allUsersSub ? styleInput.backgroundColor : '#899AF1'
            }}
            onChange={handleSelectSubscriptionUser}
            isSearchable={true}
            isMulti
          />
          <Form.Check className="d-block form-group">
              <Form.Check.Input
                className="me-2"
                type="checkbox"
                defaultValue=""
                id="emailCheck"
                style={{marginTop:'19px'}}
                checked={allUsersSub} // Establece el estado del checkbox
                onChange={handleCheckboxChange} // Maneja el cambio del checkbox             
              />
              <Form.Check.Label style={{...styleLabel,marginTop:'15px'}} htmlFor="emailCheck">
              Send notification to all users

              </Form.Check.Label>
             
            </Form.Check>
          </Form.Group>
          
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="thresholdInput">
              Alert Threshold ($){" "}
            </Form.Label>
            <Form.Control
              type="number"
              id="thresholdInput"
              ref={thresHoldRef}
              placeholder="Enter the amount"
              style={styleInput}
              onChange={handleChangeEmpty}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <InfoTwoToneIcon className="me-2" />
            <Form.Label style={styleLabel} htmlFor="informationInput">
              Information (%){" "}
            </Form.Label>
            <Form.Control
              type="number"
              id="informationInput"
              defaultValue={50}
              max={100}
              min={0}
              ref={informationRef}
              placeholder="Enter  percentage"
              style={styleInput}
              onChange={handleChangeEmpty}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <WarningTwoToneIcon className="me-2" />
            <Form.Label style={styleLabel} htmlFor="warningInput">Warning (%) </Form.Label>
            <Form.Control
              type="number"
              id="warningInput"
              defaultValue={75}
              ref={warningRef}
              max={100}
              min={0}
              placeholder="Enter  percentage"
              style={styleInput}
              onChange={handleChangeEmpty}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <DangerousTwoToneIcon className="me-2" />
            <Form.Label style={styleLabel} htmlFor="criticalInput">Critical (%) </Form.Label>
            <Form.Control
              type="number"
              id="criticalInput"
              defaultValue={90}
              ref={alertRef}
              max={100}
              min={0}
              placeholder="Enter  percentage"
              style={styleInput}
              onChange={handleChangeEmpty}
            />
          </Form.Group>


          <Form.Group className="form-group" >
            <p style={styleLabel}>Ways by which we can notify you of your consumption</p>
            <Form.Check className="d-block form-group">
              <Form.Check.Input
                className="me-2"
                type="checkbox"
                defaultValue=""
                id="emailCheck"
                ref={emailRef}
                defaultChecked
              />
              <Form.Check.Label style={styleLabel} htmlFor="emailCheck">
                Email
              </Form.Check.Label>
              <EmailTwoToneIcon className="ms-2"/>
            </Form.Check>


            <Form.Check className="d-block form-group" >
            <Tooltip title="This option will be enabled soon" placement="top">
              <Form.Check.Input
                className="me-2"
                type="checkbox"
                defaultValue={false}
                id="smsCheck"
                ref={smsRef}
                disabled={true}  
              />
              <Form.Check.Label htmlFor="smsCheck">
                SMS (Harbinger)
              </Form.Check.Label>
              <SmsTwoToneIcon className="ms-2"/>    
            </Tooltip>
            </Form.Check>

            <Form.Check className="d-block form-group">
            <Tooltip title="This option will be enabled soon" placement="top">
              <Form.Check.Input
                className="me-2"
                type="checkbox"
                defaultValue=""
                id="wspcheck"
                disabled={true}
                // defaultChecked
                ref={whatsappRef}
              />
              <Form.Check.Label htmlFor="wspcheck">
                Whatsapp (Harbinger)
              </Form.Check.Label>
              <svg className="ms-2"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07c0 1.22.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28z"/></svg>
            </Tooltip>
            </Form.Check>

          </Form.Group>
          <Link to={!emptyField &&  "/notificationsettings"} className="m-1" >
            <Button
              type="button"
              className="m-2"
              onClick={() => {
                handleSubmit();
              }}
              disabled={emptyField}
              variant="btn btn-primary"
            >
              Submit
            </Button>
          </Link>
          <Link to="/notificationsettings" className="m-1">
            <Button type="button" variant="btn btn-danger">
              cancel
            </Button>
          </Link>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default NewNotificationForm;
