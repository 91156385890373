import { createSlice } from '@reduxjs/toolkit'




const initialState = {
    openModalState: false,
    modalSelected: null
  }
  
  export const modalSlice = createSlice({
      name: 'modal',
      initialState,
      reducers: {
          openModal: (state, {payload}) => {
              state.openModalState = true;
              state.modalSelected = payload;
          },
          closeModal: (state) => {
              state.openModalState = false;
          },
      },
  })
  
  // Action creators are generated for each case reducer function
  export const { openModal, closeModal } = modalSlice.actions