import {fetchBackend} from "./fetch";



const getNamesAccounts = async(body) => {
    const resp = await fetchBackend('forecast/getdatanames', body ,'POST')
    const respJson =  resp.json()
    return respJson
}

const getDataAccount = async(body) => {
    const resp = await fetchBackend('forecast/getaccountdata', body ,'POST')
    const respJson =  resp.json()
    return respJson
}



export {getNamesAccounts,getDataAccount}