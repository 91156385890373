import React from 'react'
import {Row,Col,Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Card from '../../components/Card'
import { useSelector,useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Loader from '../../layouts/Loader'
import { useAuth0 } from '@auth0/auth0-react'
import ToastUsers from '../../components/cco/toasts/ToastUsers'
import { openModal } from '../../store/slices/modal/modalSlice'
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import { getDataForReports } from '../../store/slices/reports/reportsThunks';


const Reports =() =>{
   const { idTenant } = useSelector( (state) => state.costing );
   const { reportState,allReportsSend,sendReportaTable } = useSelector( (state) => state.reports );
    const dispatch = useDispatch();
    const user = useAuth0();
    const emailauth0 = user.user.email

  
    useEffect(()=> {
       dispatch(getDataForReports(idTenant))
    },[])

   
   return (
     <>
       <ToastUsers />
       
         <>
           {sendReportaTable !== "ready" ? (
             <Loader />
           ) : (
             <div>
               <Row>
                 <Col sm="12">
                   <Card>
                     <Card.Header className="d-flex justify-content-between"></Card.Header>
                     <Card.Body className="px-0">
                       <div className="table-responsive">
                         <table
                           id="user-list-table"
                           className="table table-striped"
                           role="grid"
                           data-toggle="data-table"
                         >
                           <thead>
                             <tr className="ligth">
                               <th>Type</th>
                               <th>Send to</th>
                               <th>Period</th>
                               <th>Send date</th>
                                <th>Download </th>
                             </tr>
                           </thead>
                           <tbody>
                             {allReportsSend?.length > 0 ? (
                               allReportsSend?.map((item) => (
                                 <tr key={item.iduser}>
                                  
                                   <td className="text-center">
                                     {item.typeReport === null ? (
                                       item.typeReport
                                     ) : (
                                        item.typeReport
                                     )}
                                   </td>
                                   <td >
                                   <Tooltip
                                         title={item.sendTo
                                          .filter(email => typeof email === 'string')  // Filtra solo las cadenas
                                          .map(email => email.replace(/'/g, ''))  // Elimina las comillas simples
                                          .join(',\n')}
                                         position="top"
                                         trigger="mouseenter"
                                         delay={200}
                                       >
                                    <div style={{marginLeft:'18px'}}>
                                   <PersonIcon/>
                                    </div>
                                </Tooltip>
                                   </td>
                                   <td>{item.period}</td>
                                  
                                   <td> {item.date}</td>
                                      <td style={{cursor:'pointer',marginLeft:'10px'}} >
                                        <div style={{marginLeft:'18px'}}>  
                                   <a href={item.reportUrl} target="_blank" rel="noopener noreferrer">
                                      <PictureAsPdfIcon/>
                                   </a>
                                          </div>

                                      </td>
                                   
                                 </tr>
                               ))
                             ) : (
                               <tr>
                                 <td colSpan="11" className="text-center">
                                   No data available in table
                                 </td>
                               </tr>
                             )}
                           </tbody>
                         </table>
                       </div>
                     </Card.Body>
                   </Card>
                 </Col>
               </Row>
             </div>
           )}
         </>
       
     </>
   );}

export default Reports;