import React, { useRef,useEffect,useState } from "react";
import { Form, Button,Spinner} from "react-bootstrap";
import '../../../../styles/containerPrincipal.css' 
import { useSelector,useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from "react-router-dom";
import { testAWSConnection,setNewAWSCredentials } from "../../../../store/slices/accounts/accountThunks";
import { resetStateCredentials } from "../../../../store/slices/accounts/accountSlice";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';


const AwsEditForm = () => {
    const user = useAuth0();

    const dispatch = useDispatch();
    const { cardUsageAccount } = useSelector((state) => state.costing);

    const { idTenant } = useSelector((state) => state.costing);

    const [showPassword, setShowPassword] = useState(false);

    const { validCredentials,stateAccount,nameNewAccount,dataAccountToEdit,indexOfAccountToEdit } = useSelector((state) => state.accounts);

    const [editCredentials, setEditCredentials] = useState(false);

    const [diasbleButtonEdit, setDiasbleButtonEdit] = useState(false);

    const botonSinFondo = { backgroundColor: 'transparent',border: 'none',padding: 0}
    

    //Estilos para el formulario 
    const styleLabel = {color:'black'}
    const styleInput = {borderColor: '#899AF1', color: '#000'}


    const accesKeyRef = useRef('');
    const secretAccessKeyRef = useRef('');

    const handleTestConnection=()=>{

        let accessKeyId = accesKeyRef.current.value.trim();
        let secretAccessKey = secretAccessKeyRef.current.value.trim();

        const body = { accessKeyId, secretAccessKey }
        dispatch(testAWSConnection(body))
    }
    
    const activateEditCredentials=()=>{
      setDiasbleButtonEdit(true)
      setEditCredentials(true)
    }

    useEffect(() => {
      if (validCredentials === true) {
        setEditCredentials(false)
      }
    }, [validCredentials]);
    
    

    const submitForm=()=>{
        let accessKeyId = accesKeyRef.current.value.trim();
        let secretAccessKey = secretAccessKeyRef.current.value.trim();
        const body = {
            data: {
            cloudProvider: 'AWS',
            accountName: nameNewAccount,
            accessKeyId,
            secretAccessKey,},
            idtenant: idTenant,
            user: user.user.email,
            update:1,
            idAccount:cardUsageAccount[indexOfAccountToEdit].accountId
          };
    
          const bodyJson = JSON.stringify(body);
          dispatch(setNewAWSCredentials(bodyJson));
          
    }

    const resetStateAccunt=()=>{
        dispatch(resetStateCredentials())
    }
    
     return (
        <Form>


          <Button
              type="button"
              onClick={activateEditCredentials}
              variant="outline-secondary"
              className="m-2"
              disabled={diasbleButtonEdit}
              size="sm"
          >
              Edit Credentials
          </Button>
          
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Access Key ID</Form.Label>
            <Form.Control
              type="text"
              ref={accesKeyRef}
              id="inputAccessKey"
              defaultValue={dataAccountToEdit.accessKeyId}
              disabled={!editCredentials}
              placeholder="Enter Access Key ID"
              style={styleInput}
            />
          </Form.Group>
          
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Secret Access Key</Form.Label>
            <Button variant="light" style={botonSinFondo} className="ms-3" onClick={()=>{setShowPassword(!showPassword)}}> 
                {showPassword ? 
                    <VisibilityOutlinedIcon /> :
                    <VisibilityOffOutlinedIcon  />
                }
            </Button>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              ref={secretAccessKeyRef}
              id="inputSecretAccessKey"
              defaultValue={dataAccountToEdit.secretAccessKey}
              disabled={!editCredentials }
              placeholder="Enter Secret Access Key"
              style={styleInput}
            />
          </Form.Group>


        {editCredentials ?  
          <>
            {validCredentials === false && (
              <Button
                  type="button"
                  onClick={handleTestConnection}
                  variant="outline-success"
                  className="m-2"
                >
                  {stateAccount === "ready" ? "Test connection" : "Loading..."}
                  {stateAccount !== "ready" && (
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
              </Button>
            )}
                {validCredentials === true && (
                  <Link to='/accounts'>
                      <Button type="button" className="m-2" disabled={nameNewAccount === ''} variant="btn btn-primary" onClick={submitForm} >
                      Submit
                      </Button>
                  </Link>
                )} 
          </>
          :
            <Link to='/accounts'>
              <Button type="button" className="m-2" disabled={nameNewAccount === ''} variant="btn btn-primary" onClick={submitForm} >
              Submit
              </Button>
            </Link>

          }
          <Link to='/accounts' > 
          <Button type="button" variant="btn btn-danger" onClick={resetStateAccunt}>
            cancel
          </Button>
          </Link>
        </Form>
      
  );
};

export default AwsEditForm;
