import { React ,useEffect } from "react";
import { Row, Col} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Circularprogressbar from "../../circularprogressbar";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { getDataForConsolidatedCard } from "../../../store/slices/accounts/accountThunks";
import { useAuth0 } from '@auth0/auth0-react'
import {Spinner} from "react-bootstrap";

//Count-up
import CountUp from "react-countup";
import { getAccountAndDatesById } from "../../../store/slices/forecast/forecastThunks";

const ConsolidatedCard = (props) => {
  const dispatch = useDispatch();
  const { cardUsageAccount,idTenant  } = useSelector(
    (state) => state.costing
  );
   
  const { dataToConsolidatedCard } = useSelector((state) => state.accounts);
  const {forecastConsolidated} = useSelector((state) => state.forecast);



const user = useAuth0();
const userEmail = user.user.email



  useEffect(() => {
    dispatch(getAccountAndDatesById(userEmail,-1,"Consolidated",idTenant,))
  }, []);

  const DiasPasadosMesActual = () => {
    // Obtener la fecha actual
    const fechaActual = new Date();  
    // Obtener el número del día actual en el mes (1-31)
    const diaActual = fechaActual.getDate();  
    // Obtener la cantidad total de días en el mes actual
    const totalDiasMes = new Date(
      fechaActual.getFullYear(),
      fechaActual.getMonth() + 1,
      0
    ).getDate();  
    // Calcular la cantidad de días que han pasado en el mes actual
    const diasPasados = diaActual;
    return diasPasados;
  }

  //aca hago los calculos necesario para poder estimar cuanto va a gastar el cliente en el mes actual si sigue con la misma tendencia
  // y sacar el porcentaje de cambio con el mes anterior
  const diasPasados = DiasPasadosMesActual();


  const consolidatedPreviusMonth = dataToConsolidatedCard?.priorMonth || 0;

  const  ConsolidatedCurrentMonth = dataToConsolidatedCard?.currentMonth || 0;

  const activeServices = dataToConsolidatedCard?.activeServices || 0;

  const percentageChange = dataToConsolidatedCard?.percentageChange || 0;
  
  const YTD = cardUsageAccount?.reduce((suma, objeto) => suma + parseFloat(objeto.ytd), 0);
  
  let trendPercetageFixed = 0
  let trendPercetage = 0;

  //aca voy a validar que si el mes anterior es 0 no haga la cuenta para que no me de infinito
  
  if(ConsolidatedCurrentMonth !== 0 && consolidatedPreviusMonth !== 0){
    const  totalXday = ConsolidatedCurrentMonth / diasPasados;
    const  trenCurrentMonth = totalXday * 30;

    trendPercetage = ((trenCurrentMonth * 100) / consolidatedPreviusMonth) - 100;

    trendPercetageFixed = trendPercetage.toFixed(0);
  }

  const difference = forecastConsolidated.totalCost - consolidatedPreviusMonth;
  const percentageDifference = ((difference / consolidatedPreviusMonth) * 100).toFixed(2);
  return (
    <div className="card">
      <Row>
        <Col className="align-items-center justify-content-center">
          <div className="  p-4 ms-3">
            <h4 className="">Consolidated</h4>
            <div className="card-body ">
              <div className="progress-widget ">
                <Circularprogressbar
                  stroke={props.coloralertmode}
                  width="80px"
                  height="80px"
                  Linecap="rounded"
                  trailstroke="#ddd"
                  strokewidth="4px"
                  style={{ width: 80, height: 80 }}
                  value={percentageChange}
                  id="circle-progress-01"
                >
                  <img
                    style={{ width: 65, marginTop: -5 }}
                    src="/ccoicon.png"
                    alt="AWS logo"
                  />
                </Circularprogressbar>
                <div className="progress-detail">
                  <p className="mb-2">{props.monthAndYear}</p>
                  <h3 className="counter">
                    $
                    <CountUp
                      start={0}
                      end={ConsolidatedCurrentMonth}
                      duration={5}
                    />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </Col>
        {/* //////////////////////////////////////////////////////////////////////////////////////////////// */}

        <Col>
          <Row>
            <Col className="p-4 ms-3">
              <p className="m-0">Current YTD </p>
              <h5 className="mt-2">
                $ {"   "}
                <CountUp start={0} end={YTD} duration={3} />
              </h5>
            </Col>
          </Row>
          <Row>
            <Col className="p-4 ms-3">
              <p className="mb-0">Total number of active services </p>

              <h5 className="mt-2">
                <CountUp start={0} end={activeServices } duration={3} className="me-2" />
              </h5>
            </Col>
          </Row>
        </Col>

        {/* //////////////////////////////////////////////////////////////////////////////////////////////// */}

        <Col>
          <Row>
            <Col className="p-4 ms-3">
              <p className="m-0">Prior month total cost : 
</p>
              <div className="d-flex justify-content-between w-50 align-items-center">
                <div className="me-2">
                  <h5 className="mt-2">
                    $ {"   "}
                    <CountUp
                      start={0}
                      end={consolidatedPreviusMonth}
                      duration={3}
                      className="me-2"
                    />
                  </h5>
                </div>
                { isNaN(percentageDifference) ? (
    <p style={{ fontSize: '0.75rem',height:'0.15vh' }}>
    <Spinner animation="border" variant="primary" />
  </p>

) : (
  trendPercetage < 0 ? (    
    <div className="d-flex align-items-center" style={{color:'green'}}>
      <ArrowCircleDownIcon fontSize="small" />
      <p className="m-0" style={{ fontSize: "15px" }}>{percentageDifference}% </p>
    </div>
  ) : (  
    <div className="d-flex align-items-center " style={{color:'red'}}>
      {/* //aca hago el calculo para ver si el trend es positivo o negativo */}
      <ArrowCircleUpIcon fontSize="small" />
      <p className="m-0" style={{ fontSize: "15px" }}>{percentageDifference}% </p>
    </div>
  )
)}

           

              </div>
            </Col>
          </Row>
          <Row>
            <Col className="p-4 ms-3">
              <p className="m-0">Consolidated total forecast: </p>
              <h5 className="mt-2">
              $ {"   "}
                <CountUp start={0} end={forecastConsolidated.totalCost || 0} duration={3} />
              </h5>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ConsolidatedCard;
