import { useAuth0 } from '@auth0/auth0-react'
import { React,useState,useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { checkingAuthentication } from '../../../store/slices/auth/authThunks'
import Loader from '../../../layouts/Loader'
// import NewUserRegisterScreen from './NewUserRegisterScreen'
import ResetPasswordScreen from './ResetPasswordScreen'
const RedirectUserScreen = () => {
   
    const [resetPasswordRequested, setResetPasswordRequested] = useState(false)
    const { loginWithRedirect } = useAuth0();
    const dispatch = useDispatch();
    const URL = process.env.REACT_APP_AUTH0_RESET_PASSWORD_REDIRECT_URL;


    useEffect(() => {
        if(window.location.href.includes(URL)){
       
            setResetPasswordRequested(true)
        } else {
            loginWithRedirect();
            dispatch( checkingAuthentication() )
        }
    }, [])
    

     if(resetPasswordRequested){
         return <ResetPasswordScreen />
     }
    
    // if(showRegisterScreen){
    //     return <NewUserRegisterScreen />
    // }

  return (
    <Loader />
  )
}
export default RedirectUserScreen