import { createSlice } from '@reduxjs/toolkit'




const initialState = {
   advisorState:'ready',   //esta variable la utilizo para saber si estoy procesando una peticion o no
    oneAccountData:[],
    advisorCards:{},
    allMonths:[]

  }
  
  export const advisorSlice = createSlice({
      name: 'advisor',
      initialState,
      reducers: {
        //actualizo el objeto drivers con los drivers que me devuelve el backend
        getAllCards : (state,{payload})=>{
            state.advisorCards = payload
        },
        setAccountsToSelect : (state,{payload})=>{
            state.accountsNamesAndIds = payload
        },
        setStateAdvisor:(state,{payload})=>{
            state.advisorState = payload
        },
        allMonths:(state,{payload}) =>{
            state.allMonths= payload   
        }

      },
  })
  // Action creators are generated for each case reducer function
  export const {getAllCards,setStateAdvisor,allMonths} = advisorSlice.actions