import { fetchBackend } from "./fetch";



export const setNotificationInDb = async(body) => {
    const resp = await fetchBackend('notification/setnotification', body ,'POST')
    const respJson =  resp.json()
    return respJson
}


export const getNotificationFromDb = async(body) => {
    const resp = await fetchBackend('notification/getnotification', body ,'POST')
    if (resp.status === 204 || resp.status === 500) return {
        status: resp.status,
        data:   []
    };
    else {
        const respJson = await resp.json();
        return {
            status: resp.status,
            data: respJson
        };
    }
}


export const getListNotificationFromDb = async(body) => {
    const resp = await fetchBackend('notification/getlistnotifications', body ,'POST')
    return resp
}

export const deleteNotificationFromDB = async(body) => {
    const resp = await fetchBackend('notification/deletenotification', body ,'POST')
    const respJson =  resp.json()
    return respJson
}

export const deactiveNotificationFromDB = async(body) => {
    const resp = await fetchBackend('notification/deactivatenotification', body ,'POST')
    const respJson =  resp.json()
    return respJson
}




export const getDetailNotificationDB = async(body) => {
    const resp = await fetchBackend('notification/getdetailsnotification', body ,'POST')
    if (resp.status === 204 || resp.status === 500) return {
        status: resp.status,
        data:   []
    };
    else {
        const respJson = await resp.json();
        return {
            status: resp.status,
            data: respJson
        };
    }
}




// Lista de servicios por cuenta :) //
export const getListsOfServices = async(body) => {
    const resp = await fetchBackend('notification/getserviceslist', body ,'POST')
    const respJson =  resp.json()
    return respJson
}


