import React, { useEffect } from "react";
import { Button, Row, Col  } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../styles/drivers.css";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../../store/slices/modal/modalSlice";
import Card from "../../components/Card";
import {Delete,ModeEdit} from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteButtonDriver from "../../components/cco/buttons/DeleteButtonDriver";
import { setIndexDimensionSelected, setFalseEditDimension,setTrueEditDimension } from "../../store/slices/drivers/driverSlice";
import { getDriversToComponent } from "../../store/slices/drivers/driverThunks";
import { useHistory } from 'react-router-dom';
const ListDimensionsByDriver = () => {
  const history = useHistory();
  const dispatch = useDispatch();
const {openModalState} = useSelector((state) => state.modal);
  const { drivers, index,driverState } = useSelector((state) => state.drivers);
  const { idTenant } = useSelector((state) => state.costing);
  const { rolConfig } = useSelector((state) => state.roles);
  const  {user} = useSelector( (state) => state.profile );
  useEffect(() => {
    // Si drivers[index].driverName es null o undefined, redirige a /drivers
    if (!drivers[index]?.driverName) {
      history.push('/drivers');
    }
  }, [drivers, index, history]);

useEffect(() => {
  dispatch(setFalseEditDimension())
 }, [])

 useEffect(() => {
   dispatch(getDriversToComponent({ tenantid: idTenant }));
 }, [driverState,openModalState]);
   

  const openModalToDelete = (indice)=>{
    dispatch(setIndexDimensionSelected(indice))
    dispatch(openModal("DeleteDimension"))
  }

  const setDataToEdit=(indice)=>{
    dispatch(setTrueEditDimension())
    dispatch(setIndexDimensionSelected(indice))
  }

  return (
    <>  
      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">              
                <h4 className="card-title">{drivers[index]?.driverName}</h4>
              </div>
             {user && rolConfig?.VIEWS?.DRIVERS?.DIMENSION_LIST.DELETE_DRIVER.includes(user[0]?.iduserrole) &&( <DeleteButtonDriver  />)}
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive mt-4">
                <table
                  id="basic-table"
                  className="table table-striped mb-0"
                  role="grid"
                >
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Weight</th>
                     {user && rolConfig?.VIEWS?.DRIVERS?.DIMENSION_LIST.ACTIONS_DIMENSION.includes(user[0]?.iduserrole) &&( <th>Actions</th>)}
                    </tr>
                  </thead>
                  <tbody>                
                    {drivers[index]?.dimensionName[0] ? (
                      drivers[index].dimensionName.map((data,indice) => (
                        <tr  key={indice}>                                                  
                    <td style={drivers[index].dimensionVisible[indice] === 1 ?{color:'black'}:{color:'#8F97AA'}}>{drivers[index].dimensionCode[indice]}</td>
                          <td>
                            <div className="d-flex align-items-center">                          
                            <h6 style={drivers[index].dimensionVisible[indice] === 1 ?{color:'black'}:{color:'#8F97AA'}}>{data}</h6>                     
                            </div>
                          </td>

                          <td>
                            <div className="d-flex align-items-center">                          
                            <h6 style={drivers[index].dimensionVisible[indice] === 1 ?{color:'black'}:{color:'#8F97AA'}}>{drivers[index].weight[indice]}</h6>                     
                            </div>
                          </td>
                       {user && rolConfig?.VIEWS?.DRIVERS?.DIMENSION_LIST.ACTIONS_DIMENSION.includes(user[0]?.iduserrole) && (   <td>
                            <Link to="/newmember" onClick={()=>{setDataToEdit(indice)}} className="m-2">
                                <Button  className="m-2" > 
                                    <ModeEdit  /> 
                                </Button>
                            </Link>
                         {  drivers[index].dimensionVisible[indice] === 1 ?
                      
                             <Button  variant="success" onClick={()=>{openModalToDelete(indice)}}> 
                           <VisibilityIcon /> 
                       </Button> :
                        <Button  variant="danger" onClick={()=>{openModalToDelete(indice)}}> 
                           <VisibilityOffIcon /> 
                       </Button>                                                            }
                            
                           
                          </td>)}
                        </tr>
                      ))
                    ) : (
                      <tr key="no-data">
                        <td colSpan="3">
                        There are no members loaded in this driver
                        </td>
                      </tr>
                    )}
                    
                  </tbody>
                </table>                           
              </div>
              <div className="d-flex justify-content-end">
              <Link to='/drivers'>
               <Button className="mx-4 my-4">
                  Back to drivers
                </Button>
               </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ListDimensionsByDriver;
