import { React } from "react";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { useDispatch } from "react-redux";
import {  OverlayTrigger, Tooltip } from "react-bootstrap";
import { openModal } from "../../../store/slices/modal/modalSlice";
import '../../../styles/drivers.css'
const DeleteButtonDriver = () => {
  const dispatch = useDispatch();



  
  const openModalToDelete = ()=>{
    dispatch(openModal("DeleteButtonDriver"))
  }


  const renderTooltipDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Delete Driver
    </Tooltip>
  );
  

  return (
    <div className="d-flex">
           <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltipDelete}
      >
       
          
        <div className="custom-cursor" onClick={()=>{openModalToDelete()}}> 
          <DeleteTwoToneIcon color="action"  sx={{ fontSize: 30 }}/>
        </div>
         
        
      </OverlayTrigger>
    </div>
  );
};

export default DeleteButtonDriver;
