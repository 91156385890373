import {getAllusers, addUserInDb,registerUserWithEmailAndPassword,  resetPassword,  verifyUserInDb,  statusUser,  updateUserData,} from "../../../helpers/users";
import {  allUsers,processingRequest,  readyRequest,  failedRequest,  listState,  errorMessage,
  updateStatusUser,
  editFormActive,
  editProfileFormActive,
  userStatusToast,
  handleAuth0Messages
} from "./usersSlices";

// Funcion para traer todos los usuarios de la base de datos
export const getusers = (body) => {
  return async (dispatch) => {
    dispatch(processingRequest());
    const respJson = await getAllusers(body);
    if (respJson.status === 200) {
      let respJsonData= respJson.data.data
      const filteredData = respJsonData.filter(user => user.iduserrole !== 0);
      const sortedData = filteredData.sort((a, b) => b.iduser - a.iduser); // Ordena de mayor a menor por iduser
      await dispatch(allUsers(sortedData));
      dispatch(listState());
    } else {
      // No debe hacer nada
    }
  };
};
// Funcionque verifica si el usuario esta en la base de datos, se agrego a auth0 y se agrego a la base de datos correctamente
// maneja los errores de auth0 y de la base de datos para mostrarlos en el componente de userList.jsx
export const addUser = (body, data) => {
  return async (dispatch) => {
    
    const verifyUserDb = await verifyUserInDb({
      userEmail: body.userEmail,
      tenantId: body.tenantId,
    });
    dispatch(processingRequest());
    if (verifyUserDb.status === 200) {
      const authUser = await registerUserWithEmailAndPassword(data);
      if (authUser.ok) {
        const respJson = await addUserInDb(body);
        if (respJson.status === 200) {
          dispatch(allUsers(respJson));
          dispatch(errorMessage({message:respJson.message,error:false}))
          dispatch(readyRequest());
        } else {
          dispatch(errorMessage({message:respJson.message,error:true}))
        }
      } else {
        authUser.jsonResp.error
          ? dispatch(errorMessage({message:authUser.jsonResp.error,error:true}))
          : dispatch(errorMessage({message:"error in auth0: check the list of users ",error:true}));
        dispatch(failedRequest());
      }
    } else {
      dispatch(failedRequest());      
      dispatch(errorMessage({message:verifyUserDb.data.message,error:true}));
    }
  };    
};

//funcion para editar los datos del usuario en la base de datos y en el store de redux
export const editUserData = (body, data) => {
  return async (dispatch) => {
    dispatch(processingRequest());
    const respJson = await updateUserData(body);
    if (respJson.status === 200) {
      dispatch(allUsers(respJson));  
      dispatch(errorMessage({message:respJson.data.message,error:false}))
      dispatch(listState());
      dispatch(activeEditForm());
    } else {
      dispatch(errorMessage(respJson.data.message));
      dispatch(errorMessage({message:respJson.data.message,error:true}))
    }
  };
};
//funcion para cambiar el estado del usuario en la base de datos y en el store de redux
export const changeUserStatus = (idTenant, userEmail) => {
  return async (dispatch) => {
    dispatch(updateStatusUser("loading"));
    const resp = await statusUser({ tenantId: idTenant, userEmail: userEmail });
    if (resp.status === 200) {
      dispatch(updateStatusUser(resp.data.userStatus));
      dispatch(userStatusToast(resp.data.userStatus));
      dispatch(allUsers(resp.data.data));
      dispatch(updateStatusUser("ready"));
    }else{

    }
  };
};
//Funcion para activar el formulario de edicion de usuario en userList
export const activeEditForm = (status) => {
  return async (dispatch) => {
    dispatch(editFormActive(status));
  };
};



//Funciones que trabajan con las request de auth0
//Funcion para el reseteo de contraseña desde userList/profile
export const passwordReset = (data) => {
  return async (dispatch) => {
     let respAuth = await resetPassword(data);
      if(respAuth){
        dispatch(handleAuth0Messages(`Message to reset your password sent to email: ${data}`))
      }
  };
  
};


//Funcion para el forgot password del login universal
export const forgotPassword = (data) => {
  return async (dispatch) => {
    await resetPassword(data);
  };
};



