//router
import IndexRouters from "./router/index"

//orlando boton
//scss
import "./assets/scss/hope-ui.scss"
import "./assets/scss/dark.scss"
import "./assets/scss/rtl.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/customizer.scss"
import Loader from "./layouts/Loader"
import RedirectUserScreen from "./components/cco/auth/RedirectUserScreen"
import { useAuth0 } from '@auth0/auth0-react';


function App() {

  const { isAuthenticated, isLoading } = useAuth0();

  if(isLoading){
    return <Loader/>
  }

  return (
   
      <div className="App">
        {
        isAuthenticated
        ?
        
        <IndexRouters />
        
        :<RedirectUserScreen />
        }
      </div>
  );
}

export default App;
