import React, { useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AwsEditForm from "./AwsEditForm";
import SnowflakeEditForm from "./SnowflakeEditForm";
import AzureEditForm from "./AzureEditForm";
import Card from "../../../Card";
import ValidCredentialsAlert from "../../alerts/ValidCredentialAlert";
import { updateNameNewAccount } from "../../../../store/slices/accounts/accountSlice"; 
import { getCredentialsFromAccount } from "../../../../store/slices/accounts/accountThunks";
import GcpEditForm from "./GcpEditForm";
const AccountEditForm = () => {

  const dispatch = useDispatch();

  const { cardUsageAccount } = useSelector((state) => state.costing);
   
  const { validCredentials,indexOfAccountToEdit,dataAccountToEdit } = useSelector((state) => state.accounts);
  
  //Estilos para el formulario 
  const styleLabel = {color:'black'}
  const styleInput = {borderColor: '#899AF1', color: '#000'}

  useEffect(() => {
    dispatch(getCredentialsFromAccount({'accountId':cardUsageAccount[indexOfAccountToEdit].accountId}))
  }, [])
  
  
return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">Edit Credential</h4>
        </div>
      </Card.Header>
      <Card.Body>
        {/* <p>Load your account data and credentials to access your consumption</p> */}
        <Form>
        <ValidCredentialsAlert/>    
        <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Account Name</Form.Label>
            <Form.Control
              type="text"
              onChange={()=>{dispatch(updateNameNewAccount(document.getElementById('inputAccountName').value))}}
              id="inputAccountName"
              defaultValue={cardUsageAccount[indexOfAccountToEdit].accountName}
              placeholder="Enter Account Name"
              maxLength={25}
              style={styleInput}
            />
          </Form.Group>


          <Form.Group className="form-group">
            <Form.Label style={styleLabel} >Cloud Provider</Form.Label>
            <select style={styleInput} disabled={true} className="form-select mb-3 shadow-none" >
              <option value="cardUsageAccount[indexOfAccountToEdit].cloudProvider">{cardUsageAccount[indexOfAccountToEdit].cloudProvider}</option>
            </select>
          </Form.Group>
        </Form>
        {(cardUsageAccount[indexOfAccountToEdit].cloudProvider === 'AWS' && (<AwsEditForm/>)) ||
          (cardUsageAccount[indexOfAccountToEdit].cloudProvider === 'Snowflake' && (<SnowflakeEditForm/>)) ||
          (cardUsageAccount[indexOfAccountToEdit].cloudProvider === 'Azure' && (<AzureEditForm/>)) ||
          (cardUsageAccount[indexOfAccountToEdit].cloudProvider === 'GCP' && (<GcpEditForm/>))

        }
      </Card.Body>
    </Card>
  );
};

export default AccountEditForm;
