import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user:[],     //este objeto lo utilizo para guardar los users que me devuelve el backend
   profileStatus:'',
   messageStatus:'',
   profileForm:false
  }

  export const profileSlice = createSlice({
  name: 'profile',
      initialState,
      reducers: {

    profileData: (state,{payload})=>{
        state.user = payload.data
    },
    processingRequest: (state) => {
        state.profileStatus = 'processing';
    },
      //cambio el estado de la variable driverState a ready para saber que estoy listo para procesar una peticion
      readyRequest: (state) => {
      state.profileStatus = 'ready';
    }, editProfileFormActive:(state,{payload}) => {
      state.profileForm = payload;
    }, handleMessageStatus:(state,{payload}) => {
      state.messageStatus = payload;
    }
       
  }})

  export const {profileData,processingRequest,readyRequest,editProfileFormActive} = profileSlice.actions;