import React from 'react';
import './Paginado.css'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
;
export default function Paginado ({cardPerPage, allCards, paginado, setCurrentPage,currentPage}) { 
const pageNumbers = []
for (let i = 1; i<= Math.ceil(allCards/cardPerPage); i++){
    pageNumbers.push(i)
}
function handlePrev(){
    setCurrentPage(currentPage - 1)
}
function handleNext(){
setCurrentPage(currentPage + 1)
}
return (
    <nav className="pagi">
    <ul className="but">
      <button
        className="but"
        disabled={currentPage === pageNumbers[0] ? true : false}
        onClick={(e) => handlePrev(e)}
      >
        <ArrowBackIosNewIcon />
      </button>
      {/* {pageNumbers &&
        pageNumbers.map((number) => (
          <button className="but" key={number} onClick={() => paginado(number)}>
            <FiberManualRecordIcon/>
          </button>
        ))} */}
      <button
        className="but"
        disabled={currentPage === pageNumbers[pageNumbers.length - 1] ? true : false}
        onClick={(e) => handleNext(e)}
      >
        <ArrowForwardIosIcon />
      </button>
    </ul>
  </nav>
)
}