import {fetchBackend} from "./fetch";



const getAdvisorRecommends = async(body) => {
    const resp = await fetchBackend('advisor/iaresponse', body ,'POST')
    const respJson =  resp.json()
    return respJson
}
const getDatesForAdvisor = async(body) => {
    const resp = await fetchBackend('advisor/getdates' )
    const respJson =  resp.json()
    return respJson
}

export {getAdvisorRecommends,getDatesForAdvisor}