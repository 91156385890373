import { React } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../store/slices/modal/modalSlice";
const SnowflakeVerifyContract = () => {
  const dispatch = useDispatch();
  const  {contractsVerifyResult} = useSelector( (state) => state.contracts );


  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Snowflake Alert</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{color:'black'}}>
      The dates you enter are active in an existing contract<br/>
      dates in active contract  :<br/>
      From: {contractsVerifyResult.results.map((el)=> el.from)}<br/>
      To: {contractsVerifyResult.results.map((el)=> el.to)}<br/>
      
        Edit the dates to be able to add this contract
           
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModal())
          }}
        >
          Close
        </Button>
        {/* <Link to="/contracts">
          <Button variant="primary"  onClick={()=>handleDeleteContract()}>
          Delete contract
          </Button>
        </Link> */}
      </Modal.Footer>
    </>
  );
};

export default SnowflakeVerifyContract;
