import React, { useState,useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { Row,Col, Button, Form } from "react-bootstrap";
import Card from '../../Card'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Tooltip} from 'react-tippy';
import { setStatusFormEdit } from "../../../store/slices/contracts/contractsSlices";
import { editContract,regionsAndPlatforms } from "../../../store/slices/contracts/contractsThunks";
import { platforms } from "../../../helpers/contracts";
import { openModal } from "../../../store/slices/modal/modalSlice";
const SnowflakeEditContract = () => {
    const dispatch = useDispatch();
    const  {selectedContract,idCurrentAccount,platformsAndRegion} = useSelector( (state) => state.contracts );

  const [input, setInput] = useState({
    from:"",
    to:"",
    platform:"",
    plan:"",
    creditAmount:"",
    creditPrice:"",
    storagePrice:"", 
    storageListPrice:"",
    platformRegion:"",
    listPrice:""
  });

  useEffect(() => {   
    dispatch(regionsAndPlatforms())     
    if (selectedContract){
       setInput({
        from:selectedContract.from,
        to:selectedContract.to,
        storagePrice:selectedContract.storagePrice, 
        storageListPrice:selectedContract.storageListPrice,
        creditPrice:selectedContract.creditPrice,
        creditAmount:selectedContract.creditAmount,
        platform:selectedContract.platform,
        platformRegion:selectedContract.platformRegion,
        plan:selectedContract.plan,
        listPrice:selectedContract.listPrice

        });
    }else{}
  },[]);



 
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.id]: e.target.value,
    });
  };

  const validateAllInputs = () => {
    // Object.values(input) obtiene un array de los valores del objeto input.
    // El método .every() verifica si todos los valores del array cumplen la condición.
    if (
      Object.keys(input).every(
        (key) =>
          key === "region" || (input[key] !== null && input[key].trim() !== "")
      )
    ) {
     // Si todos los valores cumplen la condición, se devuelve true. 
      return true;
    } else {
      // Si al menos un valor no cumple la condición, se devuelve false.
      return false;
    }
  };
  
  const setNewDataContract = (e) => {
    e.preventDefault()
    if(validateAllInputs){
      dispatch(editContract({
        from:input.from,
        to:input.to,
        creditPrice:input.creditPrice.replace(',', '.'),
        creditAmount:input.creditAmount.replace(',', '.'),
        storagePrice:input.storagePrice.replace(',', '.'),
        storageListPrice:input.storageListPrice.replace(',', '.'),
        platform:input.platform,
        platformRegion:input.platformRegion,
        plan:input.plan,
        listPrice:input.listPrice.replace(',', '.'),
        region:idCurrentAccount.region,
        idAccount:idCurrentAccount.idAccount,
        creationUser:idCurrentAccount.creationUser,
        contractIdInDb:selectedContract.contractIdInDb
       }))
    }else{
      dispatch(openModal('SnowflakeIncompleteFields'))
    }
   
  };

const handleCloseForm = () => {
    dispatch(setStatusFormEdit(false))
}
const styleFormGroup = {color:'black',marginTop:'10px',border:'1px'}
const styleFormControl = {color:'black',borderColor: "#899AF1",backgroundColor:'#E8F0FE'}
  return (
    <>
   <Row>
    <Col>
  <Card>
    <Card.Header>
    <div className="header-title">
         <h4 className="card-title">Snowflake Edit Contract</h4>
         </div>
    </Card.Header>
     <Card.Body>
    <div className="new-contract-info">
    <form>
   
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h5>Contract information</h5>
        <Tooltip
          title="In this view you need to load the contract data with Snowflake to get the costs accurately"
          position="top"
          delay={500}
        >
          <InfoOutlinedIcon className="ms-2" />
        </Tooltip>
      </div>  
      <Form>
        <div className="d-flex">
          <div style={{ flex: 1, marginRight: '10px' }}>
            <Form.Group controlId="from" style={styleFormGroup}>
              <Form.Label>From</Form.Label>
              <Form.Control
              style={input.from ? styleFormControl: {borderColor: "#899AF1",backgroundColor:'#FFFFFF'}}
                type="date"
                placeholder="Enter created user"
                value={input.from}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
         
            <div className="d-flex">
  <div style={{ flex: 1, marginRight: '10px' }}>
     <Form.Group controlId="platform" style={styleFormGroup}>
            <Form.Label>Platform</Form.Label>
            <Form.Select
          name="platform"
          style={input.platform ? styleFormControl : { borderColor: "#899AF1",backgroundColor:'#FFFFFF' }}
          value={input.platform}
          onChange={handleChange}
        >
          <option disabled value="">Select a platform</option>
          {platforms.map((platform,idx) => (
            <option key={idx} value={platform}>
              {platform}
            </option>
          ))}
        </Form.Select>
            </Form.Group>
  </div>
  <div style={{ flex: 1, marginLeft: '10px' }}>
    
      
            <Form.Group controlId="platformRegion" style={styleFormGroup}>
            <Form.Label>Region</Form.Label>
            <Form.Select
          name="platform"
          style={input.platformRegion ? styleFormControl : { borderColor: "#899AF1",backgroundColor:'#FFFFFF' }}
          value={input.platformRegion}
          onChange={handleChange}
              >
          <option disabled value="">Select a region</option>
          {   platformsAndRegion.amazonWebServices && input.platform === 'Amazon Web Services(AWS)' ?
          
          platformsAndRegion.amazonWebServices.map((platform,idx) => (
            <option key={idx} value={platform}>
              {platform}
            </option>
          )): input.platform === 'Google Cloud Platform' ?   platformsAndRegion.googleCloudPlatform.map((platform,idx) => (
            <option key={idx} value={platform}>
              {platform}
            </option>
          )):  input.platform === 'Microsoft Azure' ?   platformsAndRegion.microsoftAzure.map((platform,idx) => (
            <option key={idx} value={platform}>
              {platform}
            </option>
          )): null
        }
        </Form.Select>
            </Form.Group>
  </div>
</div>
<Form.Group controlId="creditPrice" style={{ ...styleFormGroup, width: '100%' }}>
      <Form.Label>Credit Price</Form.Label>
      <Form.Control
        type="number"
        style={input.creditPrice ? styleFormControl: {borderColor: "#899AF1",backgroundColor:'#FFFFFF'}}
        placeholder="Example: $4 "
        value={input.creditPrice}
        onChange={(e) => handleChange(e)}
      />
    </Form.Group>     
        <Form.Group controlId="listPrice" style={styleFormGroup}>
              <Form.Label>Credit list price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Example: $8 "
                style={input.listPrice ? styleFormControl: {borderColor: "#899AF1",backgroundColor:'#FFFFFF'}}
                value={input.listPrice}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </div>  
          <div style={{ flex: 1, marginLeft: '10px' }}>
          <Form.Group controlId="to" style={styleFormGroup}>
              <Form.Label>To</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter tenant name"
                style={input.to ? styleFormControl: {borderColor: "#899AF1",backgroundColor:'#FFFFFF'}}
                value={input.to}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>



            <div className="d-flex">
  <div style={{ flex: 1, marginRight: '10px' }}>
  <Form.Group  controlId="plan" style={styleFormGroup}>
          <Form.Label>Plan</Form.Label>
          <Form.Select
            as="select"
            style={input.plan ? styleFormControl: {borderColor: "#899AF1",backgroundColor:'#FFFFFF'}}
            value={input.plan}
            onChange={(e) => handleChange(e)}
          >
            <option  value="" disabled>Select plan</option>
            <option style={{color:'black'}} value="Enterprise">Enterprise</option>
            <option style={{color:'black'}} value="Business Critical">Business Critical</option>
            <option style={{color:'black'}} value="Standard">Standard</option>

          </Form.Select>
        </Form.Group>
  </div>
  <div style={{ flex: 1, marginLeft: '10px' }}>
    
      
  <Form.Group controlId="creditAmount" style={styleFormGroup}>
              <Form.Label>Total credit purchased</Form.Label>
              <Form.Control
                type="number"                     
                placeholder="Example: 5000"
                style={input.creditAmount ? styleFormControl: {borderColor: "#899AF1",backgroundColor:'#FFFFFF'}}
                value={input.creditAmount}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
  </div>
</div>
<Form.Group controlId="storagePrice" style={{ ...styleFormGroup, width: '100%' }}>
      <Form.Label>Contract storage price</Form.Label>
      <Form.Control
        type="number"
        style={input.storagePrice ? styleFormControl: {borderColor: "#899AF1",backgroundColor:'#FFFFFF'}}
        placeholder="Example: $40"
        value={input.storagePrice}
        onChange={(e) => handleChange(e)}
      />
    </Form.Group>     
    <Form.Group controlId="storageListPrice" style={styleFormGroup}>
              <Form.Label>Storage price  list</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter storage list price"
                style={input.storageListPrice ? styleFormControl: {borderColor: "#899AF1",backgroundColor:'#FFFFFF'}}
                value={input.storageListPrice}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
      
          </div>
        </div>       
      </Form>
 
   <div style={{display: 'flex', justifyContent: 'flex-end', margin: '15px 10px 0 0' }} className="d-flex justify-content-end">
   
   
        <Button  onClick={(e)=> handleCloseForm()} type="button" style={{marginRight:'15px' }} >Back to contracts</Button>
 
 <Button  type="submit" variant="primary" onClick={(e) => setNewDataContract(e)}>
          Submit
        </Button>
   
   </div>
   </form>
      </div>
      </Card.Body>
      </Card>
    </Col>
   </Row>
  </>
  );
};

export default SnowflakeEditContract;