import { createSlice } from '@reduxjs/toolkit'




const initialState = {
    driverState:'ready',   //esta variable la utilizo para saber si estoy procesando una peticion o no
    drivers:{},     //este objeto lo utilizo para guardar los drivers que me devuelve el backend
    index:null,     //esta variable la utilizo para saber que driver estoy editando
    indexDimension:null, //esta variable la utilizo para saber que dimension estoy editando
    editDimesion:false, // es un booleano que me indica si estoy editando una dimension o no
    driverIdSelected:null,//esta variable la utilizo para cuando necesito ir a buscar las columnas de los dimensiones para distribuir 
    editDriver:false, //esta variable la utilizo para saber si estoy editando un driver o no
    accountIdToDistribution:null, //esta variable la utilizo para saber a que cuenta voy a distribuir el driver
    accountNameToDistribution:null, //esta variable la utilizo para saber a que cuenta voy a distribuir el driver
    indexDimensionSelected:0 //esta variable la utilizo para saber que dimension estoy distribuyendo
  }
  
  export const driversSlice = createSlice({
      name: 'drivers',
      initialState,
      reducers: {
        //actualizo el objeto drivers con los drivers que me devuelve el backend
        updateDriversWithDimension: (state,{payload})=>{

            state.drivers = payload.data.sort(function(a,b){
                if(a.idDriver > b.idDriver){
                    return 1;
                }
                if (b.idDriver > a.idDriver){
                    return -1;
                }
                return 0;
            }) 


        },
        //guardo la posicion del driver seleccionado pra ver en detalle las dimensiones
        setIndexDriverSelected: (state,{payload})=>{
            state.index = payload
        },
        //cambio el estado de la variable driverState a processing para saber que estoy procesando una peticion
        processingRequest: (state) => {
            state.driverState = 'processing';
        },
        //cambio el estado de la variable driverState a ready para saber que estoy listo para procesar una peticion
        readyRequest: (state) => {
            state.driverState = 'ready';
        },
        //guardo la posicion de la dimension seleccionada para editar
        setIndexDimensionSelected: (state,{payload})=>{
            state.indexDimension = payload
        },
        //cambio el estado de la variable editDimension a true para saber que estoy editando una dimension
        setTrueEditDimension: (state)=>{
            state.editDimesion = true
        },
        //cambio el estado de la variable editDimension a false para saber que no estoy editando una dimension
        setFalseEditDimension: (state)=>{
            state.editDimesion = false
        },
        //actualizo el id del driver seleccionado
        updateIdDriverSelected:(state,{payload})=>{
            state.driverIdSelected= payload
        },
        //actualizo el estado de la variable editDriver a true para saber que estoy editando un driver
        setEditDriver:(state,{payload})=>{
            state.editDriver = payload
        },
        //actualizo el id de la cuenta a la que voy a distribuir el driver
        updateAccountIdToDistribution:(state,{payload})=>{
            state.accountIdToDistribution = payload
        },
        //actualizo el indice de la dimension que estoy distribuyendo
        setIndexDimensionSelectedForDistribution:(state,{payload})=>{
            state.indexDimensionSelected = payload
        },

      },
  })
  
  // Action creators are generated for each case reducer function
  export const {setIndexDimensionSelectedForDistribution,updateAccountIdToDistribution,setEditDriver,updateIdDriverSelected, updateDriversWithDimension,setIndexDriverSelected,processingRequest, readyRequest,setIndexDimensionSelected,setFalseEditDimension,setTrueEditDimension} = driversSlice.actions