import { useDispatch } from "react-redux";
import {getNamesAccounts,getDataAccount} from "../../../helpers/forecast";
import { setAccountsToSelect,setDataInAccount,setStateForecast,setForecastForConsolidated } from "./forecastSlice";



export const getAccountsNames = (body) => {
    return async( dispatch ) => {
        dispatch(setStateForecast('processing'))
        const respJson = await getNamesAccounts({idTenant:body})
        dispatch(setAccountsToSelect(respJson.data))
        dispatch(setStateForecast('ready'))

    }
}

export const getAccountAndDatesById = (user,accountId,cloud,tenantId,accumulated) => {
    return async( dispatch ) => {
        dispatch(setStateForecast('processing'))
        const respJson = await getDataAccount({idTenant:tenantId,idAccount:accountId,user:user,cloud:cloud,accumulated:accumulated})
        dispatch(setDataInAccount(respJson))
        if(cloud === 'Consolidated'){
        dispatch(setForecastForConsolidated(respJson))
        dispatch(setStateForecast('ready'))

        }
        dispatch(setStateForecast('ready'))

    }
}
    