import { getAllTenants,setNewTenant,changeRole,getJsonConfigs,getAllSuperAdmins,createNewTenantInDb } from "../../../helpers/roles";
import { getusers } from "../users/usersThunks";
import { allTenants,configJsonRol,processingRequest,readyRequest,allSuperAdmins } from "./rolesSlices";
import { handleRolMessages } from "../users/usersSlices";


//Funciones de superadmin
export const allTenantsInDb = () => {
    return async (dispatch) => {
        dispatch(processingRequest());
        const respJson = await getAllTenants();
        if (respJson.status === 200) {
            await dispatch(allTenants(respJson.data));          
            dispatch(readyRequest())  
        } else {
        // No debe hacer nada
        }
    };
}
export const getAllSuperAdm = () => {
    return async (dispatch) => {
        dispatch(processingRequest())
        const respJson = await getAllSuperAdmins();
        if (respJson.status === 200) {
            await dispatch(allSuperAdmins(respJson.data));
            dispatch(readyRequest())
            } else {
             // No debe hacer nada
        }
    }
}

    export const changeTenant = (tenant,emailAdmin) => {
        return async (dispatch) => {
            dispatch(processingRequest());
            const respJson = await setNewTenant({tenantId:tenant,emailAdmin:emailAdmin});
            if (respJson.status === 200) {
                await dispatch(allTenantsInDb());          
                dispatch(readyRequest())  
                window.location.reload();    
            } else {
            // No debe hacer nada
            }
        }
    }


export const createTenant = (tenantName,countryTenant,creationUser ) => {
    return async (dispatch) => {
        dispatch(processingRequest());
        const respJson = await createNewTenantInDb({tenantName:tenantName,country:countryTenant,creationUser:creationUser});
        if (respJson.status === 200) {
            await dispatch(allTenantsInDb());
            dispatch(readyRequest())
            } else {
                // No debe hacer nada
        }
    }
}
//------------------------------------------------------------------------//
//Funcion que obtiene el json con las configuraciones de los permisos/roles
    export const getJsonConfigsInDb = () => {
        return async (dispatch) => {
            dispatch(processingRequest());
            const respJson = await getJsonConfigs();
            if (respJson.status === 200) {
                await dispatch(configJsonRol(respJson.data));
                dispatch(readyRequest())
            } else {
            // No debe hacer nada
            }
        };
    }

    //Funcion para cambio de roles en el usuario seleccionado 
    export const handleRoleUser = (userEmail,role,idTenant) => {
        return async (dispatch) => {
            dispatch(processingRequest());
            const respJson = await changeRole({roleAssign:role,emailUser:userEmail});
            if (respJson.status === 200) {
                await dispatch(getusers({tenantId:idTenant}));          
                dispatch(handleRolMessages(respJson.message))
                dispatch(readyRequest())  
            } else {
            // No debe hacer nada
            }
        }
    }
 


