import { fabClasses } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    usersState:'',   //esta variable la utilizo para saber si estoy procesando una peticion o se completo
    errorUserMessage:{},   //Esta variable  muestra mensajes de error y de procesos exitosos 
    userStatusprocess: 'ready',//Si este estado se actualiza, la lista de users actualiza y muestra los datos actuales
    statusUserMessage:'',//MEnsaje  del toast cuando el usuario cambia a true o false
    auth0Messages:'',//Mensaje de reseteo de contraseña
    rolMessages:'', //Mensaje de cambio de rol
    editForm: false,
    users:[],     //este objeto lo utilizo para guardar los users que me devuelve el backend
    userModalData:{}//este objeto lo utilizo para guardar los datos del user que se va a editar
  }

  export const usersSlice = createSlice({
  name: 'users',
      initialState,
      reducers: {

    allUsers: (state,{payload})=>{
        state.users = payload
    },
    errorMessage: (state, { payload }) => {
      state.errorUserMessage = payload;
    },
     //cambio el estado de la variable driverState a processing para saber que estoy procesando una peticion
    processingRequest: (state) => {
      state.usersState = 'processing';
  },
    //cambio el estado de la variable driverState a ready para saber que estoy listo para procesar una peticion
    readyRequest: (state) => {
    state.usersState = 'ready';
  }, //cambio el estado de la variable driverState a failed para saber que fallo una peticion
    failedRequest: (state) => {
    state.usersState = 'failed';
  },//cambio el estado de la variable para mostrar la lista de usuarios en UserList.jsx 
  listState: (state) => {
    state.usersState = 'ready list';
  },//Cambia el estado del userStatus a activo o inactivo y se utiliza para recargar el userlist con los valores actuales
  updateStatusUser: (state,{payload}) => {
    state.userStatusprocess = payload;
  },//Funcion para mostrar el toast cuando el usuario cambia a true o false
  userStatusToast:(state,{payload})=> {
    state.statusUserMessage = payload;
  },//Funcion para activar el formulario de edicion de usuario en userList
  editFormActive: (state,{payload}) => {
    state.editForm = payload;
  },//Funcion para mostrar el mensaje de reseteo de contraseña desde auth0 
  handleAuth0Messages: (state,{payload}) => {
   state.auth0Messages = payload;
  },handleRolMessages: (state,{payload}) => {
    state.rolMessages = payload;
  },
  userModelData:(state,{payload}) => {
    state.userModalData = payload;
  }

  }})


  export const {allUsers,processingRequest,readyRequest,failedRequest,listState,errorMessage,updateStatusUser,editFormActive,userStatusToast,handleAuth0Messages,userModelData,handleRolMessages} = usersSlice.actions;