import {React,useEffect} from 'react'
import {Switch,Route} from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";

//TransitionGroup
import {TransitionGroup,CSSTransition} from "react-transition-group";

import Accounts from '../layouts/dashboard/Accounts';
import DashbordCosting from '../layouts/dashboard/DashbordCosting';
import DistrubitionTable from '../layouts/dashboard/DistributionTable';
import UserList from '../layouts/dashboard/UserList';
import UserAdd from '../layouts/dashboard/UserAdd';
import Admin from '../layouts/dashboard/Admin';
import Home from '../layouts/dashboard/Home';
import Loader from '../components/Loader'
import Notifications from '../layouts/dashboard/Alerts';
import DriversForm from '../components/cco/forms/DriverForms';
import Drivers from '../layouts/drivers/Drivers';
import ListDimensionsByDriver from '../layouts/drivers/ListDimensionByDriver';
import DimensionForm from '../components/cco/forms/DimensionForm';
import AccountForm from '../components/cco/forms/AccountForm';
import NewNotificationForm from '../components/cco/forms/notifications/NewNotificationForm';
import NotificationList from '../layouts/dashboard/NotificationList';
import UserProfile from '../layouts/dashboard/Profile';
import { useAuth0 } from '@auth0/auth0-react'
import { getUsageFromThisMonthAndLast,setTenant } from '../store/slices/costing/costingThunks'
import { getDriversToComponent } from '../store/slices/drivers/driverThunks';
import { getListNotifications } from '../store/slices/notifications/notificationThunks';
import { getDataForConsolidatedCard } from '../store/slices/accounts/accountThunks';
import ContractsViews from '../layouts/dashboard/ContractsViews';
import SnowflakeContract from '../components/cco/forms/SnowflakeContract';
import Forecast from '../layouts/forecast/Forecast';
import Advisor from '../layouts/advisor/Advisor'
import Help from '../layouts/help/Help';
import Reports from '../layouts/reports/Reports';
const CostingRouter = () => {
    const user = useAuth0();

    const { idTenant,initialStatus } = useSelector(
        (state) => state.costing
      );

    
    const dispatch = useDispatch();
    

    useEffect(() => {   
        dispatch(setTenant({"user": user.user.email}))
        
        }, [])



    useEffect(() => {
        if(idTenant !== null){
            dispatch(getDriversToComponent({tenantid:idTenant}))
            dispatch(getListNotifications({tenantid: idTenant}))
        }
      }, [idTenant])
    
    

    
    const body = {
          tenantId: idTenant,
          user: user.user.email
    };
    

      useEffect(() => {
        if(idTenant !== null){
           
            dispatch(getUsageFromThisMonthAndLast(body));
            dispatch(getDataForConsolidatedCard({idTenant }));
           
        }
    }, [idTenant])
    

   
  
    
    
    if(initialStatus === 'procesing'){
        return <Loader/>
    }


    return (
        <TransitionGroup>
            <CSSTransition classNames="fadein" timeout={300}>
                
                <Switch>

                    {/* Desde default router lo que haces es ver en que ruta esta parado y de ahi renderiza el body del DOM */}


                    {/* agrego rutas de cloud costing */}

                    <Route path="/" exact component={Home} />

                    <Route path="/accounts" exact component={Accounts} />
                    <Route path="/contracts" exact component={ContractsViews} />	
                    <Route path='/addcontract' exact component={SnowflakeContract}/>
                    <Route path="/newaccounts" exact component={AccountForm} />


                    <Route path="/dashboard" exact component={DashbordCosting} />
                     <Route path="/forecast" exact component={Forecast} /> 
                     <Route path="/advisor" exact component={Advisor} />
                    <Route path="/distribution" exact component={DistrubitionTable} />

                    <Route path="/useradd" exact component={UserAdd} />
                    <Route path="/reports" exact component={Reports} />
                    <Route path="/userlist" exact component={UserList} />
                    <Route path="/profile" exact component={UserProfile} />
                    <Route path="/admin" exact component={Admin} />
                    <Route path="/notifications" exact component={Notifications} />
                    <Route path="/help" exact component={Help} />
                    <Route path="/drivers" exact component={Drivers} />

                    <Route path="/newdriver" exact component={DriversForm} />

                    <Route path="/listdimesionbydrivers" exact component={ListDimensionsByDriver} />

                    <Route path="/newmember" exact component={DimensionForm} />

                    <Route path="/newnotification" exact component={NewNotificationForm} />

                    <Route path="/notificationsettings" exact component={NotificationList} />

                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default CostingRouter
