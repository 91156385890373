import { React, useEffect, useState, useCallback } from "react";
import { Modal, Button,ButtonGroup,ToggleButton } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../store/slices/modal/modalSlice";
import { setEquallyDistributionTrue,setAssocitedDistributionTrue } from "../../../store/slices/costing/costingSlice";
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

////////////////////////////////////////////////////////////////////////////////////////////////
//NO ESTA TERMINADO ESTE MODAL

const DistributionType = () => {
  const dispatch = useDispatch();
  const { openModal, modalSelected } = useSelector((store) => store.modal);
  const { equallyDistribution } = useSelector((store) => store.costing);

  const [radioValue, setRadioValue] = useState(0);




  const handleRadioChange = (value) => {
    setRadioValue(value);
    if (value === 1) {
      dispatch(setEquallyDistributionTrue());
    }
    if (value === 2) {
      dispatch(setAssocitedDistributionTrue());
    }
  };


  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Distribution Types</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <ButtonGroup>
            
        <ToggleButton
          type="radio"
          variant="outline-secondary"
          name="radio"
          value={1}
          onClick={(e) => handleRadioChange(1)}
          checked={radioValue === 1}
          style={{ width: "150px", display: "flex", alignItems: "center" }} // Establece el ancho fijo y ajusta el diseño
        >
          <Tooltip
            title="This type of distribution will ensure that the cost is shared equally among all members of the driver."
            position="top"
            delay={500}
          >
            <InfoOutlinedIcon style={{ fontSize: "16px", marginRight: "8px" }} /> 
          </Tooltip>
          Equally
        </ToggleButton>

          

        <ToggleButton
          type="radio"
          variant={"outline-secondary"}
          name="radio"
          value={1}
          onClick={(e) => handleRadioChange(2)}
          checked={radioValue === 2}
          style={{
            width: "150px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse", // Cambia la dirección de flexbox a "row-reverse" para colocar el icono a la izquierda
          }}
        >
          <Tooltip
            title="This type of distribution will assign each member a percentage of the cost based on their configured weight."
            position="top"
            delay={500}
          >
            <InfoOutlinedIcon  style={{ fontSize: "16px", marginLeft: "8px" }} /> 
          </Tooltip>
          Associated
        </ToggleButton>


        </ButtonGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DistributionType;
