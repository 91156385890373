import { checkingCredentials,handleEndDateMessage,handleEndDateStatus } from "./authSlice";
import {getEndDateFromBack,} from "../../../helpers/auth";

export const checkingAuthentication = () => {
    return async( dispatch ) => {
        dispatch( checkingCredentials() )
    }
}


export const getEndDateTrialPlan = ( idTenant ) => {
    return async( dispatch ) => {
        dispatch(handleEndDateStatus("loading"))
        const resp = await getEndDateFromBack({ tenantId: idTenant});
        dispatch( handleEndDateMessage( resp ) )
        dispatch(handleEndDateStatus("ready"))
    }
}
