import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    notificationState:'ready',   //esta variable la utilizo para saber si estoy procesando una peticion o no
    notifications:[],     //este objeto lo utilizo para guardar las notificaciones que me devuelve el backend
    listOfNotifications: [], //la difrencia con el de arriab es que el anterior es el historial de notificaciones disparadas por los consumos, en cambio esta es son el listado de las que creeo el usurio
    isBeingEdited : false,//esta boleando lo tengo para saber si esta editando alguna notificacion
    notificationToModify : null,
    idNotification:null, //esto lo guardo para cuanod elimino o edito una notificacion en particular
    detailNotification:{}, //este objeto lo utilizo para guardar el detalle de una notificacion en particular, en este aso lo estoy utilizando en el componente Speedometer
    actualValueAmount:0, //este valor lo utilizo para guardar el valor actual de la notificacion que estoy editando
    //notification per service 
    accountServicesLists:[]
}
  
  export const notificationsSlice = createSlice({
      name: 'notification',
      initialState,
      reducers: {
        processingRequest: (state) => {
            state.notificationState = 'processing';
        },
        //cambio el estado de la variable driverState a ready para saber que estoy listo para procesar una peticion
        readyRequest: (state) => {
            state.notificationState = 'ready';
        },
        setNotifications: (state,{payload}) => {
           
            state.notifications = payload.sort(function(a,b){
                if(a.idNotification > b.idNotification){
                    return 1;
                }
                if (b.idNotification > a.idNotification){
                    return -1;
                }
                return 0;
            }) 




        },
        setListOfNotifications:(state,{payload})=>{
            state.listOfNotifications=payload
        },
        setIsBeginEditedTrue:(state) => {
            state.isBeingEdited = true
        },
        setNotificationToModify: (state,{payload})=>{
            state.notificationToModify = payload
        },
        resetEditState: (state)=>{
            state.notificationToModify = null
            state.isBeingEdited = false
        },
        setIdNotification: (state,{payload})=>{
            state.idNotification = payload
        },
        setDetailNotification: (state,{payload})=>{
            state.actualValueAmount = payload.amount?payload.amount:0
            state.detailNotification = payload
        },
        setListsOfServices: (state,{payload})=>{
            state.accountServicesLists = payload
        }
 
      },
  })
  
  // Action creators are generated for each case reducer function
  export const {setDetailNotification, setIdNotification,resetEditState,setNotificationToModify,setIsBeginEditedTrue,setListOfNotifications,processingRequest,readyRequest,setNotifications,setListsOfServices} = notificationsSlice.actions