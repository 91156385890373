import { React, useEffect, useState, useCallback } from "react";
import { Row, Col, Button, Dropdown } from "react-bootstrap";

import "../../styles/distributionTable.css";

import { useSelector, useDispatch } from "react-redux";

import { bindActionCreators } from "redux";


import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-enterprise/index.css";
import "@inovua/reactdatagrid-community/base.css";
import NumericEditor from "@inovua/reactdatagrid-community/NumericEditor";
import "@inovua/reactdatagrid-enterprise/theme/green-light.css";



import {
  getDataforDistribution,
  setDataforDistribution,
} from "../../store/slices/costing/costingThunks.js";

import { updateDataToSave,setFlagToRenderFalse,setEquallyDistributionFalse,setAssocitedDistributionTrue,setAssocitedDistributionFalse} from "../../store/slices/costing/costingSlice.js";

import { openModal } from "../../store/slices/modal/modalSlice";

import { PiMicrosoftExcelLogoBold } from 'react-icons/pi';

import ToastCco from "../../components/cco/toasts/ToastCco";
import toast, { Toaster } from 'react-hot-toast';
import LastUpdateAlert from "../../components/cco/alerts/LastUpdateAlert";
import SelectDriverForDistribution from "../../components/cco/dropdowns/SelectDriverForDistribution";
import { setAccountSelectedForDistribution } from "../../store/slices/accounts/accountSlice"; 


// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../store/setting/setting";
import { connect } from "react-redux";
import { data } from "jquery";

const moment = require("moment");

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});


const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  return year + "-" + month;
};


const downloadBlob = (blob, fileName = 'cloud-costing.csv') => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

const SEPARATOR = ',';

const shouldComponentUpdate = () => true;







const DistributionTable = () => {


  const [height, setHeight] = useState(0.9 * window.innerHeight); // Inicialmente, 80% del alto de la ventana

useEffect(() => {
  const handleResize = () => {
    const windowWidth = window.innerWidth;
    const headerHeight = document.querySelector(".card-header").offsetHeight;
    const minHeight = 300; // Establecemos una altura mínima para evitar que la grilla sea demasiado pequeña
    const maxHeight = 0.9 * window.innerHeight; // Limitamos el tamaño máximo al 90% del alto de la ventana
    const availableHeight = window.innerHeight - headerHeight;
    const newHeight = 0.8 * availableHeight; // Podemos ajustar el porcentaje si es necesario

    setHeight(Math.min(maxHeight, Math.max(minHeight, newHeight)));
  };

  handleResize();
  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

  

  const dispatch = useDispatch();

  const { cardUsageAccount, dataDistribution, dataDashbord, flagToResetData,idTenant,flagToRenderAfeterSave,equallyDistribution,associtedDistribution } =
    useSelector((state) => state.costing);


  const {driverIdSelected,drivers } = useSelector((state) => state.drivers);

  const {accountSelectedForDistributionTable } = useSelector((state) => state.accounts);


  // const [seleccionado, setSeleccionado] = useState(
  //   cardUsageAccount[0]?.accountName || ""
  // );

  const [accountId, setAccountId] = useState(Array.isArray(cardUsageAccount) ? cardUsageAccount[0]?.accountId : 0);
 
  const [seleccionado, setSeleccionado] = useState(Array.isArray(cardUsageAccount) ? cardUsageAccount[0].accountName : "")




  //con esto me guardo en este estado el id de la cuenta la cual esta seleecionado en el dropdown
  // const [accountId, setAccountId] = useState(
  //   cardUsageAccount[0]?.accountId || 0
  // );

  let formattedDate = moment(dataDashbord.lastUpdate).format(
    "MMM DD YYYY HH:mm z"
  );

  const [months, setMonths] = useState([]);

  const [dataSourceAbsolute, setdataSourceAbsolute] = useState(0);

  const [dataSourcePercentaje, setdataSourcePercentaje] = useState(0);

  //agregar al state inicial , la fecha actual
  const [monthToDb, setmonthToDb] = useState(getTodayDate());

  const [isPercentView, setIsPercentView] = useState(false);

  const [indexTotalRow, setIndexTotalRow] = useState(0);

  const [changedData, setchangedData] = useState(false);
  
  const [showAlert, setShowAlert] = useState(false);

  const [enableFiltering, setEnableFiltering] = useState(false);

  const [gridRef, setGridRef] = useState(null);

  const [recentlyEditedRowId, setRecentlyEditedRowId] = useState(null);


  useEffect(() => {
    //estas son validaciones para que no se rompa la grilla en caso de que no haya datos
    if(Array.isArray(cardUsageAccount) ){
        getLast13Months();
        //con esto guardo en la memoria cual es el id de la cuenta a la que se esta editando la grilla
        dispatch(setAccountSelectedForDistribution(cardUsageAccount[0]))
    }

  }, []);


  
  useEffect(() => {
    if(accountSelectedForDistributionTable.accountId === null){
      seleccionarOpcion(
        cardUsageAccount[0].accountName,
        cardUsageAccount[0].accountId
      );
      setRecentlyEditedRowId(null)
      buldingBoard();
    }else{
      seleccionarOpcion(
        accountSelectedForDistributionTable.accountName,
        accountSelectedForDistributionTable.accountId
      );
      setRecentlyEditedRowId(null)
      buldingBoard();
    }
   
  }, [driverIdSelected]);


  let columnsData =[]
  columnsData =  dataDistribution.columns;



  let columns = [];

  if (columnsData !== undefined) {

    columns = [
      {
        name: "service",
        header: "Service",
        minWidth: 50,
        defaultFlex: 2,
        editable: false,
        style: { fontWeight: "bold" },
        headerAlign: "center",
      },
      {
        name: "cost",
        render: ({ value }) =>
          Number(value === null || value === undefined ? 0 : value).toFixed(2),
        format: "number",
        header: "Cost  ($)",
        headerAlign: "center",
        style: { fontWeight: "bold" },
        maxWidth: 1000,
        defaultFlex: 1,
        editor: NumericEditor,
        type: "number",
        textAlign: "end",
        editable: false,
      },

      //arranca a iterar para crear las columnas que estan grabadas en la bd

      ...columnsData.map((column) => ({
        name: column,
        header: isPercentView ? column + "    (%)" : column + "     ($)",
        maxWidth: 1000,
        defaultFlex: 1,
        type: "number",
        editor: NumericEditor,
        editable: true,
        headerAlign: "center",
        textAlign: "end",
        render: ({ value }) =>
          Number(value === null || value === undefined ? 0 : value).toFixed(2),          
      })),
    

      {
        name: "total",
        header: isPercentView ? "Total   (%)" : "Total  ($)",
        maxWidth: 1000,
        defaultFlex: 1,
        headerAlign: "center",
        type: "number",
        textAlign: "end",
        style: { fontWeight: "bold" },
        editable: false,
        render: ({ value, data }) => {
          const formattedValue = Number(
            value === null || value === undefined ? 0 : value
          ).toFixed(2);
          const color = isPercentView
          ? value > 100 ? 'red' : value === 100 ? 'green' : 'inherit'
          : data.cost < value ? 'red' : data.cost === value ? 'green' : 'inherit';
                  return (
            <span style={{ color }}>
              {formattedValue}
            </span>
          );
        }

      },
    ]
  }
  

  // esta son los filtros que se aplican a la grilla 
  const filterValue = [
    {name: 'service', operator: 'startsWith', type: 'string', value: ''},
    {name: "cost", operator: 'gt', type: 'number', value: 0 }
  ]
  




  const seleccionarOpcion = (opcion, accountId) => {
    setSeleccionado(opcion);
    setAccountId(accountId);
    dispatch(setAccountSelectedForDistribution({accountId:accountId,accountName:opcion}))
    
    const body = {
      tenantId: idTenant,
      accountId: accountId,
      month: monthToDb + "-01",
      iddriver: driverIdSelected
    };
    dispatch(getDataforDistribution(body));
    setRecentlyEditedRowId(null)

  };


  useEffect(() => {
    dispatch(setAssocitedDistributionFalse())
    dispatch(setEquallyDistributionFalse())
  },[])

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //ACA HAGO LAS 2 TIPO DE DISTRIBUCIONES 

  //aca lo que hago es tomar el cost de cada row, y lo voy a dividir en la cantidad de columnas que hay, y eso lo voy a poner en cada una de las columnas
  //osea que si tengo 3 columnas, voy a dividir el cost en 3 y eso lo voy a poner en cada una de las columnas
  useEffect(() => {
    if(associtedDistribution){

      let dimensionByOneDriver = drivers.filter((driver) => driver.idDriver === driverIdSelected);
      let dimensions = dimensionByOneDriver[0].dimensionName
      let weights = dimensionByOneDriver[0].weight
    
      let dimensionWeight = {};
        dimensions.forEach((dimension, index) => {
          dimensionWeight[dimension] = weights[index];
      });
     
      
      
      const data = dataSourceAbsolute.map((row) => ({ ...row }));
      const dataPer = dataSourcePercentaje.map((row) => ({ ...row }));
      data.forEach((row) => {
        if(row.cost >0 ){
          let total=0;

         columnsData.forEach((dimension) => {
          if(dimensionWeight[dimension] > 0) {
           
            let result = (dimensionWeight[dimension]/100) * row.cost;
            row[dimension] = result.toFixed(2);
          }
          else{
            row[dimension] = 0
          }
          total += parseFloat(row[dimension])
          row.total = total.toFixed(2) 
         })

        
        }
      })

      setdataSourceAbsolute(data)

      //aca voy a hacer lo mismo pero para el set de datos de porcentajes, osea que distribuyo el 100% en las columnas que hay
      dataPer.forEach((row) => {
        if(row.cost >0 ){
          
          let total=0;
  
          
          columnsData.forEach((dimension) => {
            row[dimension] = dimensionWeight[dimension]

            total += parseFloat(row[dimension])
            row.total = total.toFixed(2) 
           })

          
        }
      })
      setdataSourcePercentaje(dataPer)

 
    
    }
    dispatch(setAssocitedDistributionFalse())
  }, [associtedDistribution])



  

  //aca lo que hago es tomar el cost de cada row, y lo voy a dividir en la cantidad de columnas que hay, y eso lo voy a poner en cada una de las columnas
  //osea que si tengo 3 columnas, voy a dividir el cost en 3 y eso lo voy a poner en cada una de las columnas
  useEffect(() => {
    if(equallyDistribution){
      const data = dataSourceAbsolute.map((row) => ({ ...row }));
      const dataPer = dataSourcePercentaje.map((row) => ({ ...row }));

      data.forEach((row) => {
        if(row.cost >0 ){
          
          let cost = row.cost;
          let columns = dataDistribution.columns.length;
          let costPerColumn = cost / columns;
          let costPerColumnFixed = parseFloat(costPerColumn.toFixed(2));
          //aca necesito asignar ese valor a las colunas que estan definididas en columnsData
          columnsData.map((key) => {
            row[key] = costPerColumnFixed;
          });
          row.total=row.cost
        }
      })

      setdataSourceAbsolute(data)

      //aca voy a hacer lo mismo pero para el set de datos de porcentajes, osea que distribuyo el 100% en las columnas que hay
      dataPer.forEach((row) => {
        if(row.cost >0 ){
          
          let cost = row.cost;
          let columns = dataDistribution.columns.length;
          let costPerColumn = 100 / columns;
          let costPerColumnFixed = parseFloat(costPerColumn.toFixed(2));
          //aca necesito asignar ese valor a las colunas que estan definididas en columnsData
          columnsData.map((key) => {
            row[key] = costPerColumnFixed;
          });
          row.total=100
        }
      })
      setdataSourcePercentaje(dataPer)

 
    
    }
    dispatch(setEquallyDistributionFalse())
  }, [equallyDistribution])


  
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const dataGroupedFunc = () => {
  if (dataDistribution.data !== undefined) {
    return dataDistribution.data.reduce((acc, item) => {
      if (!acc[item.service]) {
        acc[item.service] = {
          service: item.service,
          cost: item.cost,
          id: item.idHiredService,
          percentaje: item.percentaje,
        };
        dataDistribution.columns.forEach((column) => {
          acc[item.service][column] = 0;
        });
      }
      acc[item.service][item.dimensionDescription] = item.resultAmount;

      return acc;
    }, {});
  }
  return {}; // Retornar un objeto vacío si no hay datos
};




const dataRowsFunc = (dataGrouped) => {
  let index = 0; // Declarar el índice fuera del map para mantener su valor
  return Object.keys(dataGrouped).map((key) => {
    const serviceData = dataGrouped[key];
    let total = 0;
    dataDistribution.columns.forEach((column) => {
      total += serviceData[column];
    });
    let dataRow = {
      id: index, // Usar el índice para asignar el valor de id
      idHiredService: serviceData.id,
      service: serviceData.service,
      cost: serviceData.cost,
      percentaje: serviceData.percentaje,
      total: total,
    };
    index += 1; // Incrementar el índice para la próxima iteración
    dataDistribution.columns.forEach((column) => {
      dataRow[column] = serviceData[column];
    });

    return dataRow;
  });
};



const processDataItem = (acc, item) => {
  if (!acc[item.service]) {
    acc[item.service] = {
      service: item.service,
      cost: item.cost,
      id: item.idHiredService,
      percentaje: item.percentaje,
    };
    dataDistribution.columns.forEach((column) => {
      acc[item.service][column] = 0;
    });
  }
  acc[item.service][item.dimensionDescription] = item.percentaje;

  return acc;
};


const processDataRowsPercentaje = (() => {
  let index = 0; // Declarar index en el ámbito de la función
  return (key, serviceDataPercentaje) => {
    let total = 0;
    dataDistribution.columns.forEach((column) => {
      total += serviceDataPercentaje[column];
    });
    let dataRowPercentaje = {
      id: index,
      idHiredService: serviceDataPercentaje.id,
      service: serviceDataPercentaje.service,
      cost: serviceDataPercentaje.cost,
      percentaje: serviceDataPercentaje.percentaje,
      total: total,
    };
    index += 1;
    dataDistribution.columns.forEach((column) => {
      dataRowPercentaje[column] = serviceDataPercentaje[column];
    });

    setIndexTotalRow(index);
    return dataRowPercentaje;
  };
})();



//esta funcion es para calcular la row total que se muestra al final de la grilla
const calculateTotalRow = (dataRows) => {
  return dataDistribution.columns.reduce((acc, column) => {
    let total = 0;
    dataRows.forEach((row) => {
      total += row[column];
    });
    acc[column] = total;
    acc.cost = dataRows.reduce((total, row) => total + row.cost, 0); // Sumar la columna 'cost'
    acc.total = dataRows.reduce((total, row) => total + row.total, 0); // Sumar la columna 'total'
    return acc;
  }, { id: "total", service: "Total", cost: "", percentaje: "", total: "" });
};



  /////////////////////////////////////////////////////////////////////////////////////////////////

//esta funcion es para que cuando se cambie de cuenta o de mes se vuelva a renderizar la grilla
  const buldingBoard = () => {
    if (dataDistribution.data !== undefined && dataDistribution.data.length > 0) {

      const dataGrouped =  dataGroupedFunc()

      let index = 0;

      const dataRows = dataRowsFunc(dataGrouped)

      const dataGroupedPercentaje = dataDistribution.data.reduce(processDataItem,{});
      
      // Armamos dos tipos de datos para poder renderizar, uno que tiene los datos con los valores en consumos y el otro con los porcentajes
      index = 0;


      const dataRowsPercentaje = Object.keys(dataGroupedPercentaje).map(
        (key) => processDataRowsPercentaje(key, dataGroupedPercentaje[key])
      );

      const totalRow = calculateTotalRow(dataRows);

      dataRows.push(totalRow);

      setdataSourceAbsolute(dataRows);
      setdataSourcePercentaje(dataRowsPercentaje);
    }
  };





//estoy lo haciendo porque despues de guardar , en un momento dataDistribution queda vacio y entonces no pasa las validaciones para que no rompa
//entonces no vuelve a renderizas pos guardado, hice esto para el vivo que se tenia que grabar para que eso no suceda, habria que verlo mas a fondo
  useEffect(() => {
   if(flagToRenderAfeterSave){
     dispatch(setFlagToRenderFalse())
     seleccionarOpcion(accountSelectedForDistributionTable.accountName,
      accountSelectedForDistributionTable.accountId);
      buldingBoard();
   }
  }, [flagToRenderAfeterSave])
  


  // useEffect(() => {
  //   seleccionarOpcion(accountSelectedForDistributionTable.accountName,
  //     accountSelectedForDistributionTable.accountId);
  //   buldingBoard();
  // }, [monthToDb]);

  useEffect(() => {
    buldingBoard();
  }, [ flagToResetData,dataDistribution]);



  const onEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      //lo que hago aca ews resolver si la vista es porcentual o abosulta y en base a eso modifico ambos set de datos ,
      //pero a la vez reconozco el tipo de dato que se esta ingresando ya sea si es porcentual o absoluto
      if(value === null){
        value = 0
      }
      console.log('esta entrando onEditComplete?')

      setRecentlyEditedRowId(rowId); // Guardar el ID de la fila editada

      /////////////////////////////////////////////////////////////////////////////////
      //Ver como hacer para que la suma de total tambien se actualize de ambos lados tanto de lo porcentual como de lo absoluto
      if (!isPercentView) {
        const data = JSON.parse(JSON.stringify(dataSourceAbsolute))
        const dataPer = JSON.parse(JSON.stringify(dataSourcePercentaje))

        //tomo aca este valor para restalo despues
        let valueBeforeChange = data[rowId][columnId];

       
        data[rowId][columnId] = value;
        setdataSourceAbsolute(data);
        data[rowId]["total"] = 0;

        columnsData.map((key) => {
          data[rowId]["total"] += parseFloat(data[rowId][key]);
        });

        //aca es donde hace los calculos para actulizar la row de total en la columna donde se modifico
        let valorPrevio = data[indexTotalRow][columnId] - valueBeforeChange;
        data[indexTotalRow][columnId] = 0;
        data[indexTotalRow][columnId] = value + valorPrevio;

        //hago los calculos para tambien actualizar en la row total , y en la columa total
        data[indexTotalRow]["total"] += value;
        // para que sume tanto en el lado de porcentaje como en el de absoluto
        let percentajeCell = (value * 100) / data[rowId]["cost"];
        percentajeCell = percentajeCell.toFixed(2);
        dataPer[rowId][columnId] = percentajeCell;

        setdataSourceAbsolute(data);
        dataPer[rowId]["total"]=0
        columnsData.map((key) => {
          dataPer[rowId]["total"] += parseFloat(
            dataPer[rowId][key]
          );
        });

        setdataSourcePercentaje(dataPer)
      } else {
        // const dataPercentaje = [...dataSourcePercentaje];
        const dataPercentaje = JSON.parse(JSON.stringify(dataSourcePercentaje))
        const dataAbs = JSON.parse(JSON.stringify(dataSourceAbsolute))
        dataPercentaje[rowId][columnId] = parseFloat(value);
        setdataSourcePercentaje(dataPercentaje);
        dataPercentaje[rowId]["total"] = 0;

        columnsData.map((key) => {
            dataPercentaje[rowId]["total"] += parseFloat(dataPercentaje[rowId][key]);          
        });


        let absoluteCell = dataPercentaje[rowId]["cost"] * (value / 100);
        absoluteCell = absoluteCell.toFixed(2);

        dataAbs[rowId][columnId] = absoluteCell;
        dataAbs[rowId]["total"] = 0;

        columnsData.map((key) => {
        
          dataAbs[rowId]["total"] += parseFloat(dataAbs[rowId][key]);
        });


        setdataSourcePercentaje(dataPercentaje);
        setdataSourceAbsolute(dataAbs)
      }
      
      
    },
    [dataSourceAbsolute, dataSourcePercentaje,isPercentView]
  );



    useEffect(() => {
      prepareDataToSave()
    }, [dataSourceAbsolute, dataSourcePercentaje])
    


    //se hizo esta funcion para que se guarde en los slices la data para que el boton que esta en el componente sub-header lo tenga disponible para guardar
  const prepareDataToSave = () => {

    let bodyToUpdateToSave = {
      percentage: dataSourcePercentaje,
      absolute: dataSourceAbsolute,
      accountId: accountSelectedForDistributionTable.accountId,
      month: monthToDb + "-01",
    };
    dispatch(updateDataToSave(bodyToUpdateToSave));
  };

  

  function getLast13Months() {
    const today = new Date();
    const months = [];
    for (let i = 0; i < 13; i++) {
      const month = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const year = month.getFullYear();
      const monthNumber = month.getMonth() + 1;
      const monthString =
        monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
      const dateString = `${year}-${monthString}`;
      months.push(dateString);
    }
    setMonths(months);
    setmonthToDb(months[0]);
  }

  const changeDate = (month) => {
    setmonthToDb(month);
    seleccionarOpcion(accountSelectedForDistributionTable.accountName,
      accountSelectedForDistributionTable.accountId);
    buldingBoard();
  };
  
  //==================================================================================================================================



  const handleSwitchChange = (event) => {
   
    setIsPercentView(event.target.checked);
  };

  const handleToggleChange = (event) => {
   
    setEnableFiltering(event.target.checked);
  };


  const changeStatus = ()=>{
    setchangedData(false)
    setShowAlert(false)
  }





  //levanta el ALerrt de que tiene cambios sin guardar 
  const alertChange = ()=>{
    changedData && (setShowAlert(true))

  }
  
  const openModalToDelete = ()=>{
    dispatch(openModal("DistributionType"))
  }






  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;

    const header = columns.map((c) => c.name).join(SEPARATOR);
    const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(SEPARATOR));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob);
  };



  const [cancelledEdit, setCancelledEdit] = useState(0)


  const getRowClassName = (rowProps) => {
    if(rowProps.id === recentlyEditedRowId ){
        rowProps.style.color = '#7986cb'
    }
  };



  return (
    <div className="container-fluid  ">
      <ToastCco/>

      <Row className=" d-flex justify-content-between card-header p-3">
        <Col xl={12} lg={8} >
          <Row className="mb-3">
              <Col xl={3} lg={2}>
                <div  className="d-flex flex-column align-items-center dropdownContainer">
                  <label >
                    Period
                  </label>
                  <Dropdown onToggle={()=>{alertChange()}}>
                    <Dropdown.Toggle
                      as={Button}
                      href="#"
                      variant=" text-secondary"
                      id="dropdownMenuButton3"
                      aria-expanded="false"
                    >
                      {monthToDb}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton3"
                    >
                      {months.map((opcion, indice) => (
                        <li className="card-title" key={indice}>
                          <Dropdown.Item
                            onClick={() => {
                              changeDate(opcion);
                            }}

                            href="#"
                          >
                            {opcion}
                          </Dropdown.Item>
                        </li>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>




            <Col xl={3} lg={2}>
              <div  className="d-flex flex-column align-items-center dropdownContainer">
                  <label >
                    Account
                  </label>
                <Dropdown onToggle={()=>{alertChange()}}>
                  <Dropdown.Toggle
                    as={Button}
                    href="#"
                    variant=" text-secondary"
                    id="dropdownMenuButton3"
                    aria-expanded="false"
                    className="botonAnchoFijo"
                  >
                    {accountSelectedForDistributionTable.accountName!== null ? accountSelectedForDistributionTable.accountName : 'No Account'}
                  </Dropdown.Toggle>
                          
                  {/* Dropdown con el que se selecciona la cuenta con la que se va a dristribuir */}

                  <Dropdown.Menu
                    className="dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton3"
                  >
                    {Array.isArray(cardUsageAccount) && cardUsageAccount.map((opcion, indice) => (
                      <li className="card-title" key={indice}>
                        <Dropdown.Item
                          onClick={() =>
                            seleccionarOpcion(opcion.accountName, opcion.accountId)
                          }
                          href="#"
                        >
                          {opcion.accountName}
                        </Dropdown.Item>
                      </li>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                </div>
            </Col>


                         
            <Col xl={3} lg={2}>
                <div  className="d-flex flex-column align-items-center dropdownContainer">
                  <label >
                    Driver
                  </label>
                  <SelectDriverForDistribution accountId={accountSelectedForDistributionTable.accountId} className="me-2" />
                </div>
            </Col> 


            <Col xl={3} lg={2}>
              <LastUpdateAlert formattedDate={formattedDate}/>
            </Col>


          </Row>

          <Row>

            <Col className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center ">
                <div  className="me-3 dropdownContainer" >
                    <label className="form-check-label "  style={{ whiteSpace: 'nowrap'}} >
                      Percent View
                    </label>
                </div>
                  <div className="form-check form-switch form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="switch1"
                      onChange={handleSwitchChange}
                      checked={isPercentView}
                    />
                  </div>
              </div>
            </Col>




            <Col className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center ">
                <div  className="me-3 dropdownContainer" >
                    <label className="form-check-label "  style={{ whiteSpace: 'nowrap'}} >
                    Enable filtering
                    </label>
                </div>
                  <div className="form-check form-switch form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="switch1"
                      onChange={handleToggleChange}
                      checked={enableFiltering}
                    />
                  </div>
              </div>
            </Col>



            <Col  className="d-flex justify-content-center">
                <div >
                    <Button variant="outline-success" className="botonesAccionExport " onClick={() => { exportCSV() }}>
                      <PiMicrosoftExcelLogoBold style={{ marginRight: '5px' }} size={22} />
                      Export
                    </Button>
                </div>
            </Col>

            <Col className="d-flex justify-content-center">
                <div>
                    <Button variant="outline-secondary"  className="botonesAccionExport "   onClick={() => { openModalToDelete() }}>
                      Actions
                    </Button>
                </div>
              </Col>



          </Row>
         
      
        </Col>

      </Row>
      <Row>
        <Col md="12">
          <ReactDataGrid
            idProperty="id"
            handle={setGridRef}
            style={{ minHeight: height }}
            onEditComplete={onEditComplete}
            editable={true}
            columns={columns}
            dataSource={!isPercentView ? dataSourceAbsolute : dataSourcePercentaje}
            sortColumn="service"
            defaultFilterValue={ filterValue }
            sortDirection="ASC"
            showEmptyRows={false}
            showZebraRows={true}
            enableFiltering={enableFiltering}
            onEditCancel={setCancelledEdit}
            onRenderRow={getRowClassName}
          />


      
        </Col>
      </Row>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(DistributionTable);
