import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Card from "../../components/Card";
import "../../styles/drivers.css";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import { openModal } from "../../store/slices/modal/modalSlice";
import { getListOfAllNotifications,deactivateNotification } from "../../store/slices/notifications/notificationThunks";
import { setIsBeginEditedTrue,setNotificationToModify,resetEditState,setIdNotification } from "../../store/slices/notifications/notificationSlice";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
const NotificationList = () => {
  const dispatch = useDispatch();
  const user = useAuth0();


  const { idTenant } = useSelector((state) => state.costing);
  const { listOfNotifications } = useSelector((state) => state.notifications);
 
  useEffect(() => {
    dispatch(getListOfAllNotifications({tenantid:idTenant}))
    dispatch(resetEditState())
  }, [])
  

    const handleDeleteNotification = (idNotification)=>{
        dispatch(setIdNotification(idNotification))
        dispatch(openModal("DeleteNotification"))
    }


    const handleActiveNotification = (idNotification,state)=>{
        //aca llamo a lafuncion del thunks de desactivar esa notificacion , soea no manda alertas pero si sigue siendo visile para el usuario
        dispatch(deactivateNotification({idNotification:idNotification , tenantid:idTenant ,user:user.user.email,state:!state}))
    }

    const handleEdit = (index)=>{
        dispatch(setIsBeginEditedTrue())
        dispatch(setNotificationToModify(listOfNotifications[index]))
    }

  return (
    <Row>
      <Col sm="12">
        <Card>
        <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Notifications settings</h4>
              </div>
            </Card.Header>
          {listOfNotifications.length === 0 ?  (  <div style={{  display: 'flex',
                   alignItems: 'center',   
                      justifyContent: 'center',fontSize:'25px',
                      height: '40vh',  }}> 
                      <svg style={{marginRight:'10px'}} xmlns="http://www.w3.org/2000/svg" width="40" height="36" fill="currentColor" class="bi bi-bell-slash" viewBox="0 0 16 16">
  <path d="M5.164 14H15c-.299-.199-.557-.553-.78-1-.9-1.8-1.22-5.12-1.22-6 0-.264-.02-.523-.06-.776l-.938.938c.02.708.157 2.154.457 3.58.161.767.377 1.566.663 2.258H6.164zm5.581-9.91a3.986 3.986 0 0 0-1.948-1.01L8 2.917l-.797.161A4.002 4.002 0 0 0 4 7c0 .628-.134 2.197-.459 3.742-.05.238-.105.479-.166.718l-1.653 1.653c.02-.037.04-.074.059-.113C2.679 11.2 3 7.88 3 7c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0c.942.19 1.788.645 2.457 1.284l-.707.707zM10 15a2 2 0 1 1-4 0zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75z"/>
</svg>
No notifications created   
                      </div> ):(<Card.Body className="p-0">
            <div className="table-responsive mt-4">
              <table
                id="basic-table"
                className="table table-striped mb-0"
                role="grid"
              >
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Services</th>
                    <th>Notification Name</th>
                    <th>Threshold</th>
                    <th>Information</th>
                    <th>Warning</th>
                    <th>Alert</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {listOfNotifications.map((data, index) => (
                    <tr key={index}>
                      <td>{data.consolidated ? 'Consolidated' : data.accountName}</td>
                      <td>
                       { data.serviceNotification !== null ?  <h6>{data.serviceName}</h6> : <h6>All services</h6>}
                      </td>
                      <td>
                          <h6>{data.notificationName}</h6>
                      </td>
                     <td>
                        <h6>$ {data.threshold}</h6>
                     </td>
                     <td>
                        <h6>% {data.information}</h6>
                     </td>
                     <td>
                        <h6>% {data.warning}</h6>
                     </td>
                     <td>
                        <h6>% {data.alert}</h6>
                     </td>
                     <td>

                        {/* agregar boton para habilitar y deshabilitar */}

                        <div className="flex align-items-center list-user-action">
                            <Tooltip title="Edit" position="top" trigger="mouseenter" delay={200}>
                                <Link   onClick={()=>{handleEdit(index)}} className="btn btn-sm btn-icon btn-warning"   to="/newnotification">
                                  <span className="btn-inner">
                                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                        <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                     </svg>
                                  </span>
                                </Link>
                            </Tooltip>
                           {' '}
                            <Tooltip title={data.active ? "Deactivate" : "Activate"} position="top" trigger="mouseenter" delay={200}>
                                <Link onClick={()=>{handleActiveNotification(data.idNotification,data.active)}} className="btn btn-sm btn-icon btn-success" to="#">
                                     {data.active ?
                                     <VisibilityOutlinedIcon/>

                                     :
                                     <VisibilityOffOutlinedIcon/>
                                     }
                                </Link>
                            </Tooltip>
                           {' '}
                            <Tooltip title="Delete" position="top" trigger="mouseenter" delay={200}>
                                <Link onClick={()=>{handleDeleteNotification(data.idNotification)}} className="btn btn-sm btn-icon btn-danger"  >
                                   <span className="btn-inner">
                                      <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                         <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                         <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                         <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                      </svg>
                                   </span>
                                </Link>
                           </Tooltip> 
                           {' '}
                        </div>
                        </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card.Body>)}
        </Card>
      </Col>
    </Row>
  );
};

export default NotificationList;
