import { createSlice } from '@reduxjs/toolkit'




const initialState = {
    forecastState:'ready',   //esta variable la utilizo para saber si estoy procesando una peticion o no
    oneAccountData:{},
    accountsNamesAndIds:[],
    forecastConsolidated:{}

  }
  
  export const forecastSlice = createSlice({
      name: 'forecast',
      initialState,
      reducers: {
        //actualizo el objeto drivers con los drivers que me devuelve el backend
        setDataInAccount : (state,{payload})=>{
            state.oneAccountData = payload
        },
        setAccountsToSelect : (state,{payload})=>{
            state.accountsNamesAndIds = payload
        },
        setStateForecast:(state,{payload})=>{
            state.forecastState = payload
        },
        setForecastForConsolidated:(state,{payload})=>{
            state.forecastConsolidated = payload
        },

      },
  })
  // Action creators are generated for each case reducer function
  export const {setDataInAccount,setAccountsToSelect,setStateForecast,setForecastForConsolidated} = forecastSlice.actions