import React, { useEffect, useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateIdDriverSelected,updateAccountIdToDistribution,setIndexDimensionSelectedForDistribution } from "../../../store/slices/drivers/driverSlice";

const SelectDriverForDistribution = (accountId) => {
  const dispatch = useDispatch();
  const { drivers, driverIdSelected,accountIdToDistribution,indexDimensionSelected } = useSelector((state) => state.drivers);

  const filteredArray = drivers.filter((element) =>
    element.relatedAccounts.includes(accountId.accountId)
  );


  const [seleccionado, setSeleccionado] = useState("" );



  useEffect(() => {
      if (filteredArray.length > 0 && seleccionado === "")  {
        if(indexDimensionSelected !== 0){
          dispatch(updateIdDriverSelected(filteredArray[indexDimensionSelected].idDriver));
          setSeleccionado(filteredArray[indexDimensionSelected].driverName);
       // seleccionarOpcion(filteredArray[0]?.driverName, filteredArray[0]?.idDriver,indexDimensionSelected)

        } else{
          seleccionarOpcion(filteredArray[0]?.driverName, filteredArray[0]?.idDriver,0)

        }
       
        // if(accountId.accountId===accountIdToDistribution){
        //   console.log('filteredindexdimension',filteredArray[indexDimensionSelected].driverName)
        //    setSeleccionado(filteredArray[indexDimensionSelected].driverName);
        // // }
        // else{
        //   setSeleccionado(filteredArray[0]?.driverName);
        // }  
      }

  }, [filteredArray]); 
  
  

  

  const seleccionarOpcion = (driverName, driverId,index) => {
    setSeleccionado(driverName);
    dispatch(updateIdDriverSelected(driverId));
    dispatch(setIndexDimensionSelectedForDistribution(index))

  };



  // useEffect(() => {
  //   if (filteredArray.length > 0) {
  //     if(accountId.accountId===accountIdToDistribution){
  //     dispatch(updateIdDriverSelected(filteredArray[indexDimensionSelected].idDriver));
  //     }
  //     else{
  //       setSeleccionado(filteredArray[0]?.driverName );
  //     }  
  //   }

  //   dispatch(updateAccountIdToDistribution(accountId.accountId))
  // }, []);




  return (
    <Dropdown>
      <Dropdown.Toggle
        as={Button}
        href="#"
        variant="text-secondary"
        id="dropdownMenuButton3"
        aria-expanded="false"
        className="botonAnchoFijo"
      >
        {filteredArray.length > 0 ? seleccionado : "No drivers loaded"}
      </Dropdown.Toggle>
     
      <Dropdown.Menu
        className="dropdown-menu-end"
        aria-labelledby="dropdownMenuButton3"
      >
        {filteredArray.length > 0 ? (
          filteredArray.map((opcion, indice) => (
            <li className="card-title" key={indice}>
              <Dropdown.Item
                onClick={() =>
                  seleccionarOpcion(opcion.driverName, opcion.idDriver,indice)
                }
                href="#"
              >
                {opcion.driverName}
              </Dropdown.Item>
            </li>
          ))
        ) : (
          <li className="card-title">
            <Dropdown.Item href="#">No drivers loaded</Dropdown.Item>
          </li>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectDriverForDistribution;
