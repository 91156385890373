import {fetchBackend} from "./fetch";



const getDataForReportsFetch = async(body) => {
    const resp = await fetchBackend('report/getinforeporttofront', body ,'POST')
    const respJson =  resp.json()
    return respJson
}


const getEmailsTable = async(body) => {
    const resp = await fetchBackend('report/emailsforreports', body ,'POST')
    const respJson =  resp.json()
    return respJson
}


const addRecipients = async(body) => {
    const resp = await fetchBackend('report/addrecipientstoreport', body ,'POST')
    const respJson =  resp.json()
    return respJson
}
const addExternalRecipients = async(body) => {
    const resp = await fetchBackend('report/addexternalrecipientstoreport', body ,'POST')
    const respJson =  resp.json()
    return respJson
}

const removeRecipients = async(body) => {
    const resp = await fetchBackend('report/removerecipientstoreport', body ,'POST')
    const respJson =  resp.json()
    return respJson
}

export {getDataForReportsFetch,getEmailsTable,addRecipients,removeRecipients,addExternalRecipients}