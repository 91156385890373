
import { getDataForReportsFetch,getEmailsTable ,addRecipients,removeRecipients,addExternalRecipients} from "../../../helpers/reports";
import { getAllDataForReports,setReportState,setEmailsForTable,updateTableInModal,updateTableReportSend,handleReportToastMessage} from "./reportsSlices";


export const getDataForReports = (body) => {
    return async( dispatch ) => {
        dispatch(updateTableReportSend('processing'))
        const respJson = await getDataForReportsFetch({idTenant:body})
        dispatch(getAllDataForReports(respJson))
        dispatch(updateTableReportSend('ready'))

    }
}

export const getEmailsForRecipientsTable = (body) => {
    return async( dispatch ) => {
        dispatch(setReportState('processing'))
        const respJson = await getEmailsTable({idTenant:body})
        dispatch(setEmailsForTable(respJson))
        dispatch(setReportState('ready'))

    }
}
export const addExternalRecipientsToReport = (body) => {
    return async( dispatch ) => {
        dispatch(updateTableInModal('processing'))
        const respJson = await addExternalRecipients({idTenant:body.idTenant,userEmail:body.userEmail,name:body.name})
        dispatch(handleReportToastMessage( {message:respJson.message,status:respJson.status}))

        dispatch(updateTableInModal('ready'))

    }
}

export const addRecipientsToReport = (body) => {
    return async( dispatch ) => {
        dispatch(updateTableInModal('processing'))
        const respJson = await addRecipients({idTenant:body.idTenant,userId:body.userId})
        dispatch(handleReportToastMessage( {message:respJson.message,status:respJson.status}))
                                                                                    
        dispatch(updateTableInModal('ready'))

    }
}
export const removeRecipientsToReport = (body) => {
    return async( dispatch ) => {
        dispatch(updateTableInModal('processing'))
        const respJson = await removeRecipients(body)
    
        dispatch(handleReportToastMessage( {message:respJson.message,status:respJson.status,email:body.userEmail}))
        dispatch(updateTableInModal('ready'))

    }
}