import {saveAllContracts,processingRequest,readyRequest,setStatusFormEdit,addVerifyContractsResults,setRegionsAndPlatforms,handleContractAdd} from './contractsSlices'
import { getContractsFromDb,editContractInDb,addNewContractInDb,deleteContractInDb,verifyContractsInDb,getRegionsAndPlatforms} from '../../../helpers/contracts';
import { openModal } from '../modal/modalSlice';

export const getContracts = (idAccount) => {
    return async (dispatch) => {
      dispatch(processingRequest());
      const respJson = await getContractsFromDb({idAccount:idAccount});
      if (respJson.status === 200) {
        let allContracts = respJson.contracts
          allContracts.sort((contractA, contractB) => new Date(contractA.from) - new Date(contractB.from));
       dispatch(saveAllContracts(allContracts))
       dispatch(readyRequest())
      } else {
        // No debe hacer nada
      }
    };
  };

export const addNewContract = (contract) => {
    return async (dispatch) => {
        dispatch(processingRequest());
        const verifyStatus = await verifyContractsInDb({from:contract.from,to:contract.to})
     
        if(verifyStatus.contracts === true){
          dispatch(addVerifyContractsResults({message:verifyStatus.message,results:verifyStatus.results}))
          dispatch(openModal("SnowflakeVerifyContract"))
          dispatch(readyRequest())
        }else{
          const respJson = await addNewContractInDb(contract);
          if (respJson.status === 200){
            dispatch(getContracts(contract.idAccount))     
            dispatch(readyRequest())
          }
        }
       
    };
};
  export const editContract = (contract) => {
    return async (dispatch) => {
      dispatch(processingRequest());      
      const respJson = await editContractInDb(contract);
      if (respJson.status === 200) {
       dispatch(getContracts(contract.idAccount))
       dispatch(readyRequest())
       dispatch(setStatusFormEdit(false))
      } else {
        // No debe hacer nada
      }
    };
  }

  export const deleteContract = (contract) => {
    return async (dispatch) => {
      dispatch(processingRequest());
      const respJson = await deleteContractInDb({contractIdInDb:contract.contractIdInDb,idAccount:contract.idAccount})
      if (respJson.status === 200) {
        dispatch(getContracts(contract.idAccount))     
        dispatch(readyRequest())
       } else {
         // No debe hacer nada
       }
     };
   }

   export const regionsAndPlatforms = () => {
    return async (dispatch) => {
      dispatch(processingRequest());
      const respJson = await getRegionsAndPlatforms()
      if (respJson.status === 200) {
        dispatch(setRegionsAndPlatforms(respJson.platformAndRegions))
        dispatch(readyRequest())
       } else {
         // No debe hacer nada
       }
     };
   } 
 