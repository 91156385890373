import { createSlice } from '@reduxjs/toolkit'




const initialState = {
    reportState:'ready',   //esta variable la utilizo para saber si estoy procesando una peticion o no
    updateTable:'ready',
    sendReportaTable:'ready',
    reportSendDataToModal:{},
    reportToastMessage:{},
    allReportsSend:[],
    emailsUsers:[]
}

  
  export const reportSlices = createSlice({
      name: 'reports',
      initialState,
      reducers: {
        //actualizo el objeto drivers con los drivers que me devuelve el backend
        getAllDataForReports : (state,{payload})=>{
            state.allReportsSend = payload
        },
       setReportState: (state,{payload})=>{
        state.reportState = payload

      },
        setEmailsForTable: (state,{payload})=>{
        state.emailsUsers = payload

      },
      updateTableInModal:(state,{payload})=>{
        state.updateTable = payload
      },
      updateTableReportSend:(state,{payload})=>{
        state.updateTable = payload
      },
      sendDataToModal:(state,{payload})=>{
        state.reportSendDataToModal = payload
      },
      handleReportToastMessage:(state,{payload})=>{
        state.reportToastMessage = payload
      }


}
  })
  // Action creators are generated for each case reducer function
  export const {getAllDataForReports,setReportState,setEmailsForTable,updateTableInModal,updateTableReportSend,sendDataToModal,handleReportToastMessage} = reportSlices.actions