import { React } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../store/slices/modal/modalSlice";
import { deleteCredential } from "../../../store/slices/costing/costingThunks";

const DeleteAccountModal = () => {
  const dispatch = useDispatch();

  const { openModalState, modalSelected } = useSelector((store) => store.modal);
  const { credentialToChange, cardUsageAccount } = useSelector(
    (state) => state.costing
  );

  let accountName = cardUsageAccount[credentialToChange.index].accountName;

  const deleteCredentials = () => {
    dispatch(
      deleteCredential({
        accountId: cardUsageAccount[credentialToChange.index].accountId,
      })
    );
    dispatch(closeModal());
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Delete Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the account {accountName} ?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Close
        </Button>
        <Link to="/accounts">
          <Button variant="danger" onClick={() => deleteCredentials()}>
            Delete
          </Button>
        </Link>
      </Modal.Footer>
    </>
  );
};

export default DeleteAccountModal;
