import { fetchBackend } from "./fetch";




const getOneUserData = async(body) => {
    const resp = await fetchBackend('users/getuser', body ,'POST')
    const respJson =  resp.json()
    return respJson
}

export {getOneUserData}