import { clearDataDashbord,processingRequest,getAwsUsage,updateStateCredentials,readyRequest,updatealertCredentialsInvalidTrue,updatealertCredentialsInvalidFalse,updateStateTrueModalCredencialesSave,getDataDashbord,clearDataDistribution,getDataDistrbution,setIdTenant,setFlagToRenderTrue,setDataSavedSuccesTrue } from "./costingSlice"
import {setDeleteAccount, getAwsResults,getConnection,setCredentials,getDataDashbords,getDataDistributionFetch,setDataDistributionFetch,updateDataForAccountCard,setTenanFromDB } from "../../../helpers/costing"
import { updateDataDashbordsFetch } from "../../../helpers/accounts"
//tengo que seguir armando la llamada al back para que llamen a esta funcion desde los componentes y agregar aca todos los dispatch
import { getDataForConsolidatedCard } from "../accounts/accountThunks"


export const getUsageFromThisMonthAndLast = (body) => {
    return async( dispatch ) => {

        const respJson = await getAwsResults(body)
        
        if(respJson.status === 200){
            
            dispatch( getAwsUsage(respJson.data.data) )
        }else if(respJson.status === 204){
            dispatch( getAwsUsage(respJson.data.data) )
        }
        else{
            dispatch(readyRequest())
            console.log('Error')
        }
        
        
    }
}


export const testConnection = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        const respJson = await getConnection(body)
        
        if(respJson.status ==='true'){
            dispatch(updateStateCredentials())
            dispatch(updatealertCredentialsInvalidTrue())
            dispatch(readyRequest())
        }else{
            dispatch(readyRequest())
            dispatch(updatealertCredentialsInvalidFalse())
        }
      
    }
}

export const setNewCredentials = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        console.log(body)
        const respJson = await setCredentials(body)
        if(respJson.status === 'true'){

            dispatch(updateStateTrueModalCredencialesSave())
            dispatch(readyRequest())
        }
        else{
            
            dispatch(readyRequest())
        }
        
      
    }
}



export const deleteCredential = (body)=>{
    return async( dispatch ) => {
        dispatch( processingRequest() )
        const respJson = await setDeleteAccount(body)
        if(respJson.status === 'true'){
            dispatch(readyRequest())
        }
        else{
            console.log('nada')
        }
        
    }
}

//trae la data desde la db y la carga en el initalState
export const getDataforDashbords = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        const respJson = await getDataDashbords(body)
        dispatch(getDataDashbord(respJson))
        dispatch(readyRequest())
    }
}

//esto solo hace un evio cuando se quiere recargar la inforamcion desde a aws a la db
export const updateDataforDashbords = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        
        const respJson = await updateDataDashbordsFetch(body)
        if(respJson.status === 'true'){
            dispatch(clearDataDashbord())
            const respJson = await getDataDashbords(body)
            dispatch(getDataDashbord(respJson))
            dispatch(readyRequest())
        }
        else{
            dispatch(readyRequest())
            console.log('nada')
        }
    }
}


export const updateDataforAccounts = (body)=>{
    return async( dispatch ) => {
        dispatch( processingRequest() )
        const respJson = await updateDataForAccountCard(body)
        if(respJson.status === 'true'){
            dispatch(clearDataDashbord())
            const respJson = await getAwsResults(body)
            if(respJson.status === 200){
                dispatch(getAwsUsage(respJson.data.data))
                dispatch(getDataForConsolidatedCard({idTenant:body.tenantId}))
            }else if(respJson.status === 204){
                dispatch( getAwsUsage(respJson.data.data) )
            }
            else{
                dispatch(readyRequest())
                console.log('Error')
            }
        }
        else{
            dispatch(readyRequest())
           
        }
    }
}



export const getDataforDistribution = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        
        const respJson = await getDataDistributionFetch(body)
        if(respJson.status === 'true'){
            dispatch(clearDataDistribution())
            dispatch(getDataDistrbution(respJson))
            dispatch(readyRequest())
        }
        else{
            
            console.log('nada')
        }
    }
}


export const setDataforDistribution = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        
        const respJson = await setDataDistributionFetch(body)
        if(respJson.status === 'true'){
            dispatch(clearDataDistribution())
            const respJson = await getDataDistributionFetch(body)
            dispatch(getDataDistrbution(respJson))
            dispatch(readyRequest())
            dispatch(setFlagToRenderTrue())
            dispatch(setDataSavedSuccesTrue())


        }
        else{
            
            console.log('nada')
        }
    }
}


export const setTenant = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        
        const respJson = await setTenanFromDB(body)
        if(respJson.status === 'true'){
            dispatch(setIdTenant(respJson))
            // dispatch(readyRequest())
        }
        else{
            
            console.log('nada')
        }
    }
}



