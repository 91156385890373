import React, { useState, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import{addNewContract, regionsAndPlatforms,getContracts} from '../../../store/slices/contracts/contractsThunks'
import { platforms } from "../../../helpers/contracts";
import {Row,Col,Form,Button} from 'react-bootstrap'
import Card from '../../../components/Card'
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import { handleContractAdd, } from "../../../store/slices/contracts/contractsSlices";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Tooltip} from 'react-tippy';
import { openModal } from "../../../store/slices/modal/modalSlice";

const SnowflakeContract = () => {
  const history = useHistory();
const [disabled,setDisabled]= useState(true)
  const  {idCurrentAccount,platformsAndRegion} = useSelector( (state) => state.contracts );


 

  const dispatch = useDispatch();
  const [input, setInput] = useState({
    from:"",
    to:"",
    platform:"",
    plan:"",
    creditAmount:"",
    creditPrice:"",
    storagePrice:"", 
    storageListPrice:"",
    platformRegion:"",
    listPrice:"",

    region:""
  });
useEffect(()=> {
  if(validateAllInputs()){setDisabled(false)
  }else{
    setDisabled(true)
}
dispatch(regionsAndPlatforms())
},[input])
  

const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.id]: e.target.value,
    });
  };

  const validateAllInputs = () => {
    // Object.values(input) obtiene un array de los valores del objeto input.
    // El método .every() verifica si todos los valores del array cumplen la condición.
    if (
      Object.keys(input).every(
        (key) =>
          key === "region" || (input[key] !== null && input[key].trim() !== "")
      )
    ) {
     // Si todos los valores cumplen la condición, se devuelve true. 
     setDisabled(false)
      return true;
    } else {
      // Si al menos un valor no cumple la condición, se devuelve false.
      setDisabled(true)
      return false;
    }
  };
  




const submitSnowflakeAccount = (e) => {
    e.preventDefault();
    if( validateAllInputs()){
     dispatch(addNewContract({
      from:input.from,
      to:input.to,
      creditPrice:input.creditPrice.replace(',', '.'),
      creditAmount:input.creditAmount.replace(',', '.'),
      storagePrice:input.storagePrice.replace(',', '.'),
      storageListPrice:input.storageListPrice.replace(',', '.'),
      platform:input.platform,
      platformRegion:input.platformRegion,
      plan:input.plan,
      listPrice:input.listPrice.replace(',', '.'),
      region:idCurrentAccount.region,
      idAccount:idCurrentAccount.idAccount,
      creationUser:idCurrentAccount.creationUser
     }))
     //dispatch(getContracts(idCurrentAccount.idAccount))         

     setTimeout(() => {
      history.push('/contracts');
    }, 1500);
  }else{
dispatch(openModal("SnowflakeIncompleteFields"))
  }
  
  };


const styleFormGroup = {color:'black',marginTop:'10px',border:'1px'}
const styleFormControl = {color:'black',borderColor: "#899AF1",backgroundColor:'#E8F0FE'}
  return (
    <>
   <Row>
    <Col>
  <Card>
    <Card.Header>
    <div className="header-title">
         <h4 className="card-title">Snowflake Contract</h4>
         </div>
    </Card.Header>

     <Card.Body>
    <div className="new-contract-info">
    <Form.Label
                        className="col-md-8 form-group"
                        htmlFor="alert"
                      >
                       Fields with * are required for add contract 
                      </Form.Label>
    <form>
   
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h5>Contract information</h5>
        <Tooltip
          title="In this view you need to load the contract data with Snowflake to get the costs accurately"
          position="top"
          delay={500}
        >
          <InfoOutlinedIcon className="ms-2" />
        </Tooltip>
      </div>  
      <Form>
        <div className="d-flex">
          <div style={{ flex: 1, marginRight: '10px' }}>
            <Form.Group controlId="from" style={styleFormGroup}>
              <Form.Label>* From</Form.Label>
              <Form.Control
              style={input.from ? styleFormControl: {borderColor: "#899AF1"}}
                type="date"
                placeholder="Enter created user"
                value={input.from}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
         
            <div className="d-flex">
  <div style={{ flex: 1, marginRight: '10px' }}>
     <Form.Group controlId="platform" style={styleFormGroup}>
            <Form.Label>* Platform</Form.Label>
            <Form.Select
          name="platform"
          style={input.platform ? styleFormControl : { borderColor: "#899AF1" }}
          value={input.platform}
          onChange={handleChange}
        >
          <option disabled value=""> Select a platform</option>
          {platforms.map((platform,idx) => (
            <option key={idx} value={platform}>
              {platform}
            </option>
          ))}
        </Form.Select>
            </Form.Group>
  </div>
  <div style={{ flex: 1, marginLeft: '10px' }}>
    
      
            <Form.Group controlId="platformRegion" style={styleFormGroup}>
            <Form.Label>* Region</Form.Label>
            <Form.Select
          name="platform"
          style={input.platformRegion ? styleFormControl : { borderColor: "#899AF1" }}
          value={input.platformRegion}
          onChange={handleChange}
              >
          <option disabled value=""> Select a region</option>
          {input.platform === 'Amazon Web Services(AWS)' ?
          
          platformsAndRegion.amazonWebServices.map((platform,idx) => (
            <option key={idx} value={platform}>
              {platform}
            </option>
          )): input.platform === 'Google Cloud Platform' ?   platformsAndRegion.googleCloudPlatform.map((platform,idx) => (
            <option key={idx} value={platform}>
              {platform}
            </option>
          )):  input.platform === 'Microsoft Azure' ?   platformsAndRegion.microsoftAzure.map((platform,idx) => (
            <option key={idx} value={platform}>
              {platform}
            </option>
          )): null
        }
        </Form.Select>
            </Form.Group>
  </div>
</div>
<Form.Group controlId="creditPrice" style={{ ...styleFormGroup, width: '100%' }}>
      <Form.Label>* Credit Price</Form.Label>
      <Form.Control
         type="number"
        style={input.creditPrice ? { ...styleFormControl, height: '100%' }: {height: '100%',borderColor: "#899AF1"}}
        placeholder="Example: $4 "
        value={input.creditPrice}
        onChange={(e) => handleChange(e)}
      />
    </Form.Group>     
        <Form.Group controlId="listPrice" style={styleFormGroup}>
              <Form.Label>* Credit list price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Example: $8 "
                style={input.listPrice ? styleFormControl: {borderColor: "#899AF1"}}
                value={input.listPrice}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
          </div>  
          <div style={{ flex: 1, marginLeft: '10px' }}>
          <Form.Group controlId="to" style={styleFormGroup}>
              <Form.Label>* To</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter tenant name"
                style={input.to ? styleFormControl: {borderColor: "#899AF1"}}
                value={input.to}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>



            <div className="d-flex">
  <div style={{ flex: 1, marginRight: '10px' }}>
  <Form.Group  controlId="plan" style={styleFormGroup}>
          <Form.Label>* Plan</Form.Label>
          <Form.Select
            as="select"
            style={input.plan ? styleFormControl: {borderColor: "#899AF1"}}
            value={input.plan}
            onChange={(e) => handleChange(e)}
          >
            <option  value="" disabled> Select plan</option>
            <option style={{color:'black'}} value="Enterprise">Enterprise</option>
            <option style={{color:'black'}} value="Business Critical">Business Critical</option>
            <option style={{color:'black'}} value="Standard">Standard</option>

          </Form.Select>
        </Form.Group>
  </div>
  <div style={{ flex: 1, marginLeft: '10px' }}>
    
      
  <Form.Group controlId="creditAmount" style={styleFormGroup}>
              <Form.Label>* Total credit purchased</Form.Label>
              <Form.Control
                type="number"
                placeholder="Example: 5000 "
                style={input.creditAmount ? styleFormControl: {borderColor: "#899AF1"}}
                value={input.creditAmount}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
  </div>
</div>
<Form.Group controlId="storagePrice" style={{ ...styleFormGroup, width: '100%' }}>
      <Form.Label>* Contract storage price</Form.Label>
      <Form.Control
        type="number"
        style= { input.storagePrice ? { ...styleFormControl, height: '100%' }: {height: '100%',borderColor: "#899AF1"}}
        placeholder="Example: $40 "

        value={input.storagePrice}
        onChange={(e) => handleChange(e)}
      />
    </Form.Group>     
    <Form.Group controlId="storageListPrice" style={styleFormGroup}>
              <Form.Label>* Storage price  list</Form.Label>
              <Form.Control
                type="number"
                placeholder="Example: $40 "
                style={input.storageListPrice ? styleFormControl: {borderColor: "#899AF1"}}
                value={input.storageListPrice}
                onChange={(e) => handleChange(e)}
              />
            </Form.Group>
      
          </div>
        </div>       
      </Form>
 
   <div style={{display: 'flex', justifyContent: 'flex-end', margin: '15px 10px 0 0' }} className="d-flex justify-content-end">
   
    <Link to='/contracts'>
        <Button   type="button" style={{marginRight:'15px' }} >Back to contracts</Button>
        </Link>
   
 <Button  disabled={disabled} type="submit" variant="primary" onClick={(e) => submitSnowflakeAccount(e)}>
          Submit
        </Button>
   
   </div>
   </form>
      </div>
      </Card.Body>
      </Card>
    </Col>
   </Row>
  </>
  );
};

export default SnowflakeContract;