import { React, useEffect, useState } from "react";
import { Row, Col, Dropdown, Button, Spinner } from "react-bootstrap";

import { bindActionCreators } from "redux";

import LastUpdateAlert from "../../components/cco/alerts/LastUpdateAlert";
import SpeedoMeter from "../../components/cco/charts/SpeedoMeter";
import ServiceBar from "../../components/cco/charts/ServiceBar";

import ToastCco from "../../components/cco/toasts/ToastCco";


// AOS
import AOS from "aos";
import "../../../node_modules/aos/dist/aos";
import "../../../node_modules/aos/dist/aos.css";

//apexcharts
import Chart from "react-apexcharts";

//swiper
// import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import { useAuth0 } from '@auth0/auth0-react'


// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";

// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../store/setting/setting";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";

import {
  getDataforDashbords,
  updateDataforDashbords,
} from "../../store/slices/costing/costingThunks.js";

import "../../styles/containerPrincipal.css";
import { updateIdAccount } from "../../store/slices/costing/costingSlice.js";
const moment = require("moment");

// install Swiper modules
SwiperCore.use([Navigation]);

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});

const DashbordCosting = (props) => {
  useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
      disable: function () {
        var maxWidth = 996;
        return window.innerWidth < maxWidth;
      },
      throttleDelay: 10,
      once: true,
      duration: 700,
      offset: 10,
    });
    //   customizer
    const colorcustomizerMode = sessionStorage.getItem("color-customizer-mode");
    const colorcustomizerinfoMode = sessionStorage.getItem(
      "colorcustominfo-mode"
    );
    const colorcustomizerprimaryMode = sessionStorage.getItem(
      "colorcustomprimary-mode"
    );
    if (colorcustomizerMode === null) {
      props.ColorCustomizerAction(
        props.customizerMode,
        props.cololrinfomode,
        props.colorprimarymode
      );
      document.documentElement.style.setProperty(
        "--bs-info",
        props.cololrinfomode
      );
    } else {
      props.ColorCustomizerAction(
        colorcustomizerMode,
        colorcustomizerinfoMode,
        colorcustomizerprimaryMode
      );
      document.documentElement.style.setProperty(
        "--bs-info",
        colorcustomizerinfoMode
      );
    }
  });


  // const user = useAuth0();

  const { dataDashbord, cardUsageAccount, status,idTenant } = useSelector((state) => state.costing);

  const dispatch = useDispatch();

  const [accountId, setIdAccount] = useState(Array.isArray(cardUsageAccount) ? cardUsageAccount[0]?.accountId : 0);
 
  const [seleccionado, setSeleccionado] = useState(Array.isArray(cardUsageAccount) ? cardUsageAccount[0].accountName : "")



  const seleccionarOpcion = (opcion, accountId) => {
    setSeleccionado(opcion);
    setIdAccount(accountId);

    const body = {
      tenantId: idTenant,
      accountId: accountId,
    };
    dispatch(updateIdAccount({ idAccount: accountId }));
    dispatch(getDataforDashbords(body));
  };
  
//aca se selecciona el consolidated por eso pasa el -1 ya que este id no existe en la bd
  const seleccionarConsolidated = () => {
    setSeleccionado("Consolidated");
    setIdAccount(-1);

    const body = {
      tenantId: idTenant,
      accountId: -1,
    };

    dispatch(updateIdAccount({ idAccount: -1 }));
    dispatch(getDataforDashbords(body));
  };

  useEffect(() => {
    if (Array.isArray(cardUsageAccount) ) {
        const body = {
          tenantId: idTenant,
          accountId: cardUsageAccount[0]?.accountId,
        };      
        dispatch(getDataforDashbords(body));
        dispatch(updateIdAccount({ idAccount: cardUsageAccount[0].accountId }));
    }
  }, []);


  let data = dataDashbord.data || [];

  let formattedDate =dataDashbord.lastUpdate? moment(dataDashbord.lastUpdate).format("MMM DD YYYY HH:mm z"):'';


  let dataMonths = dataDashbord.months || [];

  let lineGraphics = {
    options: {
      chart: {
        stacked: true,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
        },
      },
      colors: [
        "#03a9f4",
        "#80d8ff",
        "#00bcd4",
        "#00acc1",
        "#008c9e",
        "#00796b",
        "#004d40",
        "#2e7d32",
        "#558b2f",
        "#9e9d24",
        "#f9a825",
        "#ff8f00",
        "#ef6c00",
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "28%",
          endingShape: "flat",
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        type: "datetime",
        categories: dataMonths,
        labels: {
          minHeight: 20,
          maxHeight: 20,
          style: {
            colors: "#8A92A6",
          },
        },
      },
      yaxis: {
        // tickAmount: 10,
        labels: {
          show: true,
          style: {
            colors: "#a68a90",
          },
          formatter: function (val) {
            return Math.round(val);
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val;
          },
        },
      },
    },
    series: data,
  };

  return (
    <>
      <Row>
        <Col md="12">
          <ToastCco />
          <Col
            md="12"
            className="d-flex justify-content-end align-items-center"
          >
            <LastUpdateAlert  formattedDate={formattedDate} dashboard={true} />
             
          </Col>
          <div className="card" data-aos="fade-up" data-aos-delay="1000">
            <div className="flex-wrap card-header d-flex justify-content-start">
              <Dropdown>
                <Dropdown.Toggle
                  as={Button}
                  variant=" text-secondary"
                  id="dropdownMenuButton3"
                  aria-expanded="false"
                >
                  {seleccionado}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton3"
                >
                   <li className="card-title">
                    <Dropdown.Item onClick={() =>
                         seleccionarConsolidated()
                        } >
                      Consolidated
                        
                    </Dropdown.Item>
                  </li>
                  { cardUsageAccount?.map((opcion, indice) => (
                    <li className="card-title" key={indice}>
                      <Dropdown.Item
                        onClick={() =>
                          seleccionarOpcion(
                            opcion.accountName,
                            opcion.accountId
                          )
                        }
                        
                      >
                        {opcion.accountName}
                      </Dropdown.Item>
                    </li>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {status === "processing" && (
                <Spinner
                  animation="grow"
                  className="text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </div>
            <div className="card-body" >
              {!dataDashbord ? (
                <div>Cargando datos...</div>
              ) : (
                <Chart
                  className="d-activity"
                  options={lineGraphics.options}
                  series={lineGraphics.series}
                  type="bar"
                  height="230"
                />
              )}
            </div>
          </div>
        </Col>
        <Col md="12" xl="6">
        

          <SpeedoMeter/>
       

        </Col>
        <Col md="12" xl="6">

          <ServiceBar/>
          
        </Col>
      </Row>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashbordCosting);
