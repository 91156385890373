import { fetchBackend } from "./fetch";
//Proporciona un Json que tiene mapeada todas las funciones de acuerdo al rol del usuario permitiendo ver o no ver ciertas opciones

const createNewTenantInDb = async(body) => {
    const resp = await fetchBackend('admin/createtenant', body ,'POST')
    const respJson =  resp.json()
    return respJson
}


const getJsonConfigs = async(body) => {
    const resp = await fetchBackend('admin/configroles', body ,'GET')
    const respJson =  resp.json()
    return respJson
}

//Funciones para el superAdmin
const getAllTenants = async(body) => {
    const resp = await fetchBackend('admin/getalltenants',body,'GET')
    const respJson =  resp.json()
    return respJson
}
const getAllSuperAdmins = async(body) => {
    const resp = await fetchBackend('admin/getsuperad',body,'GET')
    const respJson =  resp.json()
    return respJson
}
const setNewTenant = async(body) => {
    const resp = await fetchBackend('admin/setadmintenant', body ,'POST')
    const respJson =  resp.json()
    return respJson
}

const changeRole = async(body) => {
    const resp = await fetchBackend('admin/changeuserrole', body ,'POST')
    const respJson =  resp.json()
    return respJson
}



export {getAllTenants,setNewTenant,changeRole,getJsonConfigs,getAllSuperAdmins,createNewTenantInDb}