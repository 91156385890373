
//Archivo en donde se muestra un boton en el medio del componente que diga help 

import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Help = () => {

  let  videoUno = 'https://www.youtube.com/embed/50ZlQOTMM50'
  let  videoDos = 'https://www.youtube.com/embed/3JXCwN5GhpI'
  let videoTres = 'https://www.youtube.com/embed/zjcW4OqB5Yw'
    
  

    return (
        <>
        <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '80vh', marginBottom:'15vh' }}>
        <h1>Help</h1>
        <a
        href="https://cloudcostingdocs.seidoranalytics.com/"
        target="_blank">
            <Button variant="primary">Go to help</Button>
        </a>
    </div>
            <div className='card-header' style={{marginBottom:'15vh'}}>
            <div className="row d-flex pb-5 w-100 justify-content-center">
              <div className='col-6 '>
                <h5 className=' mt-4'>How do the boards work? </h5>
                <iframe
                  className='mt-2 tutorials-iframe'
                  width="100%" height="315"
                  src={ videoUno }
                  title={('howToCreateListsTitle')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                ></iframe>
              </div>
              
              <div className='col-6'>
                <h5 className='text-lightblue mt-4'>Connect a new account</h5>
                <iframe
                  className='mt-2 tutorials-iframe'
                  width="100%" height="315"
                  src={ videoDos }
                  title={('howToSendAndScheduleTitle')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                ></iframe>
              </div>
  
              <div className='col-6 '>
                <h5 className='text-lightblue mt-4'>How does the distribution table work?</h5>
                <iframe
                  className='mt-2 tutorials-iframe'
                  width="100%" height="315"
                  src={ videoTres }
                  title={('howToCreateTemplatesTitle')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                ></iframe>
              </div>
            </div>
  
        </div>
        </>
    );
}

export default Help;