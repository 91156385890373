import React from 'react'
import {Row,Col,Image} from 'react-bootstrap'
import UserEditForm from '../../components/cco/forms/UserEditForm'
import {Link} from 'react-router-dom'
import Card from '../../components/Card'
import { useSelector,useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { getusers,activeEditForm } from '../../store/slices/users/usersThunks';
import { userModelData } from '../../store/slices/users/usersSlices'
import LockSharpIcon from '@mui/icons-material/LockSharp';
import LockOpenSharpIcon from '@mui/icons-material/LockOpenSharp';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import KeyIcon from '@mui/icons-material/Key';
import shap1 from '../../assets/images/avatars/01.png'
import Loader from '../../layouts/Loader'
import { useAuth0 } from '@auth0/auth0-react'
import ToastUsers from '../../components/cco/toasts/ToastUsers'
import { openModal } from '../../store/slices/modal/modalSlice'
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import { userSelected } from '../../store/slices/roles/rolesSlices'


const UserList =() =>{
   const { idTenant } = useSelector( (state) => state.costing );
   const  {users,usersState,editForm,userStatusprocess,errorUserMessage} = useSelector( (state) => state.users );
   const [selectedUser, setSelectedUser] = useState(null);
    const dispatch = useDispatch();
    const user = useAuth0();
    const emailauth0 = user.user.email

    
    useEffect(()=> {
      dispatch(getusers({ tenantId: idTenant }))
    },[editForm,userStatusprocess])

   const editFormView = (user)=> {
      setSelectedUser(user);
       dispatch(activeEditForm(true))
   } 
   const updateStatusUser = (user) => {
      dispatch(openModal("ChangeStatusUser"))
      dispatch(userModelData({email:user.useremail,tenant:idTenant,name:user.name,lastname:user.lastname}))

   }
   const openModalResetPassword = (user) => {     
      dispatch(openModal("ResetPasswordUser"))
     dispatch(userModelData({email:user.useremail,name:user.name,lastname:user.lastname}))
   }

   const openModalRoleAssignment = (user) => {
      dispatch(openModal("RoleAssignment"))
      
      dispatch(userSelected(user))
   }

   
   return (
     <>
       <ToastUsers />

       {editForm ? (
         <UserEditForm selectedUser={selectedUser} />
       ) : (
         <>
           {usersState !== "ready list" ? (
             <Loader />
           ) : (
             <div>
               <Row>
                 <Col sm="12">
                   <Card>
                     <Card.Header className="d-flex justify-content-between"></Card.Header>
                     <Card.Body className="px-0">
                       <div className="table-responsive">
                         <table
                           id="user-list-table"
                           className="table table-striped"
                           role="grid"
                           data-toggle="data-table"
                         >
                           <thead>
                             <tr className="ligth">
                               <th min-width="100px">Action</th>
                               <th>Profile</th>
                               <th>Name</th>
                               <th>Email</th>
                               <th>Status</th>
                               <th>Role</th>                      
                               <th>Contact</th>
                               <th>Country</th>
                               <th>City</th>
                               <th>Company</th>
                               <th>Position</th>
                               <th>Join Date</th>
                             </tr>
                           </thead>
                           <tbody>
                             {users?.length > 0 ? (
                               users?.map((item) => (
                                 <tr key={item.iduser}>
                                   <td>
                                     <div className="flex align-items-center list-user-action">
                                       <Tooltip
                                         title="Edit"
                                         position="top"
                                         trigger="mouseenter"
                                         delay={200}
                                       >
                                         <span
                                           className="btn btn-sm btn-icon btn-primary"
                                           onClick={() => {
                                             editFormView(item);
                                           }}
                                         >
                                           <svg
                                             width="20"
                                             viewBox="0 0 24 24"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                           >
                                             <path
                                               d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                               stroke="currentColor"
                                               strokeWidth="1.5"
                                               strokeLinecap="round"
                                               strokeLinejoin="round"
                                             ></path>
                                             <path
                                               fillRule="evenodd"
                                               clipRule="evenodd"
                                               d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                               stroke="currentColor"
                                               strokeWidth="1.5"
                                               strokeLinecap="round"
                                               strokeLinejoin="round"
                                             ></path>
                                             <path
                                               d="M15.1655 4.60254L19.7315 9.16854"
                                               stroke="currentColor"
                                               strokeWidth="1.5"
                                               strokeLinecap="round"
                                               strokeLinejoin="round"
                                             ></path>
                                           </svg>
                                         </span>
                                       </Tooltip>

                                       <Tooltip
                                         title="Reset Password"
                                         position="top"
                                         trigger="mouseenter"
                                         delay={200}
                                       >
                                         <Link
                                           className="btn btn-sm btn-icon btn-warning ms-1"
                                           to="#"
                                         >
                                           <span
                                             className="btn-inner"
                                             onClick={() => {
                                               openModalResetPassword(item);
                                             }}
                                           >
                                             <KeyIcon />
                                           </span>
                                         </Link>
                                       </Tooltip>
                                       

                                       <Tooltip
                                         title="Assign rol"
                                         position="top"
                                         trigger="mouseenter"
                                         delay={200}
                                       >
                                         <Link
                                           className="btn btn-sm btn-icon btn-primary ms-1"
                                           to="#"
                                         >
                                           <span className="btn-inner"
                                           onClick={()=>{openModalRoleAssignment({emailUser:item.useremail,roleUser:item.iduserrole})}}
                                           >
                                             <AdminPanelSettingsIcon
                                               style={{
                                                 color: "white",
                                                 fontSize: "1,5rem",
                                               }}
                                             />{" "}
                                             {/* Estilos para el icono */}
                                           </span>
                                         </Link>
                                       </Tooltip>
                                       {emailauth0 === item.useremail ? (
                                         <span></span>
                                       ) : item.active ? (
                                         <Tooltip
                                           title="Disable"
                                           position="top"
                                           trigger="mouseenter"
                                           delay={200}
                                         >
                                           <Link
                                             className="btn btn-sm btn-icon btn-success ms-1"
                                             to="#"
                                           >
                                             <span
                                               className="btn-inner"
                                               onClick={() => {
                                                 updateStatusUser(item);
                                               }}
                                             >
                                               <LockOpenSharpIcon />
                                             </span>
                                           </Link>
                                         </Tooltip>
                                       ) : (
                                         <Tooltip
                                           title="Enable"
                                           position="top"
                                           trigger="mouseenter"
                                           delay={200}
                                         >
                                           <Link
                                             className="btn btn-sm btn-icon btn-danger ms-1 "
                                             to="#"
                                           >
                                             <span
                                               className="btn-inner"
                                               onClick={() => {
                                                 updateStatusUser(item);
                                               }}
                                             >
                                               <LockSharpIcon />
                                             </span>
                                           </Link>
                                         </Tooltip>
                                       )}
                                     </div>
                                   </td>

                                   <td className="text-center">
                                     {item.userprofile === null ? (
                                       <Image
                                         className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                                         src={shap1}
                                         alt="profile"
                                       />
                                     ) : (
                                       <Image
                                         className="bg-soft-primary rounded img-fluid avatar-40 me-3"
                                         src={item.userprofile}
                                         alt="profile"
                                       />
                                     )}
                                   </td>
                                   <td>
                                     {item.name} {item.lastname}
                                   </td>
                                   <td>{item.useremail}</td>
                                   <td>
                                     <span className={`badge'bg-primary'`}>
                                       {item.active === true
                                         ? "Active"
                                         : "Inactive"}
                                     </span>
                                   </td>
                                   <td> { item.iduserrole === 0 ? 'SuperAdmin' : 
                                      item.iduserrole === 1 ? 'Admin' : 
                                      item.iduserrole === 2 ? 'Costing Explorer' :
                                      item.iduserrole === 3 ? 'Read Only' : '' }</td>
                                   <td>{item.contact}</td>
                                   <td>{item.country}</td>
                                   <td>{item.city}</td>
                                   <td>{item.company}</td>
                                   <td>{item.userdepartment}</td>
                                   <td>{item.creationdate}</td>
                                 </tr>
                               ))
                             ) : (
                               <tr>
                                 <td colSpan="11" className="text-center">
                                   No data available in table
                                 </td>
                               </tr>
                             )}
                           </tbody>
                         </table>
                       </div>
                     </Card.Body>
                   </Card>
                 </Col>
               </Row>
             </div>
           )}
         </>
       )}
     </>
   );}

export default UserList;