import React from 'react'


//router
import { Switch,Route } from 'react-router'



//layoutpages
import DefaultCosting from '../layouts/dashboard/DefaultCosting.jsx'
import ParentModal from '../components/cco/modals/ParentModal'


/// if isAuthenticated true recien ahi voy a buscar los datos de las credenciales a la db 
//ternario que decida entre ddvolver las rutas o un loader

const IndexRouters = () => {
    return (
        <>
            <Switch>
                <Route  path="/" component={DefaultCosting}></Route>

            </Switch>
            <ParentModal/>
        </>
    )
}

export default IndexRouters
