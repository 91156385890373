import { getOneUserData } from "../../../helpers/profile";
import { profileData,processingRequest,readyRequest,editProfileFormActive } from "./profileSlices"; 



export const getUser = (userEmail) => {
    return async( dispatch ) => {
         dispatch(processingRequest())
        const respJson = await getOneUserData({userEmail:userEmail})
        if(respJson.status === 'true'){
            dispatch(profileData(respJson))
            dispatch(readyRequest())
        }
        else{
       // console.log('nada')
        }
    }
}

//Funcion para activar el formulario de edicion de usuario en Profile
export const activeProfileForm = (status) => {
    return async (dispatch) => {
      dispatch(editProfileFormActive(status));
    };
  };