import { React } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { closeModal ,openModal} from "../../../store/slices/modal/modalSlice";
import ToastReport from "../toasts/ToastReport";
import {getEmailsForRecipientsTable,addRecipientsToReport,removeRecipientsToReport,addExternalRecipientsToReport} from '../../../store/slices/reports/reportsThunks'
const RecipientModals = () => {
  
    const dispatch = useDispatch();
  const { reportSendDataToModal } = useSelector((state) => state.reports);
  const { idTenant } = useSelector( (state) => state.costing );

  const removeRecipients =(body) => {
    dispatch(removeRecipientsToReport({idTenant:reportSendDataToModal.idTenant, userId: reportSendDataToModal.userId}
        ))
    dispatch(getEmailsForRecipientsTable(idTenant))
    dispatch(closeModal())
    dispatch(openModal('SelectRecipients'))
}
    


  return (
      <>
      <ToastReport/>
      <Modal.Header closeButton>
        <Modal.Title>Delete user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
             Are you sure you want to delete : {reportSendDataToModal.userEmail} ?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModal())
            dispatch(openModal('SelectRecipients'))

          }}
        >
          Close
        </Button>
        <Link >
          <Button variant="primary"  onClick={()=>removeRecipients()}>
          Delete user
          </Button>
        </Link>
      </Modal.Footer>
    </>
  );
};

export default RecipientModals;
