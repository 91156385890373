import React, { useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import "../../styles/drivers.css";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setIndexDriverSelected } from "../../store/slices/drivers/driverSlice";
const DriversCardView = () => {
  const dispatch = useDispatch();
  const { drivers } = useSelector((state) => state.drivers);



  return (

      <Row>
        {drivers &&
          drivers.length > 0 &&
          drivers.map((data, index) => (
            <Col className="pb-3 d-flex justify-content-center" key={index}>
              <Link to="/listdimesionbydrivers">
                <Button
                  className="butonCard"
                  onClick={() => {
                    dispatch(setIndexDriverSelected(index));
                  }}
                >
                  <h4 style={{ color: "white" }}>{data.driverName}</h4>
                </Button>
              </Link>
            </Col>
          ))}
      </Row>
  );
};

export default DriversCardView;
