import { React } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../store/slices/modal/modalSlice";
import { deleteDimension } from "../../../store/slices/drivers/driverThunks";
const DeleteDimensionModal = () => {
  const dispatch = useDispatch();


  const { drivers, index,indexDimension } = useSelector((state) => state.drivers);

  const deleteCredentials = () => {
    let body ={
        dimensionId: drivers[index].dimensionId[indexDimension]
    }
    dispatch(deleteDimension(body))
    dispatch(closeModal());
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Delete Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      Are you sure  want to change the status of {drivers[index].driverName} driver  ?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Close
        </Button>
        {/* <Link to="/listdimesionbydrivers"> */}
          <Button variant="primary" onClick={() => deleteCredentials()}>
            Apply Changes
          </Button>
        {/* </Link> */}
      </Modal.Footer>
    </>
  );
};

export default DeleteDimensionModal;
