import { fetchBackend } from "./fetch";



export const getDrivers = async(body) => {
    const resp = await fetchBackend('drivers/getdriverswithdimensions', body ,'POST')
    const respJson =  resp.json()
    return respJson
}


export const setNewDriverToDb = async(body) => {
    const resp = await fetchBackend('drivers/setnewdriver', body ,'POST')
    const respJson =  resp.json()
    return respJson
}


export const setNewDimensionToDb = async(body) => {
    const resp = await fetchBackend('drivers/setnewdimension', body ,'POST')
    const respJson =  resp.json()
    return respJson
}

export const updateDimensionToDb = async(body) => {
    const resp = await fetchBackend('drivers/updatedimension', body ,'POST')
    const respJson =  resp.json()
    return respJson
}


export const deleteDriverInDb = async(body) => {
    const resp = await fetchBackend('drivers/deletedriver', body ,'POST')
    const respJson =  resp.json()
    return respJson
}


export const deleteDimensionInDb = async(body) => {
    const resp = await fetchBackend('drivers/deletedimension', body ,'POST')
    const respJson =  resp.json()
    return respJson
}