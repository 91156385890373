import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Card from "../../components/Card";
import "../../styles/drivers.css";
import { useSelector, useDispatch } from "react-redux";
import { List, ModeEdit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import { setIndexDriverSelected,setEditDriver,updateDriversWithDimension } from "../../store/slices/drivers/driverSlice";
import { getDriversToComponent } from "../../store/slices/drivers/driverThunks";

const DriversListView = () => {
  const dispatch = useDispatch();
  const { drivers,editDriver,driverState } = useSelector((state) => state.drivers);
  const { cardUsageAccount } = useSelector((state) => state.costing);
  const { rolConfig } = useSelector((state) => state.roles);
  const  {user} = useSelector( (state) => state.profile );
  const [isListView, setisListView] = useState(false);

  // Creamos un nuevo array llamado `driversWithAccounts`
  const [driversWithAccounts, setDriversWithAccounts] = useState([]);
  // Utilizamos useEffect para combinar la información de los arrays
 
 
  useEffect(()=> {
    user.length > 0 && dispatch(getDriversToComponent({tenantid: user[0].idtenant}))

  },[driverState])



  useEffect(() => {
    dispatch(setEditDriver(false))
    // Verificamos que ambos arrays tengan datos
    if (Array.isArray(drivers) && Array.isArray(cardUsageAccount)) {
      // Mapeamos los drivers existentes
      const updatedDrivers = drivers.map((driver) => {
        // Obtenemos los IDs de las cuentas relacionadas
        const relatedAccountIds = driver.relatedAccounts;
        // Filtramos las cuentas relacionadas que coincidan con los IDs
        const relatedAccounts = cardUsageAccount.filter((account) =>
          relatedAccountIds.includes(account.accountId)
        );

        // Retornamos el driver actualizado con las cuentas relacionadas
        return { ...driver, relatedAccounts };
      });

      // Actualizamos el estado con los drivers actualizados
      setDriversWithAccounts(updatedDrivers);
    }
  }, [drivers, cardUsageAccount]);

    const handleEditDriver = (index) => {
        dispatch(setIndexDriverSelected(index))
        dispatch(setEditDriver(true))
    }
  

  return (
    <Row>
      <Col sm="12">
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <div className="header-title"></div>
          </Card.Header>
          <Card.Body className="p-0">
            <div className="table-responsive mt-4">
              <table
                id="basic-table"
                className="table table-striped mb-0"
                role="grid"
              >
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Description</th>
                    <th>Related Accounts</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {driversWithAccounts.map((data, index) => (
                    <tr key={index}>
                      <td>{data.driverCode}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <h6>{data.driverName}</h6>
                        </div>
                      </td>
                      <td>
                        {data.relatedAccounts.map((account) => (
                          <div key={account.accountId}>
                            {account.accountName}
                          </div>
                        ))}
                      </td>
                      <td>
                      { user && rolConfig?.VIEWS?.DRIVERS?.DRIVERS_LIST.EDIT_DRIVER.includes(user[0]?.iduserrole) && (<Tooltip title="Edit" position="top" trigger="mouseenter">
                            <Link to="/newdriver" className="m-1">
                              <Button className="m-1"  onClick={() => {
                                handleEditDriver(index)
                                }}>
                                <ModeEdit />
                              </Button>
                            </Link>
                        </Tooltip>)
}
                        <Tooltip title="Member List" position="top" trigger="mouseenter">
                            <Link to="/listdimesionbydrivers" className="m-1">
                              <Button
                                className="m-1"
                                variant="success"
                                onClick={() => {
                                  dispatch(setIndexDriverSelected(index));
                                }}
                              >
                                <List />
                              </Button>
                            </Link>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default DriversListView;
