import { fetchBackend } from "./fetch";

//Request
//funcion para obtener todos los usuarios de la base de datos
const addUserInDb = async (body) => {
  const resp = await fetchBackend("users/adduser", body, "POST");
  if (resp.status === 204 || resp.status === 500)
    return {
      status: resp.status,
      data: null,
    };
  else {
    const respJson = await resp.json();
    return {
      status: resp.status,
      data: respJson,
      message: respJson.message,
    };
  }
};
//funcion para verificar si exite el usuario en la base de datos
const verifyUserInDb = async (body) => {
  const resp = await fetchBackend("users/verifyuser", body, "POST");
  if (resp.status === 204 || resp.status === 500)
    return {
      status: resp.status,
      data: null,
    };
  else {
    const respJson = await resp.json();
    return {
      status: resp.status,
      data: respJson,
    };
  }
};
//funcion para obtener todos los usuarios de la base de datos
const getAllusers = async (body) => {
  const resp = await fetchBackend("users/getusers", body, "POST");
  if (resp.status === 409 || resp.status === 500)
    return {
      status: resp.status,
      data: null,
    };
  else {
    const respJson = await resp.json();
    return {
      status: resp.status,
      data: respJson,
    };
  }
};

//funcion para editar los datos del usuario en la base de datos
const updateUserData = async (body) => {
  const resp = await fetchBackend("users/edituser", body, "POST");
  if (resp.status === 409 || resp.status === 500)
    return {
      status: resp.status,
      data: null,
    };
  else {
    const respJson = await resp.json();
    return {
      status: resp.status,
      data: respJson,
    };
  }
};

const statusUser = async (body) => {
  const resp = await fetchBackend("users/userstatus", body, "POST");
  if (resp.status === 409 || resp.status === 500)
    return {
      status: resp.status,
      data: null,
    };
  else {
    const respJson = await resp.json();
    return {
      status: resp.status,
      data: respJson,
    };
  }
};



// ----Request directas a Auth0----
// funcion para registrar un usuario en auth0 desde el userAdd.jsx
const registerUserWithEmailAndPassword = async (data) => {
  const URL = process.env.REACT_APP_AUTH0_SIGNUP_URL;
  const method = "POST";
  const auth0NewUser = {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    email: data.email.toLowerCase(),
    password: data.password,
    connection: "Username-Password-Authentication",
    username: `${data.name}  ${data.lastName}`,
    given_name: data.name,
    family_name: data.lastName,
    name:`${data.name}  ${data.lastName}`,
    nickname: data.lastName + data.name,
    user_metadata: {
      position: data.position,     
      company: data.company,
    },
  };
  try {
    const resp = await fetch(URL, {
      method,
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(auth0NewUser),
    });
    const jsonResp = await resp.json();
    if (!resp.ok) {
      return { jsonResp, ok: resp.ok };
    } else {
      console.log("with sucessfully");
      return {
        jsonResp,
        ok: resp.ok,
        email: data.email,
        name: `${data.name} ${data.lastName}`,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      errorMessage: error.message,
    };
  }
};
// funcion para resetear la contraseña del usuario en auth0
const resetPassword = async (email) => {
  const URL = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`;
  const method = "POST";
  const passwordData = {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    email: email,
    connection: "Username-Password-Authentication",
  };

  try {
    const resp = await fetch(URL, {
      method,
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(passwordData),
    });
    const jsonResp = await resp.json();
    return jsonResp;
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      errorMessage: error.message,
    };
  }
};


const  validate = (input,isFormSubmitted)=> { 
  let errors = {};
  if (isFormSubmitted) { 
if(input.name === ''){
    errors.name = 'Your name is required...'
}if (input.lastName === ''){
    errors.lastName = 'Your last name is required...'
} if (input.company === ''){
    errors.company = 'Your company name is required...'
} if (input.mobile === ''){
  errors.mobile = 'Your mobile number is required...'
} if (input.position === ''){
  errors.position = 'Your position is required...'
} if (input.country === ''){
  errors.country = 'Your country is required...'
}  if(input.city === ''){
errors.city = 'Your city is requried...'
}
}
return errors;
}

const validateAllInputs=(errors,input)=>{
  if (
     Object.keys(errors).length === 0 && 
     Object.values(input).every((value) => value.trim() !== "") 
   ) {
     return true
   } else {
     return false

   }
}





//Array del Selected Country
const countries = ["Albania","Algeria","Andorra","Angola","Argentina","Armenia","Australia","Austria","Azerbaijan","Bahrain","Bangladesh","Belarus","Belgium","Bolivia","Bosnia and Herzegovina","Brazil","Bulgaria","Cambodia","Cameroon","Canada","Chile","China","Colombia","Costa Rica","Croatia","Cyprus","Czech Republic","Denmark","Dominican Republic","Ecuador","Egypt","El Salvador","Estonia","Ethiopia","Finland","France","Georgia","Germany","Greece","Guatemala","Honduras","Hong Kong","Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland","Israel","Italy","Jamaica","Japan","Jordan","Kazakhstan","Kenya","Kuwait","Latvia","Lebanon","Libya","Lithuania","Luxembourg","Macedonia","Malaysia","Malta","Mexico","Morocco","Nepal","Netherlands","New Zealand","Nigeria","Norway","Oman","Pakistan","Panama","Paraguay","Peru","Philippines","Poland","Portugal","Qatar","Romania","Russia","Saudi Arabia","Serbia","Singapore","Slovakia","Slovenia","South Africa","South Korea","Spain","Sri Lanka","Sudan","Sweden","Switzerland","Taiwan","Tanzania","Thailand","Tunisia","Turkey","Uganda","Ukraine","United Arab Emirates","United Kingdom","United States","Uruguay","Uzbekistan","Venezuela","Vietnam","Yemen", "Zimbabwe"];


export {
  registerUserWithEmailAndPassword,
  addUserInDb,
  getAllusers,
  verifyUserInDb,
  statusUser,
  updateUserData,
  resetPassword,
  validate,
  validateAllInputs,
  countries,
};
