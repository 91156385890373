const apiBaseUrl =  process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;



const fetchBackend = ( endpoint, data, method = 'GET' ) => {


    const url = `${ apiBaseUrl }/${ endpoint }`;


    

    if ( method === 'GET' ) {
        return fetch( url,{
            headers: {
                'Content-type': 'application/json',
                'Authorization': apiKey, 
            } });
    } else {
        return fetch( url, {
            method,
            headers: {
                "Content-type": "application/json",
                "Authorization": apiKey, 
            },
            body: JSON.stringify( data )
        });
    }
}



export {
    fetchBackend,
    apiBaseUrl
}

