import React from 'react'
import {Row,Col,Form,Button} from 'react-bootstrap'
import Card from '../../Card'
import { useState,useEffect } from 'react'
import { useSelector,useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react'
import { editUserData,activeEditForm } from '../../../store/slices/users/usersThunks';
import { Link } from 'react-router-dom';
import { countries } from '../../../helpers/users';
import { errorMessage } from '../../../store/slices/users/usersSlices';
import ToastUsers from '../toasts/ToastUsers';

const UserEditForm =({ selectedUser }) =>{
   const dispatch = useDispatch();
   const { idTenant} = useSelector( (state) => state.costing);
   const  {usersState,errorUserMessage} = useSelector( (state) => state.users );
   const [countriesarray, setCountriesArray] = useState([]);
   const [isFormSubmitted, setIsFormSubmitted] = useState(false);
   const [errors,setErrors]= useState({})
   const [errorsPass, setErrorsPass] = useState({
      invalidPassword: false
   });
   const [users,setUser] = useState({
      tenantId:"",
      creationUser:"",
      name:"",
      lastName:"",
      country:"",
      city:"",
      contact:"",
      company:"",
      userDepartment:"",
      idUser:""
   })
   const [input,setInput]= useState({
      password:"",
      name:"",
      lastName:"",
      company:"",
      mobile:"",
      position:"",
      country:"",
      city:"",
    })
    useEffect(() => {
        if (selectedUser) {
          setInput({
            name: selectedUser.name,
            lastName: selectedUser.lastname,
            company: selectedUser.company,
            mobile: selectedUser.contact,
            position:selectedUser.userdepartment,
            country: selectedUser.country,
            city: selectedUser.city,
          });
        }
      }, [selectedUser]);

    useEffect(() => {
      if (selectedUser){
         setUser({
            name: input.name,
            lastName: input.lastName,
            country: input.country,
            contact: input.mobile,
            company: input.company,
            city:input.city,
            userDepartment:input.position,
            idUser: selectedUser.iduser,
            creationUser:selectedUser.creationuser
          });
      }else{
         dispatch(activeEditForm(false))
      }
  
    }, [input]);
    
    useEffect(() => {
      if (isFormSubmitted) {
        setErrors(validate(input));
      }
    }, [input, isFormSubmitted]);

 
    
    const  validate = (input)=> { 
      let errors = {};
      if (isFormSubmitted) { 
    if(input.name === ''){
        errors.name = 'Your name is required...'
    }if (input.lastName === ''){
        errors.lastName = 'Your last name is required...'
    } if (input.company === ''){
          errors.company = 'Your company name is required...'
   }
   //   if (input.mobile === ''){
   //    errors.mobile = 'Your mobile number is required...'
   //  } if (input.position === ''){
   //    errors.position = 'Your position is required...'
   //  }
     if (input.country === ''){
      errors.country = 'Your country is required...'
    }  if(input.city === ''){
    errors.city = 'Your city is requried...'
    }
    }
    return errors;
    }
    
 
    const validateAllInputs = () => {
      if (
        Object.keys(errors).length === 0 &&
        Object.entries(input).every(([key, value]) =>
          key === 'mobile' || key === 'position' || (value !== null && value.trim() !== "")
        )
      ) {
        return true;
      } else {
        return false;
      }
    };
  

  
   const handleChange = (e) => {
     setInput({
       ...input,
       [e.target.id]: e.target.value,
     });
     setErrors(
       validate({
         ...input,
         [e.target.id]: e.target.value,
       })
     );
   };

      const editFormView = () => {
        dispatch(activeEditForm(false));
      }; 

   const handleSubmit = (e) => {
      e.preventDefault();
      setIsFormSubmitted(true);
      const validationErrors = validate(input);
      setErrors(validationErrors);     
      if(validateAllInputs()){
          setUser({
        name: input.name,
        lastName: input.lastName,
        country: input.country,
        contact: input.mobile,
        company: input.company
      });
      dispatch(editUserData({
         tenantId: idTenant,
         creationUser: users.creationUser,
         name: users.name,
         lastName: users.lastName,
         country: users.country,
         city: users.city,
         contact: users.contact,
         company: users.company,
         userDepartment:users.userDepartment,
         userDescription:"",
         userProfile:"",
         idUser:users.idUser
       },input))
      }else{
         dispatch(errorMessage({error:true, message:"Please fill in all the required fields"}));
      }
   
    };
  return(
      <>
     
        <div>
            <Row>
               <Col >
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">User Edit Form</h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                        <div className="new-user-info">
                           <form>
                              <div  className="row">
                              <Form.Label className="col-md-8 form-group" htmlFor="alert">                           
                              Fields with * are required
                               </Form.Label>   
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="fname"   style={{color:'black'}}>* First Name:</Form.Label>
                                    <Form.Control type="text"  value={input.name} onChange={(e) =>handleChange(e)}  id="name" 
                                 //style={errors.name && isFormSubmitted ? { borderColor: 'red'} : { borderColor: '#899AF1'} } 
                                 style={   input.name
                                    ? { borderColor: "#899AF1", background: "#E8F0FE", color: "black" }
                                    : errors.name && isFormSubmitted
                                    ? { borderColor: "red" } 
                                    : { borderColor: "#899AF1"}}
                                     maxLength={30}
                                     placeholder={  errors.name && isFormSubmitted ? errors.name:"First Name"}
                                     
                                     />
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="lname"   style={{color:'black'}}>* Last Name:</Form.Label>
                                    <Form.Control type="text"  value={input.lastName} onChange={(e) =>handleChange(e)}  id="lastName" 
                                     style={ input.lastName
                                       ? { borderColor: "#899AF1",background: "#E8F0FE", color: "black" }
                                       : errors.lastName && isFormSubmitted
                                       ? { borderColor: "red" } 
                                       :  { borderColor: "#899AF1"} }
                                     maxLength={30} 
                                     placeholder={  errors.lastName && isFormSubmitted ? errors.lastName:"Last Name"}/>
                                 </Form.Group>
                                
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="cname"   style={{color:'black'}} >* Company Name:</Form.Label>
                                    <Form.Control type="text"   value={input.company} onChange={(e) =>handleChange(e)}  id="company" 
                                    style={ input.company
                                       ? { borderColor: "#899AF1", background: "#E8F0FE", color: "black" }
                                       : errors.company && isFormSubmitted
                                       ? { borderColor: "red" } 
                                       :  { borderColor: "#899AF1"}}
                                    maxLength={30} 
                                    placeholder={  errors.company && isFormSubmitted ? errors.company:"Company"}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="pno"   style={{color:'black'}} >Position:</Form.Label>
                                    <Form.Control type="text"   value={input.position} onChange={(e) =>handleChange(e)}  id="position" 
                                    style={input.position
                                       ? { borderColor: "#899AF1", background: "#E8F0FE", color: "black" }
                                       : errors.position && isFormSubmitted
                                       ? { borderColor: "red" } 
                                       :  { borderColor: "#899AF1"}}
                                    maxLength={30} 
                                    placeholder={  errors.position && isFormSubmitted  ? errors.position:"Position"}
                                    />                               
                                 </Form.Group>                                            
                                 <Form.Group className="col-sm-6 form-group">
                                    <Form.Label  style={{color:'black'}} >* Country:</Form.Label>
                                    <select id='country' onChange={(e) =>handleChange(e)} className="selectpicker form-control" 
                                    style={input.country
                                       ? { borderColor: "#899AF1", background: "#E8F0FE", color: "black" }
                                       : errors.country && isFormSubmitted
                                       ? { borderColor: "red" } 
                                       :  { borderColor: "#899AF1"}} 
                                    maxLength={30}  
                                    data-style="py-0"
                                    defaultValue={selectedUser ? selectedUser.country : ''}
                                    >
                                       <option disabled={true}>Select Country</option>
                              { countriesarray > 1 ? 
                                  (<option>Loading countries....</option>):(countries.map((item,idx)=> {
                                    return (    <option key={idx} value={item}>{item}</option>)
                                        }) ) 

                              }                                                           
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="city"   style={{color:'black'}} >* City:</Form.Label>
                                    <Form.Control type="text"  value={input.city} onChange={(e) =>handleChange(e)}  id="city" 
                                  style={input.city
                                    ? { borderColor: "#899AF1", background: "#E8F0FE", color: "black" }
                                    : errors.city && isFormSubmitted
                                    ? { borderColor: "red" } 
                                    :  { borderColor: "#899AF1"}}
                                  maxLength={30} 
                                  placeholder={  errors.city && isFormSubmitted ? errors.city:"City"}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6  form-group">
                                    <Form.Label htmlFor="mobno"   style={{color:'black'}} >Mobile Number:</Form.Label>
                                    <Form.Control   value={input.mobile} onChange={(e) =>handleChange(e)}  id="mobile" 
                                     type="number"
                                   maxLength={30}
                                    style={input.mobile
                                       ? {  borderColor: "#899AF1",background: "#E8F0FE", color: "black" }
                                       : errors.mobile && isFormSubmitted
                                       ? { borderColor: "red" } 
                                       :  { borderColor: "#899AF1"} } 
                                    placeholder={  errors.mobile ? errors.mobile:"Mobile"}/>
                                 </Form.Group>                                                                                                
                              </div>
                              <hr/>
                          
                              <div className="row">
                                    
                                         
                              <div className="checkbox">   

                              {errorsPass.invalidPassword && (
                                  <Form.Label htmlFor="alert"
                                  maxLength={30}
                                  style={  errorsPass.invalidPassword === 'Password accepted'  ? { color: 'blue'}:{ color: 'red'} } 
                                  >{errorsPass.invalidPassword}</Form.Label>
  )}                          </div>
                             {
                                  usersState === 'failed' ?        
                                       (  <div>
                                          <Form.Label htmlFor="alert"
                                             style={  { color: 'red'} } 
                                             >{errorUserMessage}

                                             </Form.Label>
                                       </div>) : (<div></div>)
                              }
                               {
                                   usersState === 'processing' ?        
                                   (  <div>
                                      <Form.Label htmlFor="alert"
                                         style={  { color: 'blue'} } 
                                         >Processing request...</Form.Label>
                                   </div>) : (<div></div>)
                               }  
                                 {
                                   usersState === 'ready' ?        
                                   (  <div>
                                      <Form.Label htmlFor="alert"
                                         style={  { color: 'green'} } 
                                         >
                                         {errorUserMessage}</Form.Label>
                                   </div>) : (<div></div>)
                               }              
                              </div>
                              <div className="d-flex justify-content-end">                          
                              <Button type="submit" variant="btn btn-primary mx-4"   
                              onClick={(e)=>{handleSubmit(e)}}>Update User                             
                              </Button>
                                 <Button  onClick={()=> editFormView()} variant="btn btn-danger mx-4"  >Cancel   </Button>
                              </div>                                                          
                           </form>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
      </>
  )
}
export default UserEditForm;

