import React, { useRef,useEffect } from "react";
import { Form, Button} from "react-bootstrap";
import Card from "../../../components/Card";
import '../../../styles/containerPrincipal.css' 
import { useSelector,useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from "react-router-dom";
import { updateDimension } from "../../../store/slices/drivers/driverThunks";


const DimensionEditForm = () => {
    const user = useAuth0();

    const dispatch = useDispatch();

    const { idTenant } = useSelector((state) => state.costing);

    const { drivers, index,indexDimension } = useSelector((state) => state.drivers);
console.log('driverindex',index)
    const dimensionNameRef = useRef(drivers[index].dimensionName[indexDimension]);

    const dimensionCodeRef = useRef(drivers[index].dimensionCode[indexDimension]);

    const weightRef = useRef(0);


    //Estilos para el formulario 
    const styleLabel = {color:'black'}
    const styleInput = {borderColor: '#899AF1', color: '#000'}
    const handleSubmit=()=>{
        const body={
            tenantid:idTenant,
            dimensionCode:dimensionCodeRef.current.value,
            dimensionName: dimensionNameRef.current.value,
            idDimension:drivers[index].dimensionId[indexDimension],
            weight:weightRef.current.value || 0 ,
            user: user.user.email
          }
      
      dispatch(updateDimension(body))
    }
  
    //espera en caso de que no se hayan llegado  cargar los  datos
    if (!drivers[index]) {
        return null; 
      }


      

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">Edit {drivers[index].driverName} Member</h4>
        </div>
      </Card.Header>
      <Card.Body>
        {/* <p>Create new dimension for {drivers[index].driverName}</p> */}
        <Form>
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Member Code </Form.Label>
            <Form.Control
              type="text"
              id="inputNameDimension"
              ref={dimensionCodeRef}
              defaultValue={drivers[index].dimensionCode[indexDimension]}
              placeholder="Enter Code"
              style={styleInput}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Member Name </Form.Label>
            <Form.Control
              type="text"
              id="inputNameDimension"
              ref={dimensionNameRef}
              defaultValue={drivers[index].dimensionName[indexDimension]}
              placeholder="Enter Name"
              style={styleInput}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="inputWeight">Weight Distribution </Form.Label>
            <Form.Control
              type="number"
              id="inputWeight"
              ref={weightRef}
              defaultValue={drivers[index].weight[indexDimension]}
              placeholder="Weight %"
              style={styleInput}
              max={100}
            />
          </Form.Group>
          
       
          <Link to='/listdimesionbydrivers'>
            <Button type="button" className="m-2" onClick={()=>{handleSubmit()}} variant="btn btn-primary" >

              Submit
            </Button>
          </Link>
          <Link to='/listdimesionbydrivers' > 
          <Button type="button" variant="btn btn-danger">
            cancel
          </Button>
          </Link>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default DimensionEditForm;
