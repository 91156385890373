import React, { useRef,useState,useEffect } from "react";
import { Form, Button} from "react-bootstrap";
import Card from "../../../components/Card";
import '../../../styles/containerPrincipal.css' 
import { useSelector,useDispatch } from "react-redux";
import Select from "react-select";
import { useAuth0 } from '@auth0/auth0-react'
import { setNewDriver } from "../../../store/slices/drivers/driverThunks";
import Loader from '../../../layouts/Loader'
import { Link } from "react-router-dom";


const DriverEditForm = () => {


    const driverNameRef = useRef("");

    const driverCodeRef = useRef("");

    const user = useAuth0();

    const dispatch = useDispatch();


    //Estilos para el formulario 
    const styleLabel = {color:'black'}
    const styleInput = {borderColor: '#899AF1', color: '#000'}

    const [selectedOptions, setSelectedOptions] = useState();



    const {  cardUsageAccount,idTenant } = useSelector(
        (state) => state.costing
      );

    const {  driverState,drivers,index } = useSelector(
      (state) => state.drivers
    );
    

    let dataToSelect=[]
    
    cardUsageAccount.map((data)=>{

        let obj = {
          value: data.accountId,
          label: data.accountName,
        };
        dataToSelect.push(obj);
    })

    function handleSelect(data) {
      setSelectedOptions(data);
    }

    
    const handleSubmit=()=>{
      let accounts=[]
      selectedOptions.map((data)=>(
        accounts.push(data.value)
      ))

      
      const body={
        tenantid:idTenant,
        driverCode:driverCodeRef.current.value,
        driverName: driverNameRef.current.value,
        accounts:accounts,
        user: user.user.email,
        editDriver:1,
        driverId:drivers[index].idDriver,
      }
      
      dispatch(setNewDriver(body))
    }
  

    const filteredAccounts = dataToSelect.filter(account => {
        return drivers[index].relatedAccounts.includes(account.value);
      });
    
    useEffect(() => {
        setSelectedOptions(filteredAccounts)
    }, [])
    

    if(driverState === 'processing'){
        return <Loader center={true}/>
      }
    
  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">Edit Driver</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Driver Code </Form.Label>
            <Form.Control
              type="text"
              id="inputNameDriver"
              ref={driverCodeRef}
              maxLength={50}
              placeholder="Enter Code"
              defaultValue={drivers[index].driverCode}
              style={styleInput}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Driver Name </Form.Label>
            <Form.Control
              type="text"
              id="inputNameDriver"
              ref={driverNameRef}
              placeholder="Enter Name"
              maxLength={100}
              defaultValue={drivers[index].driverName}
              style={styleInput}
            />
          </Form.Group>
          <Select  
            options={dataToSelect}
            placeholder="Select Account"
            value={selectedOptions}
            styles={styleInput}
            onChange={handleSelect}
            isSearchable={true}
            isMulti
          />
         
            <Link to="/drivers" className="m-1">
                <Button type="button" className="m-2" onClick={()=>{handleSubmit()}} variant="btn btn-primary" >    
                    Submit
                </Button>
            </Link>
            <Link to="/drivers" className="m-1">
                <Button type="button" variant="btn btn-danger">
                    Cancel
                </Button>
            </Link>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default DriverEditForm;
