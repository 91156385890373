import { React, useState,useEffect } from "react";
import { Modal, Button,Form,Tabs, Tab, } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector, useDispatch } from "react-redux";
import { closeModal,openModal } from "../../../store/slices/modal/modalSlice";
import Select from "react-select";
import { getusers } from "../../../store/slices/users/usersThunks";
import Loader from '../../../layouts/Loader'
import {getEmailsForRecipientsTable,addRecipientsToReport,addExternalRecipientsToReport} from '../../../store/slices/reports/reportsThunks'
import {sendDataToModal } from '../../../store/slices/reports/reportsSlices'
import ToastReport from "../toasts/ToastReport";
import '../../../styles/selectRecipients.css'
const SelectRecipients = () => {

    const dispatch = useDispatch();
    const  {user} = useSelector( (state) => state.profile );
    const { idTenant } = useSelector( (state) => state.costing );
const {emailsUsers,reportState,updateTable} = useSelector( (state) => state.reports );
const  {users} = useSelector( (state) => state.users );

    const [addTenant, setAddTenant] = useState(false);

    const [allUsersSub, setAllUsersSub] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState();
    const [key, setKey] = useState('home');


    const [messageError,setMessageError] = useState(false);
    const [input, setInput] = useState({
      userEmail:'',
      name:''
    });


    const userAuth = useAuth0();
    const adminEmail = userAuth.user.email
    const [selectedOption, setSelectedOption] = useState("default");


    const styleLabel = {color:'black'}
    const styleInput = {borderColor: '#899AF1', color: '#000'}
    const usersEmail = []
    
 
// Filtrar los usuarios que no están en emailUsers
const filteredUsers = users.filter((user) => {
    // Verificar si el ID del usuario no está en emailUsers
    return !emailsUsers.some((emailUser) => emailUser.iduser === user.iduser);
  });


  // Mapear y agregar los usuarios filtrados a usersEmail
  filteredUsers.forEach((data) => {
    let obj = {
      value: data.iduser,
      label: `${data.name} ${data.lastname}`,
    };
    usersEmail.push(obj);
  });

  useEffect(()=> {
      dispatch(getusers({ tenantId: idTenant }))
      dispatch(getEmailsForRecipientsTable(idTenant))
      
  },[])

  useEffect(()=> {      
    dispatch(getEmailsForRecipientsTable(idTenant))
  },[updateTable])
 
  
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.id]: e.target.value,
    });
  
  };
  
  

function handleSelectSubscriptionUser(data) {
  setAllUsersSub(false)
  setSelectedUsers(data);
  dispatch(getEmailsForRecipientsTable(idTenant))

}
const removeUserFromReport =(body) => {
    dispatch(sendDataToModal(body))
    dispatch(closeModal())
    dispatch(openModal("RecipientsModals"))
    //  dispatch(getEmailsForRecipientsTable(idTenant))
    
}
const addUserFromReport = () => {
    selectedUsers.map((el) => {
    dispatch(addRecipientsToReport({idTenant:idTenant,userId:el.value}))
    setSelectedUsers([])
    dispatch(getEmailsForRecipientsTable(idTenant))

    })
}
const submitNewExternalUserTenant = () => {
    // dispatch(addTenantInDb(input))
    if(input.name&&input.userEmail){
        //--- // modify
     dispatch(addExternalRecipientsToReport({idTenant:idTenant,userEmail:input.userEmail,name:input.name}))
     setAddTenant(false)
    }else{
        setMessageError(true)
     setTimeout(() => {
       setMessageError(false)
     }, 3000);
 }
}

 
  return ( <>
    <Modal.Header closeButton>
      <Modal.Title>Report recipients</Modal.Title>
    </Modal.Header>
        
<>
<ToastReport/>

    <Modal.Body >
    <div className="custom-tabs-container" style={{backgroundColor:'#F2F2F2'}}>
          <Tabs
      defaultActiveKey="home"
      id="justify-tab-example"
      className="mb-3"
      justify
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
  
          <h5>Add email to send report </h5>

      <Tab eventKey="home" title="Add  recipients">
      
      <Form.Group className="form-group"  style={{ paddingTop: '8px', color:'black',paddingBottom:'80px' }}>
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Name : </Form.Label>
            <Select  
            options={usersEmail}
            placeholder="Select users for send report email"
            value={selectedUsers}
            style={{
              ...styleInput,
              backgroundColor: allUsersSub ? styleInput.backgroundColor : '#899AF1'
            }}
            onChange={handleSelectSubscriptionUser}
            isSearchable={true}
            isMulti
          />

{/* <Button  style={{ marginTop: '10px'}}  variant="primary" onClick={() => addUserFromReport()}>
       Add User
        </Button>    */}
          </Form.Group> 
         
      </Tab>
      <Tab eventKey="profile" title="Add external recipient">
     
      {/* <p style={{}}>Add external recipient</p> */}
         
         <Form>
       <Form.Group controlId="userEmail" style={{ paddingTop: '8px', color:'black' }}>
         <Form.Label>Email:</Form.Label>
         <Form.Control
           type="text"
           value={input.userEmail}
           onChange={(e) => handleChange(e)}
           placeholder="Name for the new tenant"
           style={input.userEmail? { borderColor: "#899AF1",color:'black' }:{borderColor: "#899AF1"}}
         />
       </Form.Group>
       <Form.Group controlId="name" style={{ paddingTop: '8px', color:'black' }}>
         <Form.Label>Name and last name:</Form.Label>
         <Form.Control
           type="text"
           value={input.name}
           onChange={(e) => handleChange(e)}
           placeholder="External user name"
           style={input.name? { borderColor: "#899AF1",color:'black' }:{borderColor: "#899AF1"}}
         />
       </Form.Group>
      {reportState !== 'ready' ?  <Form.Label style={{paddingTop:'5px',color:'black',fontWeight:'bold'}}>Loading...</Form.Label> : null}
     </Form>
     {/* <Button style={{ marginTop: '10px'}}  variant="primary"  onClick={() => submitNewExternalUserTenant()}>
       Add External user
       </Button> */}
      
      </Tab>
     
    
    </Tabs>
    </div>

         {addTenant ? (<Form>
        <Form.Group controlId="userEmail" style={{ paddingTop: '8px', color:'black' }}>
          <Form.Label>Email user:</Form.Label>
          <Form.Control
            type="text"
            value={input.userEmail}
            onChange={(e) => handleChange(e)}
            placeholder="Name for the new tenant"
            style={input.userEmail? { borderColor: "#899AF1",color:'black' }:{borderColor: "#899AF1"}}
          />
        </Form.Group>
        <Form.Group controlId="name" style={{ paddingTop: '8px', color:'black' }}>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            value={input.name}
            onChange={(e) => handleChange(e)}
            placeholder="External user name"
            style={input.name? { borderColor: "#899AF1",color:'black' }:{borderColor: "#899AF1"}}
          />
        </Form.Group>
       {reportState !== 'ready' ?  <Form.Label style={{paddingTop:'5px',color:'red',fontWeight:'bold'}}>Loading...</Form.Label> : null}
      </Form>) : (   <Form.Group className="form-group"  style={{ paddingTop: '8px', color:'black' }}>
            {/* <Form.Label style={styleLabel} htmlFor="exampleInputText1">Add cloudcosting recipients : </Form.Label>
            <Select  
            options={usersEmail}
            placeholder="Select users for send report email"
            value={selectedUsers}
            style={{
              ...styleInput,
              backgroundColor: allUsersSub ? styleInput.backgroundColor : '#899AF1'
            }}
            onChange={handleSelectSubscriptionUser}
            isSearchable={true}
            isMulti
          />

<Button  style={{ marginTop: '10px'}}  variant="primary" onClick={() => addUserFromReport()}>
       Add User
        </Button>    */}
          </Form.Group> 
          
          )}
         
        </Modal.Body>
        <Modal.Body style={{ color: 'black' }}>
          <h5 style={{ paddingBottom: '15px' }}>Selected recipients:</h5>
          { updateTable === 'ready'? (
              <table className="superadmin-table" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #899AF1', borderRadius: '3px',fontSize:'15px' }}>
            <thead style={{ backgroundColor: '#f2f2f2' }}>
              <tr>
                <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }}>Name</th>
                <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }}>Email</th>
                <th style={{ padding: '10px', textAlign: 'left', borderBottom: '1px solid #ccc' }}>Actions</th>
              </tr>
            </thead>
    <tbody>
              {emailsUsers && emailsUsers.map((el) => (
                <tr key={el.iduser} style={{ borderBottom: '1px solid #ccc' }}>
                  <td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>{el.name}</td>                                 
                  <td style={{ padding: '5px', borderBottom: '1px solid #ccc' }}>{el.userEmail}</td> 
                  <td>
                   <span className="btn btn-sm btn-icon btn-danger"style={{marginLeft:'20px'}}    onClick={() => {
                                                removeUserFromReport({idTenant:idTenant,userId:el.iduser,userEmail:el.userEmail});
                                               }}>
                                      <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                         <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                         <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                         <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                      </svg>
                                   </span>
                                   </td>
                </tr>
              ))}
            </tbody>
          </table>) : ( <div style={{height:'50%',width:'30%'}}>  <Modal.Body style={{ color: 'black' }}>Loading...</Modal.Body></div> )}
        </Modal.Body>
      </> 
      

    <Modal.Footer>
         <Button variant="danger" onClick={() => dispatch(closeModal())}>
        Close
      </Button> 
    
     {  
       key === 'home' ? <Button variant="primary"  onClick={() =>    addUserFromReport()
       }>
       Submit
        </Button> : <Button variant="primary" onClick={() =>     submitNewExternalUserTenant()
}>
        Submit
        </Button>} 
    
    </Modal.Footer>
  </>
);
};
export default SelectRecipients;