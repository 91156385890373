import { createSlice } from '@reduxjs/toolkit'




const initialState = {
    errorState: false,
    noDataState: false,
    messageCustom: false,
    messageToShow: '',


  }
  
  export const toastSlice = createSlice({
      name: 'toast',
      initialState,
      reducers: {
          changeStateToast: (state, {payload}) => {
              state.errorState = payload.state;
          },
          noDataToast: (state, {payload}) => {
            state.noDataState = payload.state;
            },
         changeStateMessageCustom: (state, {payload}) => {
            state.messageCustom = payload.state;
         },
          setMessageToShow: (state, {payload}) => {
            state.messageToShow = payload;
          },
          
      },
  })
  
  // Action creators are generated for each case reducer function
  export const { changeStateToast,noDataToast,changeStateMessageCustom,setMessageToShow } = toastSlice.actions