import {React, useEffect,useState} from 'react'
import { Row,Col,Dropdown,Button,Alert,Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {bindActionCreators} from "redux"
import { useSelector,useDispatch } from 'react-redux'
import { openModal,closeModal } from '../../store/slices/modal/modalSlice'
import { useAuth0 } from '@auth0/auth0-react'

//circular
// AOS
import AOS from 'aos'
import '../../../node_modules/aos/dist/aos'
import '../../../node_modules/aos/dist/aos.css'
//apexcharts
import Chart from "react-apexcharts";
//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.scss';
//Count-up
import CountUp from 'react-countup';
// store
import {NavbarstyleAction, getDirMode, getcustomizerMode, getcustomizerprimaryMode,  getcustomizerinfoMode,  SchemeDirAction, ColorCustomizerAction,  getNavbarStyleMode, getSidebarActiveMode, SidebarActiveStyleAction, getDarkMode, ModeAction,  SidebarColorAction, getSidebarColorMode, getSidebarTypeMode} from '../../store/setting/setting'
import {connect} from "react-redux"
import { getDataforDashbords,updateDataforDashbords } from '../../store/slices/costing/costingThunks.js'
const moment = require('moment');





  
// install Swiper modules
SwiperCore.use([Navigation]);

const mapStateToProps = (state) => {
    return {
        darkMode: getDarkMode(state),
        customizerMode: getcustomizerMode(state),
        cololrinfomode: getcustomizerinfoMode(state),
        colorprimarymode: getcustomizerprimaryMode(state),
        schemeDirMode: getDirMode(state),
        sidebarcolorMode: getSidebarColorMode(state),
        sidebarTypeMode: getSidebarTypeMode(state),
        sidebaractivestyleMode: getSidebarActiveMode(state),
        navbarstylemode: getNavbarStyleMode(state),
    };
}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            ModeAction,
            SchemeDirAction,
            SidebarColorAction,
            SidebarActiveStyleAction,
            NavbarstyleAction,
            ColorCustomizerAction,
        },
        dispatch
    )
})

let videoUno;
let videoDos;
let videoTres; // 

const Home = () => {


  videoUno = 'https://www.youtube.com/embed/50ZlQOTMM50'
  videoDos = 'https://www.youtube.com/embed/3JXCwN5GhpI'
  videoTres = 'https://www.youtube.com/embed/zjcW4OqB5Yw'
  



  
  
  
    return (
     
        <div className='card-header' >
            <div className="row d-flex pb-5 w-100 justify-content-center">
              <div className='col-6 '>
                <h5 className=' mt-4'>How do the boards work? </h5>
                <iframe
                  className='mt-2 tutorials-iframe'
                  width="100%" height="315"
                  src={ videoUno }
                  title={('howToCreateListsTitle')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                ></iframe>
              </div>
              
              <div className='col-6'>
                <h5 className='text-lightblue mt-4'>Connect a new account</h5>
                <iframe
                  className='mt-2 tutorials-iframe'
                  width="100%" height="315"
                  src={ videoDos }
                  title={('howToSendAndScheduleTitle')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                ></iframe>
              </div>
  
              <div className='col-6 '>
                <h5 className='text-lightblue mt-4'>How does the distribution table work?</h5>
                <iframe
                  className='mt-2 tutorials-iframe'
                  width="100%" height="315"
                  src={ videoTres }
                  title={('howToCreateTemplatesTitle')}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                ></iframe>
              </div>
            </div>
  
        </div>
    )
  };

export default connect(mapStateToProps, mapDispatchToProps)(Home)
