import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   allTenants:[],
   allSuperAd:[],
   requestStatus:'',
   userSelected:{},
   rolConfig:{}
  }

  export const rolesSlices = createSlice({
  name: 'roles',
      initialState,
      reducers: {
        configJsonRol: (state,{payload})=>{
            state.rolConfig = payload
        },
        processingRequest: (state)=>{
          state.requestStatus = 'processing'
      },
      readyRequest: (state)=>{
          state.requestStatus = 'ready'
      },
      allTenants: (state,{payload})=>{
            state.allTenants = payload
        },
        allSuperAdmins: (state,{payload})=>{
            state.allSuperAd = payload
        },
        userSelected: (state,{payload})=>{
            state.userSelected = payload
        }

  }})


  export const {readyRequest,processingRequest,allTenants,userSelected,configJsonRol,allSuperAdmins} = rolesSlices.actions;