import { useDispatch } from "react-redux";
import {getAdvisorRecommends,getDatesForAdvisor} from "../../../helpers/advisor";
import { getAllCards,setStateAdvisor,allMonths} from "./advisorSlice";



export const getCardsByAccountId = (user,accountId,cloud,tenantId,lastMonth) => {
    return async( dispatch ) => {
        dispatch(setStateAdvisor('processing'))
        const respJson = await getAdvisorRecommends({idAccount:accountId,user:user,idTenant:tenantId,cloud:cloud,lastMonth:lastMonth})
        // console.log('entra? thunks')
        // console.log('respJson',respJson)
        dispatch(getAllCards(respJson))
        dispatch(setStateAdvisor('ready'))

    }
}

export const getDatesAndMonth = () => {
    return async( dispatch ) => {
        dispatch(setStateAdvisor('processing'))
        const respJson = await getDatesForAdvisor()
        dispatch(allMonths(respJson))
        dispatch(setStateAdvisor('ready'))

    }
}
