import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    contracts: [],
    contractsStatus:'procesing',
    selectedContract:{},
    platformsAndRegion:{},
    idCurrentAccount:{},
    contractsVerifyResult:{},
    editForm: false,
    addContract:false,
    showAddModal:false
 
}

export const contractsSlice= createSlice({
    name: 'contracts',
    initialState,
    reducers: {
        saveAllContracts: (state,{payload})=> {
            state.contracts = payload;
        },
        processingRequest: (state) => {
            state.initialStatus = 'processing';
        },
        readyRequest: (state) => {
            state.contractsStatus = 'ready';
        },
        setCurrentIdAccount: (state,{payload}) => {
          
            state.idCurrentAccount = payload;
        },
        setStatusFormEdit: (state,{payload}) => {
            state.editForm = payload;
        },
        setSelectedContract: (state,{payload}) => {
            state.selectedContract = payload;
        },
        handleContractAdd:(state,{payload})=> {
            state.addContract = payload
            console.log(state.addContract)
        },
        setShowAddModal:(state,{payload})=> {
            state.showAddModal = payload
           
            },
        addVerifyContractsResults:(state,{payload})=> {
            state.contractsVerifyResult = payload
        },
        setRegionsAndPlatforms:(state,{payload}) => {
            state.platformsAndRegion = payload
        }
    }
})


export const {saveAllContracts,processingRequest,readyRequest,setCurrentIdAccount,setStatusFormEdit,setSelectedContract,handleContractAdd,setShowAddModal,addVerifyContractsResults,setRegionsAndPlatforms} = contractsSlice.actions
