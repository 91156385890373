import { React } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../store/slices/modal/modalSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteNotification } from "../../../store/slices/notifications/notificationThunks";
const DeleteNotificationModal = () => {
  const dispatch = useDispatch();
  const user = useAuth0();


  const { idNotification } = useSelector((state) => state.notifications);
  const { idTenant } = useSelector((state) => state.costing);



  const handleDelete = () => {
    let body ={
        idNotification: idNotification ,
        tenantid:idTenant,
        user:user.user.email
    }
    //aca llamo a lafuncion del thunks que se encarga de elimar la notificacion de la bd, en realidad hace una baja logica 
    dispatch(deleteNotification(body))
    dispatch(closeModal());
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Delete Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this notification ?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Close
        </Button>
        <Link to="/notificationsettings">
          <Button variant="danger" onClick={()=>{handleDelete()}}>
            Delete
          </Button>
        </Link>
      </Modal.Footer>
    </>
  );
};

export default DeleteNotificationModal;
