//componente donde puedo mostrar un toast con un mensaje que va a llegar como un prop desde el componente padre
//el toast se va a mostrar por 5 segundos y luego se va a ocultar

import React, { Component } from 'react';
import {toast , Toaster} from 'react-hot-toast';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDataSavedSuccesFalse } from '../../../store/slices/costing/costingSlice';
import { setStateAccountSavedSuccessfully } from '../../../store/slices/accounts/accountSlice';
import { changeStateToast,noDataToast,changeStateMessageCustom } from '../../../store/slices/toasts/toastSlice';


const ToastCco = (message) => {

    const dispatch = useDispatch();

    const { dataSavedSucces } = useSelector((state) => state.costing);
    const { accountSavedSuccessfully } = useSelector((state) => state.accounts);
    const { errorState,noDataState,messageToShow,messageCustom } = useSelector((state) => state.toast);

    const styleToastSuccess = {
        border: '1px solid #FFFFFF',
        padding: '16px',
        color: '#FFFFFF',
        backgroundColor: '#6991f5',
      };
    
    const duration = 1500;

    useEffect(() => {
        if (dataSavedSucces) {
          toast.success('Data Saved',{
            duration : duration,
            style: styleToastSuccess,
        });
          dispatch(setDataSavedSuccesFalse())
        }
      }, [dataSavedSucces]);

      useEffect(() => {
        if (accountSavedSuccessfully) {
          toast.success('Account Saved',{
            duration: duration,
            style: styleToastSuccess,
        });
        dispatch(setStateAccountSavedSuccessfully({state: false}))
      }
     } ,[accountSavedSuccessfully])

     useEffect(() => {
      if (errorState) {
        toast.error('Error',{
          duration: duration,
          style: styleToastSuccess,
      });
      dispatch(changeStateToast({state: false}))
    }
   } ,[errorState])


   useEffect(() => {
    if (noDataState) {
      toast.error('No Data',{
        duration: duration,
        style: styleToastSuccess,
    });
    dispatch(noDataToast({state: false}))
  }
 } ,[errorState])


 useEffect(() => {
  if (messageCustom) {
    toast.error(messageToShow,{
      duration: duration,
      style: styleToastSuccess,
  });
  dispatch(changeStateMessageCustom({state: false}))
}
} ,[messageCustom])







    return (
        <div>
            <Toaster/>
        </div>
    );
}

export default ToastCco;