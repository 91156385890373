import React from 'react'
import {Row,Col,Form,Button,Image} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Card from '../../Card'
import { useState,useEffect } from 'react'
import { useSelector,useDispatch } from "react-redux";


import { editUserData} from '../../../store/slices/users/usersThunks';
import { countries } from '../../../helpers/users';
import { activeProfileForm } from '../../../store/slices/profile/profileThunks';
import { errorMessage } from '../../../store/slices/users/usersSlices'


import womanavatar0 from '../../../assets/images/avatars/womanavatar0.png'
import womanavatar1 from '../../../assets/images/avatars/womanavatar1.png'
import womanavatar2 from '../../../assets/images/avatars/womanavatar2.png'
import womanavatar3 from '../../../assets/images/avatars/womanavatar3.png'
import womanavatar4 from '../../../assets/images/avatars/womanavatar4.png'
import profilemen from '../../../assets/images/avatars/profilemen.png'
import avatars11 from '../../../assets/images/avatars/01.png'
import avatars22 from '../../../assets/images/avatars/avtar_1.png'
import avatars33 from '../../../assets/images/avatars/avtar_2.png'
import avatars44 from '../../../assets/images/avatars/avtar_3.png'
import avatars55 from '../../../assets/images/avatars/avtar_4.png'
import avatars66 from '../../../assets/images/avatars/avtar_5.png'
import ToastUsers from '../toasts/ToastUsers'

const imageNames = [
    avatars11,
    avatars22,
    avatars33,
    avatars44,
    avatars55,
    avatars66,
    womanavatar0,
    womanavatar1,
    womanavatar2,
    profilemen,
    womanavatar3,
    womanavatar4
  ];
const ProfileEditForm =({ selectedUser }) =>{
  const dispatch = useDispatch();
  const { idTenant } = useSelector((state) => state.costing);
  const { usersState, errorUserMessage } = useSelector((state) => state.users);
  const [countriesarray, setCountriesArray] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [avatars, setAvatar] = useState("");
  const [statusImgProfile, setStatusImgProfile] = useState(false);
  const [errorsPass, setErrorsPass] = useState({
    invalidPassword: false,
  });
  const description = 'Enter text about your work experience, skills, and interests. This will help other users to know you better.'
  const [users, setUser] = useState({
    tenantId: "",
    creationUser: "",
    name: "",
    lastName: "",
    country: "",
    city: "",
    contact: "",
    company: "",
    userDepartment: "",
    userDescription: "",
    userProfile: "",
    idUser: "",
  });
  const [input, setInput] = useState({
    password: "",
    name: "",
    lastName: "",
    company: "",
    mobile: "",
    position: "",
    country: "",
    city: "",
    userProfile: "",
    userDescription: "",
  });
  //userDescription
  useEffect(() => {
    if (selectedUser) {
      setAvatar(selectedUser.userprofile);
      setInput({
        name: selectedUser.name,
        lastName: selectedUser.lastname,
        company: selectedUser.company,
        mobile: selectedUser.contact,
        position: selectedUser.userdepartment,
        country: selectedUser.country,
        city: selectedUser.city,
        userDescription: selectedUser.userdescription,
        userProfile: selectedUser.userprofile,
      });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedUser) {
      setUser({
        name: input.name,
        lastName: input.lastName,
        country: input.country,
        contact: input.mobile,
        company: input.company,
        city: input.city,
        userDepartment: input.position,
        idUser: selectedUser.iduser,
        creationUser: selectedUser.creationuser,
      });
    } else {
      dispatch(activeProfileForm(false));
    }
  }, [input]);

  //funcion para obtener los paises de la api de paises
  useEffect(() => {
    if (isFormSubmitted) {
      setErrors(validate(input));
    }
  }, [input, isFormSubmitted]);

  const validate = (input) => {
    let errors = {};
    if (isFormSubmitted) {
      if (input.name === "") {
        errors.name = "Your name is required...";
      }
      if (input.lastName === "") {
        errors.lastName = "Your last name is required...";
      }
      if (input.company === "") {
        errors.company = "Your company name is required...";
      }
      // if (input.mobile === "") {
      //   errors.mobile = "Your mobile number is required...";
      // }
      // if (input.position === "") {
      //   errors.position = "Your position is required...";
      // }
      if (input.country === "") {
        errors.country = "Your country is required...";
      }
      if (input.city === "") {
        errors.city = "Your city is requried...";
      }
    }
    return errors;
  };

  const validateAllInputs = () => {
    
    if (
      Object.keys(errors).length === 0 &&
      Object.entries(input).every(([key, value]) =>
        key === 'mobile' || key === 'position' ||key === 'userDescription' ||(value !== null && value.trim() !== "")
      )
    ) {
      return true;
    } else {
      return false;
    }
  };









  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.id]: e.target.value,
    });
    setErrors(
      validate({
        ...input,
        [e.target.id]: e.target.value,
      })
    );
  };

  const editFormView = () => {
    dispatch(activeProfileForm(false));
  };

  const selectedAvatar = (avatar) => {
    setInput((prevInput) => ({
      ...prevInput,
      userProfile: avatar,
    }));
    setAvatar(avatar);

    setStatusImgProfile(false);
  };

  const openMenuimgProfile = () => {
    setStatusImgProfile(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);
    const validationErrors = validate(input);
    setErrors(validationErrors);
    if (validateAllInputs()) {
      setUser({
        name: input.name,
        lastName: input.lastName,
        country: input.country,
        contact: input.mobile,
        company: input.company,
      });
      dispatch(
        editUserData(
          {
            tenantId: idTenant,
            creationUser: users.creationUser,
            name: users.name,
            lastName: users.lastName,
            country: users.country,
            city: users.city,
            contact: users.contact,
            company: users.company,
            userDepartment: users.userDepartment,
            userDescription: input.userDescription,
            userProfile: input.userProfile,
            idUser: users.idUser,
          },
          input
        )
      );
      dispatch(activeProfileForm(false));
    } else {
      dispatch(errorMessage({error:true, message:"Please fill in all the required fields"}));
    }
  };

  return (
    <>
    <ToastUsers/>
      <div>
        <Row>
          <Col>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Edit your profile</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <Form.Label className="col-md-8 form-group" htmlFor="alert">
                  * Edit the field you need to change the information
                </Form.Label>
                <div className="new-user-info">
                  <form>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group d-flex flex-column align-items-center">
                        <Form.Label htmlFor="rpass" style={{ color: "black" }}>
                          Change your profile image
                        </Form.Label>

                        {statusImgProfile ? (
                          <div>
                            {imageNames.map((image, index) => (
                              <Image
                                key={index}
                                src={image}
                                onClick={() => selectedAvatar(image)}
                                alt={`Image ${index + 1}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  marginRight: "10px",
                                }}
                              />
                            ))}
                          </div>
                        ) : (
                          <div>
                            {avatars === "" ? (
                              <Image
                                className="theme-color-default-img rounded-pill avatar-130 img-fluid"
                                src={avatars11}
                                alt="profile-pic"
                              />
                            ) : (
                              <Image
                                className="theme-color-default-img rounded-pill avatar-130 img-fluid"
                                src={avatars}
                                alt="profile-pic"
                              />
                            )}
                            <Link
                              className="btn btn-sm btn-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="edit user form"
                              data-original-title="Edit"
                              to="#"
                            >
                              <button
                                className="btn-inner"
                                onClick={() => openMenuimgProfile()}
                                style={{
                                  backgroundColor: "white",
                                  marginTop: "70px",
                                  border: "none",
                                  color: "#899AF1",
                                  fontSize: "24px",
                                }}
                              >
                                <svg
                                  width="40"
                                  height="40"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M15.1655 4.60254L19.7315 9.16854"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </button>
                            </Link>
                          </div>
                        )}
                      </Form.Group>

                      {/* Renderizar el componente <Form.Control> */}
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="pass" style={{ color: "black" }}>
                          Description
                        </Form.Label>
                        <Form.Control
                          maxLength={300}
                          type="password"
                          value={input.userDescription}
                          onChange={(e) => handleChange(e)}
                          id="userDescription"
                          as="textarea"
                          rows={6}
                          style={input.userDescription === description ? {background: "#fff",borderColor: "#899AF1",color:"#AAB0BE" }: { borderColor: "#899AF1",background: "#E8F0FE", color: "black" }}
                          placeholder="Enter text about your work!"
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="fname" style={{ color: "black" }}>
                        *  First Name:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.name}
                          onChange={(e) => handleChange(e)}
                          id="name"
                          style={
                            input.name
                                    ? { borderColor: "#899AF1",background: "#E8F0FE", color: "black" }
                                    : errors.name && isFormSubmitted
                                    ? { borderColor: "red" } 
                                    : null
                          }
                          maxLength={30}
                          placeholder={
                            errors.name && isFormSubmitted
                              ? errors.name
                              : "First Name"
                          }
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="lname" style={{ color: "black" }}>
                        * Last Name:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.lastName}
                          onChange={(e) => handleChange(e)}
                          id="lastName"
                          style={
                            input.lastName
                            ? { borderColor: "#899AF1",background: "#E8F0FE", color: "black" }
                            : errors.lastName && isFormSubmitted
                            ? { borderColor: "red" } 
                            : null
                          }
                          maxLength={30}
                          placeholder={
                            errors.lastName && isFormSubmitted
                              ? errors.lastName
                              : "Last Name"
                          }
                        />
                      </Form.Group>

                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="cname" style={{ color: "black" }}>
                        * Company Name:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.company}
                          onChange={(e) => handleChange(e)}
                          id="company"
                          style={
                            input.company
                            ? { borderColor: "#899AF1",background: "#E8F0FE", color: "black" }
                            : errors.company && isFormSubmitted
                            ? { borderColor: "red" } 
                            : null
                          }
                          maxLength={30}
                          placeholder={
                            errors.company && isFormSubmitted
                              ? errors.company
                              : "Company"
                          }
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="pno" style={{ color: "black" }}>
                          Position:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.position}
                          onChange={(e) => handleChange(e)}
                          id="position"
                          style={
                            input.position
                            ? { borderColor: "#899AF1",background: "#E8F0FE", color: "black" }
                            : errors.position && isFormSubmitted
                            ? { borderColor: "red" } 
                            : { borderColor: "#899AF1"}
                          }
                          maxLength={30}
                          placeholder={
                            errors.position && isFormSubmitted
                              ? errors.position
                              : "Position"
                          }
                        />
                      </Form.Group>
                      <Form.Group className="col-sm-6 form-group">
                        <Form.Label style={{color:'black'}}>* Country:</Form.Label>
                        <select
                          id="country"
                          onChange={(e) => handleChange(e)}
                          className="selectpicker form-control"
                          style={
                            input.country
                            ? { borderColor: "#899AF1",background: "#E8F0FE", color: "black" }
                            : errors.country && isFormSubmitted
                            ? { borderColor: "red" } 
                            : null
                          }
                          data-style="py-0"
                          defaultValue={
                            selectedUser ? selectedUser.country : ""
                          }
                        >
                          <option disabled={true}>Select Country</option>
                          {countriesarray > 1 ? (
                            <option>Loading countries....</option>
                          ) : (
                            countries.map((item, idx) => {
                              return (
                                <option key={idx} value={item}>
                                  {item}
                                </option>
                              );
                            })
                          )}
                        </select>
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="city" style={{ color: "black" }}>
                        * City:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.city}
                          onChange={(e) => handleChange(e)}
                          id="city"
                          style={
                            input.city
                            ? { borderColor: "#899AF1",background: "#E8F0FE", color: "black" }
                            : errors.city && isFormSubmitted
                            ? { borderColor: "red" } 
                            : null
                          }
                          maxLength={30}
                          placeholder={
                            errors.city && isFormSubmitted
                              ? errors.city
                              : "City"
                          }
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6  form-group">
                        <Form.Label htmlFor="mobno" style={{ color: "black" }}>
                          Mobile Number:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.mobile}
                          onChange={(e) => handleChange(e)}
                          id="mobile"
                          style={
                            input.mobile
                            ? { borderColor: "#899AF1",background: "#E8F0FE", color: "black" }
                            : errors.mobile && isFormSubmitted
                            ? { borderColor: "red" } 
                            :  { borderColor: "#899AF1"}
                          }
                          placeholder={errors.mobile ? errors.mobile : "Mobile"}
                        />
                      </Form.Group>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="row"></div>
                      <div className="checkbox">
                        {errorsPass.invalidPassword && (
                          <Form.Label
                            htmlFor="alert"
                            maxLength={30}
                            style={
                              errorsPass.invalidPassword === "Password accepted"
                                ? { color: "blue" }
                                : { color: "red" }
                            }
                          >
                            {errorsPass.invalidPassword}
                          </Form.Label>
                        )}{" "}
                      </div>
                      {usersState === "failed" ? (
                        <div>
                          <Form.Label htmlFor="alert" style={{ color: "red" }}>
                            {errorUserMessage}
                          </Form.Label>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {usersState === "processing" ? (
                        <div>
                          <Form.Label htmlFor="alert" style={{ color: "blue" }}>
                            Processing request...
                          </Form.Label>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {usersState === "ready" ? (
                        <div>
                          <Form.Label
                            htmlFor="alert"
                            style={{ color: "green" }}
                          >
                            {errorUserMessage}
                          </Form.Label>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        type="submit"
                        variant="btn btn-primary mx-4"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Update Profile
                      </Button>
                      <Button
                        onClick={() => editFormView()}
                        variant="btn btn-danger mx-4"
                      >
                        Cancel{" "}
                      </Button>
                    </div>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default ProfileEditForm;

