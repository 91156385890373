import { React } from "react";
import { Link } from "react-router-dom";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { updateEditAccount } from "../../../store/slices/accounts/accountSlice";
const EditButtonCard = ({ index }) => {
  const dispatch = useDispatch();
  const saveIndexCredential = (idCredential) => {
    dispatch(updateEditAccount({ index: idCredential, edit: true}));
  };

  const renderTooltipEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit
    </Tooltip>
  );


  return (
    <div className="d-flex">
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltipEdit}
      >
        <div className="px-3">
          <Link to="/newaccounts" onClick={() => saveIndexCredential(index)}>
            <div id="dual-svg-container-44">
              <EditTwoToneIcon color="action"  sx={{ fontSize: 30 }}/>
            </div>
          </Link>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default EditButtonCard;
