import React, { useRef,useState,useEffect } from "react";
import { Form, Button,Spinner} from "react-bootstrap";
import '../../../../styles/containerPrincipal.css' 
import { useSelector,useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from "react-router-dom";
import { testSnowflakeConnection,setNewAWSCredentials } from "../../../../store/slices/accounts/accountThunks";
import {setCurrentIdAccount} from "../../../../store/slices/contracts/contractsSlices";
import { resetStateCredentials } from "../../../../store/slices/accounts/accountSlice";

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css'



const SnowflakeEditForm = () => {

    const [showPassword, setShowPassword] = useState(false);

    // const togglePasswordVisibility = () => {setShowPassword(!showPassword);};
    const user = useAuth0();
    const userFinal=user.user.email
    const dispatch = useDispatch();
    const { cardUsageAccount,idTenant } = useSelector((state) => state.costing);

    const { validCredentials,stateAccount,nameNewAccount,dataAccountToEdit,indexOfAccountToEdit } = useSelector((state) => state.accounts);

    const [editCredentials, setEditCredentials] = useState(false);

    const [diasbleButtonEdit, setDiasbleButtonEdit] = useState(false);



    const userSnowflakeRef = useRef('');
    const PasswordSnowflakeRef = useRef('');
    const urlAccountSnowflakeRef = useRef('');
    const warehouseSnowflakeRef = useRef('');


    //Estilos para el formulario 
    const styleLabel = {color:'black'}
    const styleInput = {borderColor: '#899AF1', color: '#000'}
    const botonSinFondo = { backgroundColor: 'transparent',border: 'none',padding: 0}


    const handleTestConnection=()=>{

      let user = userSnowflakeRef.current.value.trim();
      let password = PasswordSnowflakeRef.current.value.trim();
      let account = urlAccountSnowflakeRef.current.value.trim();
      let warehouse = warehouseSnowflakeRef.current.value.trim();

        const body = { user, password, account,warehouse }

        dispatch(testSnowflakeConnection(body))
    }
    

    const activateEditCredentials=()=>{
      setDiasbleButtonEdit(true)
      setEditCredentials(true)
    }

    useEffect(() => {
      if (validCredentials === true) {
        setEditCredentials(false)
      }
    }, [validCredentials]);
    
    

    const submitForm=()=>{
      let user = userSnowflakeRef.current.value.trim();
      let password = PasswordSnowflakeRef.current.value.trim();
      let account = urlAccountSnowflakeRef.current.value.trim();
      let warehouse = warehouseSnowflakeRef.current.value.trim();

        const body = {
            data: {
            cloudProvider: 'Snowflake',
            accountName: nameNewAccount,
            user,
            password,
            account,
            warehouse,},
            idtenant: idTenant,
            user: userFinal,
            update:1,
            idAccount:cardUsageAccount[indexOfAccountToEdit].accountId
          };
    
          const bodyJson = JSON.stringify(body);
          dispatch(setNewAWSCredentials(bodyJson));
    }

    const resetStateAccunt=()=>{
        dispatch(resetStateCredentials())
    }
  //Esta funcion toma los datos del estado costing y los uso en la parte de los contractos ------

    const setIdAccountForContract = () => {
      dispatch(setCurrentIdAccount({idAccount:cardUsageAccount[indexOfAccountToEdit].accountId,
        region:dataAccountToEdit.account,
      creationUser:userFinal}));
    }
  //----------------------------------------------------------------------------------------------
     return (
        <Form>
           <Button
              type="button"
              onClick={activateEditCredentials}
              variant="outline-secondary"
              className="m-2"
              disabled={diasbleButtonEdit}
              size="sm"
          >
              Edit Credentials
          </Button>

          <Link to='/contracts'>
          <Button 
          type="button"
          variant="outline-secondary"
          className="m-2"
          onClick={(e)=>setIdAccountForContract(e)}
          size="sm"
          >
            
            Contracts account
            </Button>
          </Link>
       

          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">User</Form.Label>
            <Form.Control
              type="text"
              ref={userSnowflakeRef}
              id="inputUser"
              disabled={!editCredentials}
              defaultValue={dataAccountToEdit.user}
              placeholder="Enter Snowflake User"
              style={styleInput}
            />
          </Form.Group>
          
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Password</Form.Label>
            <Button variant="light" style={botonSinFondo} className="ms-3" onClick={()=>{setShowPassword(!showPassword)}}> 
                {showPassword ? 
                    <VisibilityOutlinedIcon /> :
                    <VisibilityOffOutlinedIcon  />
                }
            </Button>

            <Form.Control
              type={showPassword ? 'text' : 'password'}
              ref={PasswordSnowflakeRef}
              id="inputPassword"
              defaultValue={dataAccountToEdit.password}
              disabled={!editCredentials}
              placeholder="Enter Password"
              style={styleInput}
            />
          
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">URL Account</Form.Label>
            <Form.Control
              type="text"
              ref={urlAccountSnowflakeRef}
              id="inputUrlAccount"
              defaultValue={dataAccountToEdit.account}
              disabled={!editCredentials}
              placeholder="Enter URL Account"
              style={styleInput}
            />
          </Form.Group>


          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="warehouseInput">Warehouse</Form.Label>
            <Tooltip title="This warehouse runs queries to retrieve your account's cost information." position="top" delay={500}>
              <InfoOutlinedIcon className="ms-2"/>
            </Tooltip>
            <Form.Control
              type="text"
              ref={warehouseSnowflakeRef}
              id="warehouseInput"
              disabled={!editCredentials}
              defaultValue={dataAccountToEdit.warehouse}
              placeholder="Enter a Warehouse"
              style={styleInput}
            />
          </Form.Group>

          

          {editCredentials ?  
          <>
            {validCredentials === false && (
              <Button
                  type="button"
                  onClick={handleTestConnection}
                  variant="outline-success"
                  className="m-2"
                >
                  {stateAccount === "ready" ? "Test connection" : "Loading..."}
                  {stateAccount !== "ready" && (
                    <Spinner
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
              </Button>
            )}
                {validCredentials === true && (
                  <Link to='/accounts'>
                      <Button type="button" className="m-2" disabled={nameNewAccount === ''} variant="btn btn-primary" onClick={submitForm} >
                      Submit
                      </Button>
                  </Link>
                )} 
          </>
          :
            <Link to='/accounts'>
              <Button type="button" className="m-2" disabled={nameNewAccount === ''} variant="btn btn-primary" onClick={submitForm} >
              Submit
              </Button>
            </Link>

          }
          <Link to='/accounts' > 
          <Button type="button" variant="btn btn-danger" onClick={()=>{resetStateAccunt()}}>
            cancel
          </Button>
          </Link>
        </Form>
      
  );
};

export default SnowflakeEditForm;
