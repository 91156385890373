import React, { useEffect, useRef, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import Card from "../../../Card";
import "../../../../styles/containerPrincipal.css";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { setNewNotification,listOfServices } from "../../../../store/slices/notifications/notificationThunks";
import { resetEditState } from "../../../../store/slices/notifications/notificationSlice";
import { Link } from "react-router-dom";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import DangerousTwoToneIcon from "@mui/icons-material/DangerousTwoTone";
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import { boolean } from "@inovua/reactdatagrid-community/filterTypes";
import { getusers } from "../../../../store/slices/users/usersThunks";



const EditNotificationForm = () => {
  const { cardUsageAccount, idTenant } = useSelector((state) => state.costing);
  const  {users} = useSelector( (state) => state.users );

  const {notificationToModify,accountServicesLists } = useSelector((state) => state.notifications);
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedUsers, setSelectedUsers] = useState();
  const [allUsersSub, setAllUsersSub] = useState(false);



  const [notificationPerService, setNotificationPerService] = useState(notificationToModify.serviceNotification === "true" ? true : false);
  const [selectedServices, setselectedServices] = useState("");
 


  const servicesForNotification = []

  accountServicesLists.map((data)=>{
    let obj = {
      value: data.idAccount,
      label: data.serviceName,
    };
    servicesForNotification.push(obj);
  })
  useEffect(()=> {
    dispatch(getusers({ tenantId: idTenant }))
    setSelectedOptions(filteredUsersEmail)
  setselectedServices(notificationToModify.serviceName)
    
    if(accountServicesLists.length ===0){
      dispatch(listOfServices(cardUsageAccount && cardUsageAccount[0].accountId,idTenant))
    }

  },[])
  
    function handleNotificationPerService(event) {
      if(event.target.checked){
        setNotificationPerService(true)
        
       // setselectedServices(data);
      }else{
        setNotificationPerService(false)
        setselectedServices("")
  
      //  setselectedServices([])
      }
    };
     
  function handleSelectedService(data){
    setselectedServices(data)
  }
    function dataForNotificationPerService (idAccount,idTenant) {
      dispatch(listOfServices(idAccount,idTenant))
    }
  






  let usersInNotification = []

  const filteredUsersEmail = notificationToModify.usersemail.map((el)=> {
    return  {
      value: el.id,
      label: `${el.name} ${el.lastname}`,
    };
  })










  const alertNameRef = useRef(notificationToModify.notificationName || "");
  const accountNameRef = useRef("");
  const thresHoldRef = useRef(0);
  const emailRef = useRef(false);
  const appMobileRef = useRef(false);
  const informationRef = useRef(0);
  const warningRef = useRef(0);
  const alertRef = useRef(0);

  //Estilos para el formulario 
  const styleLabel = {color:'black'}
  const styleInput = {borderColor: '#899AF1', color: '#000'}
  const user = useAuth0();
  const dispatch = useDispatch();


const usersEmail = []
 users.map((data)=>{
    let obj = {
      value: data.iduser,
      label: `${data.name} ${data.lastname}`,

    };
    usersEmail.push(obj);
 })




  let dataToSelect = [];

  cardUsageAccount.map((data) => {
    let obj = {
      value: data.accountId,
      label: `${data.name} ${data.lastname}`,

    };
    dataToSelect.push(obj);
  });


  const handleSubmit = () => {
    selectedOptions.map((data)=>{
      usersInNotification.push(data.value);
    })

    const body = {
      edit:true,
      idnotification: notificationToModify.idNotification,
      tenantid: idTenant,
      alertName: alertNameRef.current.value,
      accountid: notificationToModify.idAccount,
      consolidated: notificationToModify.consolidated,
      user: user.user.email,
      usersSubscriptions:{users:usersInNotification},
     details: {
      serviceNotification:notificationToModify.serviceNotification,
      serviceName: notificationToModify.serviceName ? notificationToModify.serviceName : "All Services",

         threshold: thresHoldRef.current.value,
         information: informationRef.current.value,
         warning: warningRef.current.value,
         alert: alertRef.current.value,
         sms: false,
         whatsapp:false,
         email: emailRef.current.checked,
        //  appmobile: appMobileRef.current.checked,
         appmobile: false,

        },
    };
    dispatch(setNewNotification(body));
    dispatch(resetEditState())

  };

  
  const [emptyField, setEmptyField] = useState(true);


  const handleChangeEmpty = () => {
    if(alertNameRef.current.value !==""  && thresHoldRef.current.value !=="" && informationRef.current.value !=="" && warningRef.current.value !=="" && alertRef.current.value !==""){
      setEmptyField(false)
    }else{
      setEmptyField(true)
    }
  };
  const handleCheckboxChange = (event) => {
    if(event.target.checked){
      setAllUsersSub(true)
      setSelectedOptions(usersEmail)
    }else{
      setAllUsersSub(false)
      setSelectedOptions([])
    }
  }
  function handleSelectSubscriptionUser(data) {
    setAllUsersSub(false)
    setSelectedOptions(data)
  }


  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">Edit Notification</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <p>Configure your notifications to be aware of your consumption</p>
        <Form>
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Notification Name </Form.Label>
            <Form.Control
              type="text"
              id="inputNameDriver"
              ref={alertNameRef}
              defaultValue={notificationToModify.notificationName}
              placeholder="Enter the name of the notification"
              style={styleInput}
              onChange={handleChangeEmpty}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Account Name </Form.Label>
            <Form.Control
              type="text"
              id="inputNameDriver"
              ref={accountNameRef}
              defaultValue={notificationToModify.consolidated ? 'Consolidated' : notificationToModify.accountName}
              disabled={true}
              style={styleInput}
            />
          </Form.Group>

          <Form.Group  className="form-group">

{notificationToModify.serviceNotification && (
  <>
    <Form.Label style={styleLabel} htmlFor="exampleInputText1">
      Service Notification
    </Form.Label>
    <Form.Control
              type="text"
              id="inputNameDriver"
              ref={accountNameRef}
              defaultValue={notificationToModify.serviceName ?  notificationToModify.serviceName : null}
              disabled={true}
              style={styleInput}
            />
  </>
)}

            

             
  </Form.Group>  



          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Users Subscriptions </Form.Label>
            <Select  
            options={usersEmail}
            placeholder="Select users for send notifications email"
            value={selectedOptions}
            styles={styleInput}
            onChange={handleSelectSubscriptionUser}
            isSearchable={true}
            isMulti
          />
          <Form.Check className="d-block form-group">
              <Form.Check.Input
                className="me-2"
                type="checkbox"
                defaultValue=""
                id="emailCheck"
                style={{marginTop:'19px'}}
                checked={allUsersSub} // Establece el estado del checkbox
                onChange={handleCheckboxChange} // Maneja el cambio del checkbox             
              />
              <Form.Check.Label style={{...styleLabel,marginTop:'15px'}} htmlFor="emailCheck">
              Send notification to all users

              </Form.Check.Label>
             
            </Form.Check>

                    </Form.Group>





          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">
              Alert Threshold ($){" "}
            </Form.Label>
            <Form.Control
              type="number"
              id="inputNameDriver"
              ref={thresHoldRef}
              defaultValue={notificationToModify.threshold}
              placeholder="Enter the amount"
              style={styleInput}
              onChange={handleChangeEmpty}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <InfoTwoToneIcon className="me-2" />
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">
              Information (%){" "}
            </Form.Label>
            <Form.Control
              type="number"
              id="inputNameDriver"
              defaultValue={notificationToModify.information}
              ref={informationRef}
              placeholder="Enter  percentage"
              style={styleInput}
              onChange={handleChangeEmpty}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <WarningTwoToneIcon className="me-2" />
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Warning (%) </Form.Label>
            <Form.Control
              type="number"
              id="inputNameDriver"
              defaultValue={notificationToModify.warning}
              ref={warningRef}
              placeholder="Enter  percentage"
              style={styleInput}
              onChange={handleChangeEmpty}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <DangerousTwoToneIcon className="me-2" />
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Alert (%) </Form.Label>
            <Form.Control
              type="number"
              id="inputNameDriver"
              defaultValue={notificationToModify.alert}
              ref={alertRef}
              placeholder="Enter  percentage"
              style={styleInput}
              onChange={handleChangeEmpty}
            />
          </Form.Group>


          <Form.Group className="form-group" >
            <p style={styleLabel} >Ways by which we can notify you of your consumption</p>
            <Form.Check className="d-block form-group">
              <Form.Check.Input
                className="me-2"
                type="checkbox"
                defaultValue=""
                id="flexCheckDefault"
                ref={emailRef}
                defaultChecked={notificationToModify.email === "true"}
              />
              <Form.Check.Label style={styleLabel} htmlFor="flexCheckDefault">
                Email
              </Form.Check.Label>
              <EmailTwoToneIcon className="ms-2"/>
            </Form.Check>


            {/* <Form.Check className="d-block form-group">
              <Form.Check.Input
                className="me-2"
                type="checkbox"
                defaultValue=""
                id="flexCheckChecked"
                ref={appMobileRef}
                defaultChecked={notificationToModify.appMobile === "true"}
              />
              <Form.Check.Label style={styleLabel} htmlFor="flexCheckChecked">
              Cloud Costing App
              </Form.Check.Label>
            </Form.Check> */}
           

          </Form.Group>

          <Link to={!emptyField &&  "/notificationsettings"} className="m-1">
            <Button
              type="button"
              className="m-2"
              onClick={() => {
                handleSubmit();
              }}
              variant="btn btn-primary"
              disabled={emptyField}
            >
              Submit
            </Button>
          </Link>
          <Link to="/notificationsettings" className="m-1">
            <Button type="button" variant="btn btn-danger">
              cancel
            </Button>
          </Link>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EditNotificationForm;
