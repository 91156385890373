import { fetchBackend } from "./fetch";



export const testAWSCredentialsToDB = async(body) =>{
    const resp = await fetchBackend('aws/testcredentials',body , 'POST')
    const respJson =  resp.json()
    return respJson
}

export const setAWSCredentialsToDB = async(body) =>{
    const resp = await fetchBackend('accounts/setnewcredentials',body , 'POST')
    const respJson =  resp.json()
    return respJson
}
export const setGcpCredentialsToDB = async(body) =>{
    const resp = await fetchBackend('gcp/setnewgcpaccount',body , 'POST')
    const respJson =  resp.json()
    return respJson
}

export const testSnowflakeCredentialsToDB = async(body) =>{
    const resp = await fetchBackend('snowflake/testcredentials',body , 'POST')
    const respJson =  resp.json()
    return respJson
}

export const testGcpCredentialsToDB = async(body) =>{
    const resp = await fetchBackend('gcp/testgcpcredentials',body , 'POST')
    const respJson =  resp.json()
    return respJson
}

export const testAzureCredentialsToDB = async(body) =>{
    const resp = await fetchBackend('azure/testcredentials',body , 'POST')
   return resp
}


export const getCredentialsFromAccountFromDB = async(body) =>{
    const resp = await fetchBackend('accounts/getcredentials',body , 'POST')
    const respJson =  resp.json()
    return respJson
}


export const updateDataDashbordsFetch = async(body) =>{
    const resp = await fetchBackend('accounts/setcostyearly',body, 'POST')
    const respJson =  resp.json()
    return respJson
}


export const getTopServiceByAccountDB = async(body) =>{
    const resp = await fetchBackend('accounts/gettopservicebyaccount',body, 'POST')
    if (resp.status === 204 || resp.status === 500)
    return {
        status: resp.status,
        data: []
    };
    else {
        const respJson = await resp.json();
        return {
            status: resp.status,
            data: respJson
    };
}
}


export const getDataForConsolidatedCardFromDB = async(body) =>{
    const resp = await fetchBackend('accounts/dataforconsolidatedcard',body , 'POST')
    return resp
}


export const setScheduleRefreshToDB = async(body) =>{
    const resp = await fetchBackend('accounts/setschedule',body , 'POST')
    return resp
}

export const getScheduleRefreshToDB = async(body) =>{
    const resp = await fetchBackend('accounts/getschedule',body , 'POST')
    return resp
}