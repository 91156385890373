import React, { useEffect, useState } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import Card from "../../components/Card";
import "../../styles/drivers.css";
import { useSelector, useDispatch } from "react-redux";
import DriversCardView from "./DriversCardView";
import DriversListView from "./DriversListView";
import { getDriversToComponent } from "../../store/slices/drivers/driverThunks";
import { setIndexDriverSelected } from "../../store/slices/drivers/driverSlice";
const Drivers = () => {
  const dispatch = useDispatch();
  const { idTenant } = useSelector((state) => state.costing);
  const [isListView, setisListView] = useState(true);

  const handleSwitchChange = () => {
    setisListView(!isListView);
  }

  useEffect(() => {
    dispatch(getDriversToComponent({ tenantid: idTenant }));
  }, []);

  return (
    <Container fluid className="card p-4">
      <div className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">
            Manage your drivers to distribute your consumption
          </h4>
        </div>

        <div className="form-check form-switch form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id="switch1"
            onChange={handleSwitchChange}
            checked={isListView}
          />
          <label className="form-check-label pl-2" htmlFor="switch1">
            List View
          </label>
        </div>
      </div>

      <p key={1}>Your drivers</p>
      {isListView ? <DriversListView /> : <DriversCardView />}

    </Container>
  );
};

export default Drivers;
