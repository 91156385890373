import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import Card from "../../Card";
import AccountEditForm from "./editFormsAcoounts/AccountEditForm";
import AccountAwsForm from "./AccountAwsForm";
import AccountSnowflakeForm from "./AccountSnowflakeForm";
import AccountAzureForm from "./AccountAzureForm";
import AccountGcpForm from "./AccountGcpForm";
import ValidCredentialsAlert from "../alerts/ValidCredentialAlert";
import { updateNameNewAccount } from "../../../store/slices/accounts/accountSlice";
const AccountForm = () => {
  const user = useAuth0();

  const [provider, setProvider] = useState('AWS');

  const dispatch = useDispatch();

  const { cardUsageAccount } = useSelector((state) => state.costing);
   
  const { validCredentials,editAccount } = useSelector((state) => state.accounts);

  //Estilos para el formulario 
  const styleLabel = {color:'black'}
  const styleInput = {borderColor: '#899AF1', color: '#000'}


  const providers = ['AWS', 'Azure', 'GCP', 'Snowflake']
  

  if (editAccount) {
    return <AccountEditForm/>
  }

return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <div className="header-title">
          <h4 className="card-title">New Credential</h4>
        </div>
      </Card.Header>
      <Card.Body>
        <p>Load your account data and credentials to access your consumption</p>
        <Form>
        <ValidCredentialsAlert/>    
        <Form.Group className="form-group">
            <Form.Label htmlFor="exampleInputText1" style={styleLabel}>Account Name</Form.Label>
            <Form.Control
              type="text"
              maxLength={25}
              onChange={()=>{dispatch(updateNameNewAccount(document.getElementById('inputAccountName').value))}}
              id="inputAccountName"
              placeholder="Enter Account Name"
              style={styleInput}
            />
          </Form.Group>


          <Form.Group className="form-group">
            <Form.Label  style={styleLabel}>Cloud Provider</Form.Label>
            <select style={styleInput} disabled={validCredentials} className="form-select mb-3 shadow-none" onChange={(e) => setProvider(e.target.value)}>
              {providers.map((prov,index) => (
                <option key={index} value={prov}>{prov}</option>
              ))}
            </select>
          </Form.Group>
        </Form>

        {(provider === 'AWS' && (<AccountAwsForm/>)) ||
        (provider === 'Snowflake' && (<AccountSnowflakeForm/>)) ||
        (provider === 'Azure' && (<AccountAzureForm/>)) ||
        (provider === 'GCP' && (<AccountGcpForm/>))
        }

      </Card.Body>
    </Card>
  );
};

export default AccountForm;
