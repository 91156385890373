import React, { useRef,useEffect,useState } from "react";
import { Form, Button,Spinner} from "react-bootstrap";
import '../../../../styles/containerPrincipal.css' 
import { useSelector,useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from "react-router-dom";
import { setNewGCPCredentials,testGcpConnection } from "../../../../store/slices/accounts/accountThunks";
import { resetStateCredentials } from "../../../../store/slices/accounts/accountSlice";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';


const GcpEditForm = () => {
    const user = useAuth0();

    const dispatch = useDispatch();
    const { cardUsageAccount } = useSelector((state) => state.costing);

    const { idTenant } = useSelector((state) => state.costing);

    const [showPassword, setShowPassword] = useState(false);

    const { validCredentials,stateAccount,nameNewAccount,dataAccountToEdit,indexOfAccountToEdit } = useSelector((state) => state.accounts);

    const [editCredentials, setEditCredentials] = useState(false);

    const [diasbleButtonEdit, setDiasbleButtonEdit] = useState(false);

    const botonSinFondo = { backgroundColor: 'transparent',border: 'none',padding: 0}
    
    //Estilos para el formulario 
    const styleLabel = {color:'black'}
    const styleInput = {borderColor: '#899AF1', color: '#000'}


    const bigQueryTableId = useRef('');
    const accountServiceCredentials = useRef('');

    const handleTestConnection=()=>{

        let bigQueryTable = bigQueryTableId.current.value.trim();
        let accountServiceCredentialsTest = accountServiceCredentials.current.value.trim();
  
        const credentialsObject = JSON.parse(accountServiceCredentialsTest);
  
          const body = { costtable:bigQueryTable,credentials:credentialsObject }
  
          dispatch(testGcpConnection(body))
      }
    
    const activateEditCredentials=()=>{
      setDiasbleButtonEdit(true)
      setEditCredentials(true)
    }

    useEffect(() => {
      if (validCredentials === true) {
        setEditCredentials(false)
      }
    }, [validCredentials]);
    
    

    const submitForm=()=>{
        let costtable = bigQueryTableId.current.value.trim();
        let credentials = JSON.parse(accountServiceCredentials.current.value)
        const body = {
            data: {
            cloudProvider: 'GCP',
            accountName: nameNewAccount,
            costtable,
            credentials},
            idtenant: idTenant,
            user: user.user.email,
            update:1,
            idAccount:cardUsageAccount[indexOfAccountToEdit].accountId
          };
    
          dispatch(setNewGCPCredentials(body));
          
    }

    const resetStateAccunt=()=>{
        dispatch(resetStateCredentials())
    }
    
     return (
        <Form>
            <Button
              type="button"
              onClick={activateEditCredentials}
              variant="outline-secondary"
              className="m-2"
              disabled={diasbleButtonEdit}
              size="sm"
          >
              Edit Credentials
          </Button>
        <Form.Group className="form-group">
          <Form.Label style={styleLabel} htmlFor="exampleInputText1">Service account credentials</Form.Label>
          <Form.Control
            type="text"
            ref={accountServiceCredentials}
            id="inputUser"
            defaultValue={JSON.stringify(dataAccountToEdit.credentials)}

            disabled={!editCredentials}
            placeholder="Enter account credentials"
            style={styleInput}
              as="textarea"
            rows={6}
          />
        </Form.Group>
        
        <Form.Group className="form-group">
          <Form.Label style={styleLabel} htmlFor="exampleInputText1">BigQuery table id</Form.Label>
          <Button variant="light" style={botonSinFondo} className="ms-3" onClick={()=>{setShowPassword(!showPassword)}}> 
              {showPassword ? 
                  <VisibilityOutlinedIcon /> :
                  <VisibilityOffOutlinedIcon  />
              }
          </Button>

          <Form.Control
            type={showPassword ? 'text' : 'password'}
            ref={bigQueryTableId}
            id="inputPassword"
            defaultValue={dataAccountToEdit.costtable}
            disabled={!editCredentials}
            placeholder="Enter table id"
            style={styleInput}
          />
        </Form.Group>




 

  
        

      {validCredentials === false && (
        <Button
            type="button"
            onClick={()=>{handleTestConnection()}}
            variant="outline-success"
            className="m-2"
          >
            {stateAccount === "ready" ? "Test connection" : "Loading..."}
            {stateAccount !== "ready" && (
              <Spinner
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
          )}
          {validCredentials === true && (
          <Link to='/accounts'>
              <Button type="button" className="m-2" disabled={nameNewAccount === ''} variant="btn btn-primary" onClick={()=>{submitForm()}} >
                Submit
              </Button>
           </Link>
          )}
        <Link to='/accounts' > 
        <Button type="button" variant="btn btn-danger" onClick={()=>{resetStateAccunt()}}>
          cancel
        </Button>
        </Link>
      </Form>
      
  );
};

export default GcpEditForm;
