import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";


import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css'


import { useSelector, useDispatch } from "react-redux";


import { bindActionCreators } from "redux";
//circular
import Circularprogressbar from "../../components/circularprogressbar.js";

import EditButtonCard from "../../components/cco/buttons/EditButtonCard.jsx";
import ConsolidatedCard from "../../components/cco/accounts/ConsolidatedCard.jsx";
import ToastCco from "../../components/cco/toasts/ToastCco.jsx";
// AOS
import AOS from "aos";
import "aos";
import "../../../node_modules/aos/dist/aos.css";
//apexcharts

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";

import Loader from "../Loader.jsx";

import { updateEditAccount,resetStateCredentials } from "../../store/slices/accounts/accountSlice.js";

//Count-up
import CountUp from "react-countup";
// store
import {
  NavbarstyleAction,
  getDirMode,
  getcustomizerMode,
  getcustomizerprimaryMode,
  getcustomizerinfoMode,
  SchemeDirAction,
  ColorCustomizerAction,
  getNavbarStyleMode,
  getSidebarActiveMode,
  SidebarActiveStyleAction,
  getDarkMode,
  ModeAction,
  SidebarColorAction,
  getSidebarColorMode,
  getSidebarTypeMode,
} from "../../store/setting/setting";
import { connect } from "react-redux";

// install Swiper modules
SwiperCore.use([Navigation]);

const mapStateToProps = (state) => {
  return {
    darkMode: getDarkMode(state),
    customizerMode: getcustomizerMode(state),
    cololrinfomode: getcustomizerinfoMode(state),
    colorprimarymode: getcustomizerprimaryMode(state),
    schemeDirMode: getDirMode(state),
    sidebarcolorMode: getSidebarColorMode(state),
    sidebarTypeMode: getSidebarTypeMode(state),
    sidebaractivestyleMode: getSidebarActiveMode(state),
    navbarstylemode: getNavbarStyleMode(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ModeAction,
      SchemeDirAction,
      SidebarColorAction,
      SidebarActiveStyleAction,
      NavbarstyleAction,
      ColorCustomizerAction,
    },
    dispatch
  ),
});



const Accounts = (props) => {
  const dispatch = useDispatch();



  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("en-US", { month: "long" });
  const currentYear = currentDate.getFullYear();
  const monthAndYear = currentMonth + " " + currentYear;

  const { cardUsageAccount,status } = useSelector((state) => state.costing);

  const { rolConfig } = useSelector((state) => state.roles);
  const  {user} = useSelector( (state) => state.profile );
  

  useEffect(() => {
    AOS.init({
      startEvent: "DOMContentLoaded",
      disable: function () {
        var maxWidth = 996;
        return window.innerWidth < maxWidth;
      },
      throttleDelay: 10,
      once: true,
      duration: 700,
      offset: 10,
    });

    //   customizer
    const colorcustomizerMode = sessionStorage.getItem("color-customizer-mode");
    const colorcustomizerinfoMode = sessionStorage.getItem(
      "colorcustominfo-mode"
    );
    const colorcustomizerprimaryMode = sessionStorage.getItem(
      "colorcustomprimary-mode"
    );
    if (colorcustomizerMode === null) {
      props.ColorCustomizerAction(
        props.customizerMode,
        props.cololrinfomode,
        props.colorprimarymode
      );
      document.documentElement.style.setProperty(
        "--bs-info",
        props.cololrinfomode
      );
    } else {
      props.ColorCustomizerAction(
        colorcustomizerMode,
        colorcustomizerinfoMode,
        colorcustomizerprimaryMode
      );
      document.documentElement.style.setProperty(
        "--bs-info",
        colorcustomizerinfoMode
      );
    }
  }, []);

//con esto actualizo el estado de edit a false cuando se renderiza el componente para que en caso de entrar en new account no se quede en true y entre como para editar una cuenta
  useEffect(() => {
      dispatch(updateEditAccount({edit:false}))
      dispatch(resetStateCredentials())
  }, [])


  const dateFormated = (date) => {
    if(date){
      const [year, month] = date.split('-');
      const dateObj = new Date(year, month - 1);
      const formattedDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' }).format(dateObj);
      return formattedDate;
  }else{
      return 'No data'
  }
  }
 

  if(status === 'processing'){
    return <Loader center={true}/>
}


  return (
    <>

      <ToastCco />
      <div className="container-fluid">
        {/* row que contiene el valor total de todos las cuentas-----------------------------------------------------  */}
        <ConsolidatedCard monthAndYear={monthAndYear}  />
       

        <Row>
          <Col></Col>
        </Row>


        {/* Resto de las cards de cada cuenta---------------------------------------------------------------- */}
        <Row className="d-flex">
          {Array.isArray(cardUsageAccount) && cardUsageAccount.map((data, index) => (
            <Col xl={4} key={index}>   
              <div className="card card-slide  p-4 ">
                <div className="d-flex position-relative justify-content-between">
                <h4 className="pb-1" style={{ overflow: 'hidden' }}>{data.accountName}</h4>


                 {user && rolConfig?.VIEWS?.ACCOUNTS?.EDIT_ACCOUNT.includes(user[0]?.iduserrole) &&( <EditButtonCard index={index} />)}
                </div>
                <div className="card-body ">
                  <div className="progress-widget">
                  <Tooltip title={"Change percentage " + Number(data.percentageChange).toFixed(0) + 
                      "%\n" +"Last Month: " +  Number(data.previousMonthCost).toFixed(0)
                
                } position="top" delay={1000}>                   
                  <div>
                    <Circularprogressbar
                      stroke={props.coloralertmode}
                      width="80px"
                      height="80px"
                      Linecap="rounded"
                      trailstroke="#ddd"
                      strokewidth="4px"
                      style={{ width: 80, height: 80 }}
                      value={data.percentageChange}
                      id="circle-progress-01"
                    >
                      
                      <img
                        style={{ width: 50, marginTop: -5 }}
                        src={`/${data.cloudProvider}.png`}
                        
                        alt={data.cloudProvider}
                      />
                        
                    
                    </Circularprogressbar>
                    </div>
                    </Tooltip>
                    <div className="progress-detail">
                      <p className="mb-2">{dateFormated(data.currentMothDescription)}</p>
                      <h4 className="counter">
                        $
                        <CountUp
                          start={0}
                          end={data.currentMonthCost}
                          duration={3}
                        />
                      </h4>
                    </div>
                  </div>
                </div>
                 
                
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
