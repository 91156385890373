import React from 'react'
import {Container,Row,Col,Spinner } from 'react-bootstrap';
import '../styles/loader.css'

const Loader = ({center=false}) => {
    return (

        <div  className={center ? "container-fluidLoader" : "containerLoader" }>
       
                <Spinner animation="border" variant="primary" className='me-3' />
                 Loading.....

        </div>
    )
  }
  
  export default Loader