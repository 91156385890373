import React, { useRef,useEffect,useState } from "react";
import { Form, Button,Spinner} from "react-bootstrap";
import '../../../styles/containerPrincipal.css' 
import { useSelector,useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { testSnowflakeConnection,setNewGCPCredentials,testGcpConnection } from "../../../store/slices/accounts/accountThunks";
import { resetStateCredentials } from "../../../store/slices/accounts/accountSlice";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import {Tooltip} from 'react-tippy';
import 'react-tippy/dist/tippy.css'

const AccountGcpForm = () => {
    const user = useAuth0();
    const userFinal=user.user.email
    const dispatch = useDispatch();
    
    const { idTenant } = useSelector((state) => state.costing);

    const { validCredentials,stateAccount,nameNewAccount } = useSelector((state) => state.accounts);

    const [showPassword, setShowPassword] = useState(false);

    const bigQueryTableId = useRef('');
    const accountServiceCredentials = useRef('');
  

    //Estilos para el formulario 
    const styleLabel = {color:'black'}
    const styleInput = {borderColor: '#899AF1', color: '#000'}
    const botonSinFondo = { backgroundColor: 'transparent',border: 'none',padding: 0}


    const handleTestConnection=()=>{

      let bigQueryTable = bigQueryTableId.current.value.trim();
      let accountServiceCredentialsTest = accountServiceCredentials.current.value.trim();

      const credentialsObject = JSON.parse(accountServiceCredentialsTest);

        const body = { costtable:bigQueryTable,credentials:credentialsObject }

        dispatch(testGcpConnection(body))
    }
    
  
    const submitForm=()=>{
        //No cambiar nombre de las variables 
      let costtable = bigQueryTableId.current.value.trim();
      let credentials = JSON.parse(accountServiceCredentials.current.value)

        
        const body = {
            data: {
            cloudProvider: 'GCP',
            accountName: nameNewAccount,
            costtable,
            credentials
          },
            idtenant: idTenant,
            user: userFinal,
          };
         
          dispatch(setNewGCPCredentials(body));
    }

    const resetStateAccunt=()=>{
        dispatch(resetStateCredentials())
    }


     return (
        <Form>
          
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">Service account credentials</Form.Label>
            <Form.Control
              type="text"
              ref={accountServiceCredentials}
              id="inputUser"
              disabled={validCredentials}
              placeholder="Enter account credentials"
              style={styleInput}
                as="textarea"
              rows={6}
            />
          </Form.Group>
          
          <Form.Group className="form-group">
            <Form.Label style={styleLabel} htmlFor="exampleInputText1">BigQuery table id</Form.Label>
            <Button variant="light" style={botonSinFondo} className="ms-3" onClick={()=>{setShowPassword(!showPassword)}}> 
                {showPassword ? 
                    <VisibilityOutlinedIcon /> :
                    <VisibilityOffOutlinedIcon  />
                }
            </Button>

            <Form.Control
              type={showPassword ? 'text' : 'password'}
              ref={bigQueryTableId}
              id="inputPassword"
              disabled={validCredentials}
              placeholder="Enter table id"
              style={styleInput}
            />
          </Form.Group>

        {validCredentials === false && (
          <Button
              type="button"
              onClick={()=>{handleTestConnection()}}
              variant="outline-success"
              className="m-2"
            >
              {stateAccount === "ready" ? "Test connection" : "Loading..."}
              {stateAccount !== "ready" && (
                <Spinner
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
            )}
            {validCredentials === true && (
            <Link to='/accounts'>
                <Button type="button" className="m-2" disabled={nameNewAccount === ''} variant="btn btn-primary" onClick={()=>{submitForm()}} >
                  Submit
                </Button>
             </Link>
            )}
          <Link to='/accounts' > 
          <Button type="button" variant="btn btn-danger" onClick={()=>{resetStateAccunt()}}>
            cancel
          </Button>
          </Link>
        </Form>
      
  );
};

export default AccountGcpForm;
