import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    status: 'ready', //ready , processing
    initialStatus:'procesing',
    cardUsageAccount:[],
    cloudProvider: 'AWS',
    credentialsOk:false,
    alertCredentialsInvalid:'',
    modalCredencialesSave:false,
    credentialToChange:{
        index:null,
        state:false
    },
    dataDashbord:{},
    dataDistribution:{},
    dataToSave:{
        percentage:null,
        absolute:null,
        month:null,
        accountId:null
    },//genero esta varaiable para guardar los datos de la grilla, ya que el boton save esta en el componente 'sub-header' 
    flagToResetData:0,
    idAccount:null,
    idTenant:null,
    notificationView : true,
    flagToRenderAfeterSave:false,
    dataSavedSucces:false,
    equallyDistribution:false,
    associtedDistribution:false,

}


export const costingSlice= createSlice({
    name: 'costing',
    initialState,
    reducers: {

        processingRequest: (state) => {
            state.status = 'processing';
        },
        readyRequest: (state) => {
            state.status = 'ready';
        },
        getAwsUsage: (state,{payload})=>{
            state.initialStatus = 'ready';
            state.status = 'ready';
            state.cardUsageAccount=payload
        },
        updateProvider: (state,newProvider)=>{
            state.cloudProvider= newProvider.payload
        },

        updateStateCredentials: (state)=>{
            state.credentialsOk = true
            state.status = 'ready';
        },
        resetSateCredentials: (state)=>{
            state.credentialsOk = false
            state.alertCredentialsInvalid=''
            state.status = 'ready';
        },
        updatealertCredentialsInvalidTrue:(state)=>{
            state.alertCredentialsInvalid = true
        },
        updatealertCredentialsInvalidFalse:(state)=>{
            state.alertCredentialsInvalid = false
        },
        updateStateTrueModalCredencialesSave:(state)=>{
            state.modalCredencialesSave = true
        },
        updateStateFalseModalCredencialesSave:(state)=>{
            state.modalCredencialesSave = false
        },
        editCredentials:(state,index)=>{
            state.credentialToChange.state = true
            state.credentialToChange.index = index.payload
        },
        editCredentialsFalse:(state)=>{
            state.credentialToChange.state = false
            state.credentialToChange.index = []
        },
        updatecardUsageAccount: (state,{payload})=>{
            
            let body={
                cloudProvider: payload.cloudProvider,
                accountName:payload.accountName,
                accessKeyId : payload.accessKeyId,
                secretAccessKey : payload.secretAccessKey,
            }
            state.cardUsageAccount[payload.index]=body
        },
        getDataDashbord: (state,{payload})=>{
            state.dataDashbord=payload.data
        },
        clearDataDashbord: (state)=>{
            state.dataDashbord=[]
        },
        getDataDistrbution:(state,{payload})=>{
            state.dataDistribution=payload.data
        },
        clearDataDistribution: (state)=>{
            state.dataDistribution={}
        },
        updateDataToSave: (state,{payload})=>{
            state.dataToSave.absolute=payload.absolute
            state.dataToSave.percentage=payload.percentage
            state.dataToSave.accountId=payload.accountId
            state.dataToSave.month=payload.month
        },
        //esta funcion es solo una bandera que se activa desde el componente sub-header para que se dispare la funcion buildBoard() que esta en el componente DistributionTable
        flagToResetDataDistributionTable:(state)=>{
            state.flagToResetData = state.flagToResetData + 1
        },
        updateIdAccount:(state,{payload})=>{
            state.idAccount = payload.idAccount
        },
        setIdTenant:(state,{payload})=>{
            state.idTenant = payload.idTenant
        },
        setNotificaionViewFalse:(state)=>{
            state.notificationView = false
        },
        setNotificaionViewTrue:(state)=>{
            state.notificationView = true
        },
        setFlagToRenderTrue : (state)=>{
            state.flagToRenderAfeterSave = true
        },
        setFlagToRenderFalse : (state)=>{
            state.flagToRenderAfeterSave = false
        },
        setDataSavedSuccesTrue : (state)=>{
            state.dataSavedSucces = true
        },
        setDataSavedSuccesFalse : (state)=>{
            state.dataSavedSucces = false
        },
        setEquallyDistributionTrue : (state)=>{
            state.equallyDistribution = true
        },
        setEquallyDistributionFalse : (state)=>{
            state.equallyDistribution = false
        },
        setAssocitedDistributionTrue : (state)=>{
            state.associtedDistribution = true
        },
        setAssocitedDistributionFalse : (state)=>{
            state.associtedDistribution = false
        },
        


        
    }
})


export const {setAssocitedDistributionFalse,setAssocitedDistributionTrue,setEquallyDistributionFalse,setEquallyDistributionTrue,setDataSavedSuccesFalse,setDataSavedSuccesTrue,setFlagToRenderFalse,setFlagToRenderTrue,setNotificaionViewTrue,setNotificaionViewFalse,setIdTenant,updateIdAccount,flagToResetDataDistributionTable,updateDataToSave,editCredentialsFalse,clearDataDistribution,getDataDistrbution,clearDataDashbord,getDataDashbord,updatecardUsageAccount,editCredentials,updateStateFalseModalCredencialesSave,updateStateTrueModalCredencialesSave,processingRequest,getAwsUsage,updateProvider,updateStateCredentials,readyRequest,resetSateCredentials,updatealertCredentialsInvalidTrue,updatealertCredentialsInvalidFalse} = costingSlice.actions
