import { React,useEffect,useState } from "react";
import { Modal, Button,Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../store/slices/modal/modalSlice";
import { useAuth0 } from "@auth0/auth0-react";
import '../../../styles/modalScheduleRefresh.css' 
import {setScheduleRefresh,getScheduleRefresh} from '../../../store/slices/accounts/accountThunks'

const ScheduleRefresh = () => {
  const dispatch = useDispatch();
  const user = useAuth0();


  const { idTenant } = useSelector((state) => state.costing);

  const { scheduleRefresh } = useSelector((state) => state.accounts);

  const [selectedTime, setSelectedTime] = useState("00");

  useEffect(() => {
    dispatch(getScheduleRefresh({'tenantId':idTenant}));
  }, []);

  
  const timezoneOffsetMinutes = new Date().getTimezoneOffset();
  const timezoneOffsetHours = timezoneOffsetMinutes / 60;
  const timezoneOffsetSign = timezoneOffsetHours >= 0 ? 1 : -1;
  const absoluteTimezoneOffsetHours = Math.abs(timezoneOffsetHours);
  
  const timezoneOffsetHoursString = timezoneOffsetSign * absoluteTimezoneOffsetHours

  let savedHourShow = 0;

  if (scheduleRefresh) {
    let savedHour = parseInt(scheduleRefresh);
  
    // Ajusta la hora a GMT-0 (resta 3 horas para GMT-3)
    savedHour -= 3;
  
    // Ajusta la hora si se sale del rango de 0 a 23
    if (savedHour < 0) {
      savedHour = 24 + savedHour; // Ajusta hacia atrás
    } else if (savedHour >= 24) {
      savedHour = savedHour % 24; // Ajusta hacia adelante
    }
    savedHourShow = savedHour
  }

  const handleSubmit = () => {
    let finalHour = 0;

    if(timezoneOffsetHoursString > 0){
      finalHour = parseInt(selectedTime) + parseInt(timezoneOffsetHoursString);
      //valido que si es mayor a 23 , lo que se pasa a 23 vuelva a comenzar desde 0
      if(finalHour> 23){
        finalHour = finalHour - 24
      }
    }
    else{
      if(timezoneOffsetHoursString < 0){
        finalHour = parseInt(selectedTime) - parseInt(timezoneOffsetHoursString);
        //aca valido por si termina siendo menos a 0 , lo que se pasa a 0 vuelva a comenzar desde 23
        if(finalHour < 0){
          finalHour = 24 + finalHour
        }
      }
      else{
        finalHour = parseInt(selectedTime);
      }
    }
    // Formatea finalHour como una cadena de dos dígitos (por ejemplo, "01", "09", "12", etc.)
  const finalHourFormatted = finalHour.toString().padStart(2, '0');
  
    let body ={
        tenantId:idTenant,
        user:user.user.email,
        croncode:`0 ${finalHourFormatted} * * *`,
        edit: scheduleRefresh ? true : false
    }

    dispatch(setScheduleRefresh(body));
    
    dispatch(closeModal());
  };


  // Genera un array de horas desde "00" hasta "23"
  const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = i.toString().padStart(2, '0'); // Añade ceros a la izquierda si es necesario
    return hour;
  });

  const handleTimeSelect = (hour) => {
    setSelectedTime(hour); // Actualiza 'selectedTime' cuando se selecciona una hora
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Schedule Refresh Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      Data updates will be performed daily at:

      <Dropdown className="d-inline mx-2 custom-dropdown">
        <Dropdown.Toggle id="dropdown-autoclose-true">
          {selectedTime} hrs
        </Dropdown.Toggle>

        <Dropdown.Menu className="custom-dropdown-menu">
            {hours.map((hour) => (
              <Dropdown.Item key={hour}
              onClick={() => handleTimeSelect(hour)} 
              >{hour}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
      </Dropdown>

        {scheduleRefresh  && (
        <p className="mt-3" style={{ fontStyle: 'italic' }}>Your account is already configured to perform daily data updates at: {savedHourShow}:00 hrs</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Close
        </Button>
        <Link to="/accounts">
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Link>
      </Modal.Footer>
    </>
  );
};

export default ScheduleRefresh;
