import React from 'react'
import {Row,Col,Form,Button} from 'react-bootstrap'
import Card from '../../components/Card'
import { useState,useEffect } from 'react'
import { useSelector,useDispatch } from "react-redux";
import { useAuth0 } from '@auth0/auth0-react'
import { addUser } from '../../store/slices/users/usersThunks';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { countries } from '../../helpers/users';
import ToastUsers from '../../components/cco/toasts/ToastUsers';
import { errorMessage } from '../../store/slices/users/usersSlices';

const UserAdd =() =>{
  const dispatch = useDispatch();
  const emailTenant = useAuth0();
  const history = useHistory();
  const { idTenant } = useSelector((state) => state.costing);
  const { usersState, errorUserMessage } = useSelector((state) => state.users);
  const [countriesarray, setCountriesArray] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorsPass, setErrorsPass] = useState({
    invalidPassword: false,
  });

  const [users, setUser] = useState({
    tenantId: "",
    creationUser: "",
    userEmail: "",
    password: "",
    name: "",
    lastName: "",
    country: "",
    city: "",
    contact: "",
    company: "",
    userDepartment: "",
  });
  const [input, setInput] = useState({
    email: "",
    password: "",
    repeatpassword: "",
    name: "",
    lastName: "",
    company: "",
    mobile: "",
    position: "",
    country: "",
    city: "",
  });

  useEffect(() => {
    setUser({
      userEmail: input.email,
      password: input.repeatpassword,
      name: input.name,
      lastName: input.lastName,
      country: input.country,
      city: input.city,
      contact: input.mobile,
      company: input.company,
      userDepartment: input.position,
    });
  }, [input]);

  useEffect(() => {
    if (isFormSubmitted) {
      setErrors(validate(input));
    }
  }, [input, isFormSubmitted]);

  useEffect(() => {
    setCountriesArray(countries);
  }, []);

  useEffect(() => {
    if (usersState === "ready") {
      history.push("/userlist");
    }
  }, [usersState, history]);

  const validate = (input) => {
    let errors = {};
    if (isFormSubmitted) {
      if (input.name === "") {
        errors.name = "Your name is required...";
      }
      if (input.lastName === "") {
        errors.lastName = "Your last name is required...";
      }
      if (input.email === "") {
        errors.email = "Your email is required...";
      }
      if (input.company === "") {
        errors.company = "Your company name is required...";
      }
      // if (input.mobile === "") {
      //   errors.mobile = "Your mobile number is required...";
      // }
      // if (input.position === "") {
      //   errors.position = "Your position is required...";
      // }
      if (input.country === "") {
        errors.country = "Your country is required...";
      }
      if (input.city === "") {
        errors.city = "Your city is requried...";
      }
    }
    return errors;
  };
  const validatePassword = (password, repeatpassword) => {
    if (password !== repeatpassword) {
      errorsPass.invalidPassword = "Password not match";
      return false;
    } else if (
      !/^(?=.*[A-Z])(?=.*\d).{8,}$/.test(repeatpassword) &&
      !/^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password)
    ) {
      errorsPass.invalidPassword =
        "Remember the password must contain a number, a capital letter and at least 8 characters";

      return false;
    } else {
      errorsPass.invalidPassword = "Password accepted";
      return true;
    }
  };

  // const validateAllInputs = () => {
  //   if (
  //     validatePassword(input.password, input.repeatpassword) &&
  //     Object.keys(errors).length === 0 &&
  //     Object.values(input).every((value) => value !== null && value.trim() !== "")
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const validateAllInputs = () => {
    if (
      validatePassword(input.password, input.repeatpassword) &&
      Object.keys(errors).length === 0 &&
      Object.entries(input).every(([key, value]) =>
        key === 'mobile' || key === 'position' || (value !== null && value.trim() !== "")
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.id]: e.target.value,
    });
    setErrors(
      validate({
        ...input,
        [e.target.id]: e.target.value,
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);
    const validationErrors = validate(input);
    setErrors(validationErrors);
    if (validateAllInputs()) {
      setUser({
        userEmail: input.email,
        password: input.repeatpassword,
        name: input.name,
        lastName: input.lastName,
        country: input.country,
        city: input.city,
        contact: input.mobile,
        company: input.company,
        userDepartment: input.position,
      });
      dispatch(
        addUser(
          {
            tenantId: idTenant,
            creationUser: emailTenant.user.email,
            userEmail: users.userEmail.trim(),
            password: users.password,
            name: users.name,
            lastName: users.lastName,
            country: users.country,
            city: users.city,
            contact: users.contact,
            company: users.company,
            userDepartment: users.userDepartment,
          },
          input
        )
      );
    } else {
    
    }
  };
  return (
    <>
   <ToastUsers/>
      <div>
        <Row>
          <Col>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">New User Information</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="new-user-info">
                  <form>
                    <div className="row">
                      <Form.Label
                        className="col-md-8 form-group"
                        htmlFor="alert"
                      >
                       Fields with * are required
                      </Form.Label>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="fname" style={{ color: "black" }}>
                         * First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.name}
                        
                          onChange={(e) => handleChange(e)}
                         
                          id="name"
                          style={
                            input.name
                            ? { background: "#E8F0FE",borderColor: "#899AF1", color: "black" } 
                            : errors.name && isFormSubmitted
                            ? { borderColor: "red" } 
                            :  { borderColor: "#899AF1" }
                          }
                          maxLength={50}
                          placeholder={
                            errors.name && isFormSubmitted
                              ? errors.name
                              : "Enter your first name... "
                          }
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="lname" style={{ color: "black" }}>
                         * Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.lastName}
                          onChange={(e) => handleChange(e)}
                          id="lastName"
                          style={
                            input.lastName
                            ? { background: "#E8F0FE", borderColor: "#899AF1",color: "black" } 
                            : errors.lastName && isFormSubmitted
                            ? { borderColor: "red" } 
                            :  { borderColor: "#899AF1" }
                          }
                          maxLength={50}
                          placeholder={
                            errors.lastName && isFormSubmitted
                              ? errors.lastName
                              : "Enter your Last Name..."
                          }
                        />
                      </Form.Group>

                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="cname" style={{ color: "black" }}>
                        * Company Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.company}
                          onChange={(e) => handleChange(e)}
                          id="company"
                          style={
                            input.company
                            ? {background: "#E8F0FE",borderColor: "#899AF1", color: "black" } 
                            : errors.company && isFormSubmitted
                            ? { borderColor: "red" } 
                            :  { borderColor: "#899AF1" }
                          }
                          maxLength={50}
                          placeholder={
                            errors.company && isFormSubmitted
                              ? errors.company
                              : "Enter your Company..."
                          }
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="pno" style={{ color: "black" }}>
                          Position
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.position}
                          onChange={(e) => handleChange(e)}
                          id="position"
                          style={
                            input.position
                            ? {  background: "#E8F0FE", borderColor: "#899AF1" , color: "black" } 
                            :  { borderColor: "#899AF1" }
                          }
                          maxLength={50}
                          placeholder={
                            errors.position && isFormSubmitted
                              ? errors.position
                              : "Enter your Position..."
                          }
                        />
                      </Form.Group>
                      <Form.Group className="col-sm-6 form-group">
                        <Form.Label style={{ color: "black" }}>
                         * Country
                        </Form.Label>
                        <select
                          id="country"
                          onChange={(e) => handleChange(e)}
                          className="selectpicker form-control"
                          style={
                            input.country
                            ? {borderColor: "#899AF1", background: "#E8F0FE", color: "black" }
                            : errors.country && isFormSubmitted
                            ? { borderColor: "red" } 
                            :  { borderColor: "#899AF1" }
                          }
                          data-style="py-0"
                        >
                          <option disabled={true}>Select Country</option>
                          {countriesarray > 1 ? (
                            <option>Loading countries....</option>
                          ) : (
                            countries.map((item, idx) => {
                              return (
                                <option key={idx} value={item}>
                                  {item}
                                </option>
                              );
                            })
                          )}
                        </select>
                      </Form.Group>

                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="city" style={{ color: "black" }}>
                         * City
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.city}
                          onChange={(e) => handleChange(e)}
                          id="city"
                          style={
                            input.city
                            ? {background: "#E8F0FE", borderColor: "#899AF1",color: "black" } 
                            : errors.city && isFormSubmitted
                            ? { borderColor: "red" } 
                            :  { borderColor: "#899AF1" }
                          }
                          maxLength={30}
                          placeholder={
                            errors.city && isFormSubmitted
                              ? errors.city
                              : "Enter your City..."
                          }
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6  form-group">
                        <Form.Label htmlFor="mobno" style={{ color: "black" }}>
                          Mobile Number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={input.mobile}
                          onChange={(e) => handleChange(e)}
                          id="mobile"
                          style={
                            input.mobile
                            ? {background: "#E8F0FE",borderColor: "#899AF1", color: "black" } 
                            :  { borderColor: "#899AF1" }
                            }
                          placeholder={
                            errors.mobile
                              ? errors.mobile
                              : "Enter your Mobile..."
                          }
                        />
                      </Form.Group>
                    </div>
                    <hr />
                    <h5 className="mb-3">Security</h5>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="email" style={{ color: "black" }}>
                         * Email
                        </Form.Label>
                        <Form.Control
                          type="email"
                          value={input.email}
                          onChange={(e) => handleChange(e)}
                          id="email"
                          style={
                            input.email
                            ? {background: "#E8F0FE",borderColor: "#899AF1",color: "black" } 
                            : errors.email && isFormSubmitted
                            ? { borderColor: "red" } 
                            :  { borderColor: "#899AF1" }
                          }
                          maxLength={50}
                          placeholder={
                            errors.email && isFormSubmitted
                              ? errors.email
                              : "Enter your Email"
                          }
                        />
                      </Form.Group>
                      <div className="row">
                        <Form.Group className="col-md-6 form-group">
                          <Form.Label htmlFor="pass" style={{ color: "black" }}>
                           * Password
                          </Form.Label>
                          <Form.Control
                            type="password"
                            value={input.password}
                            onChange={(e) => handleChange(e)}
                            id="password"
                            style={
                              input.password ?{ borderColor: "#899AF1",background: "#E8F0FE", color: "black" }:
                              errorsPass.invalidPassword === false ||
                              errorsPass.invalidPassword === "Password accepted"
                                ? { borderColor: "#899AF1" }
                                : { borderColor: "red" }
                            }
                            placeholder="Password"
                          />
                        </Form.Group>
                        <Form.Group className="col-md-6 form-group">
                          <Form.Label
                            htmlFor="rpass"
                            style={{ color: "black" }}
                          >
                           * Repeat Password
                          </Form.Label>
                          <Form.Control
                            type="password"
                            value={input.repeatpassword}
                            onChange={(e) => handleChange(e)}
                            style={
                              input.repeatpassword ? { borderColor: "#899AF1",background: "#E8F0FE", color: "black" }:
                              errorsPass.invalidPassword === false ||
                              errorsPass.invalidPassword === "Password accepted"
                                ? { borderColor: "#899AF1" }
                                : { borderColor: "red" }  
                            }
                            maxLength={30}
                            id="repeatpassword"
                            placeholder="Repeat Password "
                          />
                        </Form.Group>
                      </div>
                      <div className="checkbox">
                        {errorsPass.invalidPassword && (
                          <Form.Label
                            htmlFor="alert"
                            maxLength={30}
                            style={
                              errorsPass.invalidPassword === "Password accepted"
                                ? { color: "blue" }
                                : { color: "red" }
                            }
                          >
                            {errorsPass.invalidPassword}
                          </Form.Label>
                        )}{" "}
                      </div>
                      {/* {usersState === "failed" ? (
                        <div>
                          <Form.Label htmlFor="alert" style={{ color: "red" }}>
                            {errorUserMessage.message}
                          </Form.Label>
                        </div>
                      ) : (
                        <div></div>
                      )} */}
                      {usersState === "processing" ? (
                        <div>
                          <Form.Label htmlFor="alert" style={{ color: "blue" }}>
                            Processing request...
                          </Form.Label>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {/* {usersState === "ready" ? (
                        <div>
                          <Form.Label
                            htmlFor="alert"
                            style={{ color: "green" }}
                          >
                            {errorUserMessage.message}
                          </Form.Label>
                        </div>
                      ) : (
                        <div></div>
                      )} */}
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        type="submit"
                        variant="btn btn-primary mx-4"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        Add User
                      </Button>
                      <Link to="/">
                        <Button variant="btn btn-danger mx-4">Cancel </Button>
                      </Link>
                    </div>
                  </form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default UserAdd;

