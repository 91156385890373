import { React } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { passwordReset,changeUserStatus } from "../../../store/slices/users/usersThunks";
import { closeModal } from "../../../store/slices/modal/modalSlice";
import { deleteContract } from "../../../store/slices/contracts/contractsThunks";

const SnowflakeIncompleteFields = () => {
  const dispatch = useDispatch();
  const  {selectedContract} = useSelector( (state) => state.contracts );



  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Incomplete fields</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{color:'black'}}>
      The fields of the form are incomplete, for a better precision in the costs of your account it is required to complete them
           
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(closeModal())
          }}
        >
          Close
        </Button>
      
      </Modal.Footer>
    </>
  );
};

export default SnowflakeIncompleteFields;
