//componente donde puedo mostrar un toast con un mensaje que va a llegar como un prop desde el componente padre
//el toast se va a mostrar por 5 segundos y luego se va a ocultar


import React, { Component } from 'react';
import {toast , Toaster} from 'react-hot-toast';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleReportToastMessage } from '../../../store/slices/reports/reportsSlices';
const ToastReport = (message) => {

    const dispatch = useDispatch();
    const { reportToastMessage,updateTable } = useSelector((state) => state.reports);
    const styleToastSuccess = {
        border: '1px solid #FFFFFF',
        padding: '16px',
        color: '#FFFFFF',
        backgroundColor: '#6991f5',
      };
    const duration = 5000;

    useEffect(() => {
        if (reportToastMessage.status === 200) {
          toast.success(reportToastMessage.message,{
            duration : duration,
            style: styleToastSuccess,
        });
        dispatch(handleReportToastMessage({status:undefined,message:undefined}));
        }else if(reportToastMessage.status === 204){    
          toast.error(reportToastMessage.message,{
            duration : duration,
            style: styleToastSuccess,
        });
        dispatch(handleReportToastMessage({status:undefined,message:undefined}));
        }else{  }
      }, [reportToastMessage]);


      return (
        <div>
            <Toaster/>
        </div>
    );
}
export default ToastReport;