import { React, useState,useEffect } from "react";
import { Modal,Row,Col,Form,Button } from "react-bootstrap";


import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react'
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../../store/slices/modal/modalSlice";
import { allTenantsInDb,changeTenant, handleRoleUser,getAllSuperAdm } from "../../../store/slices/roles/rolesThunks";








const RoleAssignment = () => {
    const dispatch = useDispatch();
    const { idTenant } = useSelector( (state) => state.costing );
    const { userSelected,rolConfig } = useSelector((state) => state.roles);
    const  {user} = useSelector( (state) => state.profile );
    //const currentTenant = user[0].idtenant
    //const userAuth = useAuth0();
    //const adminEmail = userAuth.user.email
    
  const [selectedOption, setSelectedOption] = useState("default");
  const [roleDescription, setRoleDescription] = useState('');

  const roleDescriptions = {
    default: '',
    1: 'Admin: Access to all the functionalities and menus of the application',
    2: 'Costing: Look at the costs, you can update the accounts',
    3: 'Read only: View only, no modifications allowed',
  };


  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
    setRoleDescription(roleDescriptions[e.target.value]);
  };
const submitRoleChange = () => {
    dispatch(handleRoleUser(userSelected.emailUser,selectedOption,idTenant))
    dispatch(closeModal())
}

  return (
   <>
      <Modal.Header closeButton>
        <Modal.Title>Role assignment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
      <Form.Label style={{ color: "black", display: "block" }}>
      <h5>Select the role you want to assign </h5> 
        </Form.Label>
        <Form.Label  style={{ color: "black", display: "block" ,paddingTop:'6px'}}>
      <h6>  User: {userSelected.emailUser}  </h6>
        <br />
        <h6> Assigned role: {
        userSelected.roleUser === 0 ? 'SuperAdmin' : 
        userSelected.roleUser === 1 ? 'Admin' : 
        userSelected.roleUser === 2 ? 'Costing Explorer' :
        userSelected.roleUser === 3 ? 'Read Only' : '' 
      }
      </h6>
        </Form.Label>
        </div>
<Form.Group className="col-sm-6 form-group">
        <select
          value={selectedOption}
          //onChange={(e) => setSelectedOption(e.target.value)
            onChange={ handleSelectChange}        
            style={{ borderColor: "#899AF1" ,borderRadius:'5px', height: '35px', width: '100%'}}              
            
        >
          <option value="default" disabled>
            Select an option
          </option>
       { user && rolConfig.VIEWS?.SECURITY.USER_LIST.ASSIGN_SUPERADMIN.includes(user[0].iduserrole)&&
       ( <option key={0} value={0}>SuperAdmin</option> ) }
            <option key={1} value={1}>Admin</option>
            <option key={2} value={2}>Costing Explorer</option>
            <option key={3} value={3}>Read only</option>
        </select>
        </Form.Group>

        {roleDescription && (
          <div>
             <Form.Label style={{ color: "black" }}>
            <p>{roleDescription}</p>
            </Form.Label>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => dispatch(closeModal())}>
          Close
        </Button>
        <Link>
          <Button variant="primary" onClick={() => submitRoleChange()}>
          Assign role

              </Button>
        </Link>
      </Modal.Footer>
    </>
  );
};

export default RoleAssignment;
            
         
           
        
        
          

    
        

