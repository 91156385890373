import React, {useState,useEffect}from 'react'
import FsLightbox from 'fslightbox-react';
import {Row,Col,Image,Form,Nav,Dropdown,Tab,Button} from 'react-bootstrap'
import Card from '../../components/Card'
import { useAuth0 } from '@auth0/auth0-react'
import {Link} from 'react-router-dom'
import { useSelector,useDispatch } from "react-redux";
import { getUser } from '../../store/slices/profile/profileThunks';
import { passwordReset } from '../../store/slices/users/usersThunks';
import {activeProfileForm} from '../../store/slices/profile/profileThunks';
import Loader from '../../layouts/Loader'
import ProfileEditForm from '../../components/cco/forms/ProfileEditForm';
import ToastUsers from '../../components/cco/toasts/ToastUsers';
import { openModal,closeModal } from '../../store/slices/modal/modalSlice' 
import { userModelData } from '../../store/slices/users/usersSlices'
// img                                       /assets/images/avatars/01.png'
import avatars11 from '../../assets/images/avatars/01.png'
import avatars22 from '../../assets/images/avatars/avtar_1.png'
import avatars33 from '../../assets/images/avatars/avtar_2.png'
import avatars44 from '../../assets/images/avatars/avtar_3.png'
import avatars55 from '../../assets/images/avatars/avtar_4.png'
import avatars66 from '../../assets/images/avatars/avtar_5.png'


//import ShareOffcanvas from '../../components/partials/components/shareoffcanvas'

const UserProfile =() =>{
   const [selectedUser, setSelectedUser] = useState(null);

// const [activeTab, setActiveTab] = useState("fourth");
// const [toggler, setToggler] = useState(false);
const emailTenant = useAuth0();
const dispatch = useDispatch();
const  {user,profileStatus,profileForm} = useSelector( (state) => state.profile );



useEffect(()=> {

   dispatch(getUser(emailTenant.user.email))
},[profileForm])

   useEffect(()=> {
      dispatch(activeProfileForm(false))
      dispatch(getUser(emailTenant.user.email))
   },[])

   const resetUserPassword = (user) => {
      dispatch(openModal("ResetPasswordUser"))
      dispatch(userModelData({email:user.useremail,name:user.name,lastname:user.lastname}))
   }


   const editFormView = (user)=> {
      setSelectedUser(user);
       dispatch(activeProfileForm(true))
   } 



  return(
      <>
      { profileForm ? ( <ProfileEditForm  selectedUser={selectedUser}/> ): ( 
         <> 
       
       <ToastUsers/>

    { profileStatus !== 'ready' ? (<Loader/>)  : ( <>
    
    {  user?.map((item,idx) =>(
        <Tab.Container  defaultActiveKey="first">
            <Row>
               <Col lg="12">
                  <Card>
                        <Card.Body>
                           <div className="d-flex flex-wrap align-items-center justify-content-between">
                              <div className="d-flex flex-wrap align-items-center">
                                 <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                  { item.userprofile === null ? <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={avatars11} alt="profile-pic"/>:<Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={item.userprofile} alt="profile-pic"/>}
                            
                                 </div>
                                 <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                    <h4 className="me-2 h4">{`${item.name} ${item.lastname}`}</h4>
                                 {item.userdepartment ===null? <span> work in {item.company}</span>:  <span> {item.userdepartment} at {item.company}</span>}
                                 </div>
                              </div>
                              
                           </div>
                        </Card.Body>
                  </Card>
               </Col>
                 
                 
     
               <Col lg="6" className="col-lg-6">
               <Card>
                     <Card.Header>
                        <div className="header-title">
                           <h4 className="card-title">Information </h4>
                        </div>
                     </Card.Header>
                     <Card.Body>
                     <div className="mb-1">Email: <span  className="ms-3">{item.useremail}</span></div>
                        <div className="mb-1">Country: <span  className="ms-3">{item.country}</span></div>
                        <div className="mb-1">City: <span  className="ms-3">{item.city}</span></div>
                     {item.contact === null ? null :  <div className="mb-1">Phone: <span  className="ms-3">{item.contact}</span></div>}
                     </Card.Body>
                  </Card>
                  <Card>
                     <Card.Header>
                        <div className="header-title">
                           <h4 className="card-title">Creation data</h4>
                        </div>
                     </Card.Header>
                     <Card.Body >
                     <div className="mb-1">Creation by:<span className="ms-3">{item.creationuser}</span></div>
                     <div className="mb-1">Date:<span  className="ms-3">{item.creationdate}</span></div>
                    
                     <Button type="submit" variant="btn btn-primary mx-6 my-4 ml-2"   
                        onClick={()=> {resetUserPassword(item)}}>Reset password                            
                              </Button>                                          
                     </Card.Body>
                  </Card>
               </Col>
                <Col lg="6">
                  <Tab.Content className="profile-content">
                     <Tab.Pane eventKey="first" id="profile-feed">
                     <Card  className=" my-0">
                           <Card.Header>
                              <div className="header-title">
                                 <h4 className="card-title">Edit Profile</h4>
                              </div>
                           </Card.Header>
                           <Card.Body className="d-flex flex-column justify-content-between">
                              <div className="text-center">
                                 <div className="user-profile">
                                 { item.userprofile === null ? <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={avatars11} alt="profile-pic"/>:<Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={item.userprofile} alt="profile-pic"/>}
                                   
                                 </div>
                                 <div className="mt-3">
                                    <h3 className="d-inline-block me-2">{`${item.name} ${item.lastname} `} </h3>
                                    <p className="d-inline-block ">  {item.userdepartment}</p>
                                    <p className="mb-0">{item.userdescription}</p>
                                 </div>
                                 <Button type="submit" variant="btn btn-primary mx-6 my-4 ml-2"   
                        onClick={()=> { editFormView(item)}}>Edit profile                           
                              </Button>    
                              </div>
                           </Card.Body>
                        </Card>
                    
                     </Tab.Pane>
                     <Tab.Pane eventKey="second" id="profile-activity">
                        <Card>
                           <Card.Header className="d-flex justify-content-between">
                              <div className="header-title">
                                 <h4 className="card-title">Activity</h4>
                              </div>
                           </Card.Header>
                           
                        </Card>
                     </Tab.Pane >
                  
                  </Tab.Content>
               </Col> 
            </Row>
         </Tab.Container> //fin del map 
              //comienzo del map
              ) ) } </>)}  
               </>
               )}
      </>
 

 )}

export default UserProfile;