
import { React, useEffect, useState } from "react";
import Chart  from  "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Dropdown, Button, Spinner } from "react-bootstrap";
import { getAccountsNames,getAccountAndDatesById } from "../../store/slices/forecast/forecastThunks";
import { getCardsByAccountId,getDatesAndMonth } from "../../store/slices/advisor/advisorThunks";
import LastUpdateAlert from "../../components/cco/alerts/LastUpdateAlert";
import { setStateForecast } from "../../store/slices/forecast/forecastSlice";
import {Tooltip} from 'react-tippy';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import CountUp from "react-countup";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useAuth0 } from '@auth0/auth0-react'
import Paginado from "./Paginado";
const moment = require("moment");

const Advisor = () => {
  
  const dispatch = useDispatch();
  const user = useAuth0();
  const userEmail = user.user.email


    const { cardUsageAccount, idTenant  } = useSelector((state) => state.costing);
    const {accountsNamesAndIds,oneAccountData,forecastState } = useSelector((state) => state.forecast);
    const {advisorCards,advisorState,allMonths } = useSelector((state) => state.advisor);

    const [noData, setNoData] = useState(false); 
    const [seleccionado, setSeleccionado] = useState(Array.isArray(accountsNamesAndIds) ? accountsNamesAndIds[0]?.accountName : "")
    const [selectMonth,setSelectMonth] = useState(allMonths.data ? allMonths.data[0]?.months : "")
    const [selectedAccount, setSelectedAccount] = useState({});
    const [arrayNamesInSearch,setArrayNamesInSearch] = useState([])
    const [search,setSearch] = useState({search:""})

    const arrayAdvisorCards = advisorCards?.message
    const [currentPage,setCurrentPage]= useState(1)// pagina actual pagina q sigue
    const [cardPerPage, setCardPerPage]= useState(2) //cards  por pagina
    const indexOfLastCard = currentPage * cardPerPage // 12
    const indexOfFirstCard= indexOfLastCard - cardPerPage
    const cardCutP =  arrayAdvisorCards && arrayAdvisorCards?.slice(indexOfFirstCard,indexOfLastCard) 
    const searchCutP =  arrayNamesInSearch && arrayNamesInSearch?.slice(indexOfFirstCard,indexOfLastCard)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   
   
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      // Agregar el event listener cuando el componente se monta
      window.addEventListener('resize', handleResize);
  
      // Limpiar el event listener cuando el componente se desmonta
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
   
useEffect(() => {  
      dispatch(getDatesAndMonth())
  }, []);

    const paginado = (pageNumber) => {
      setCurrentPage(pageNumber)
    }    

useEffect(() => {
      if(idTenant){
        dispatch(getAccountsNames(idTenant))
      }
    }, [idTenant]);

useEffect(()=> {
      if(allMonths.data?.length > 0 && idTenant && accountsNamesAndIds[0]?.accountid && accountsNamesAndIds[0]?.cloudprovider ){
        setSelectMonth(allMonths.data[0]?.months)
        setSelectedAccount({
      accountName: accountsNamesAndIds[0]?.accountName,
      accountid: accountsNamesAndIds[0]?.accountid,
      cloudprovider:accountsNamesAndIds[0]?.cloudprovider,
      tenantid: accountsNamesAndIds[0]?.tenantid
    })
        dispatch(getCardsByAccountId(userEmail,accountsNamesAndIds[0]?.accountid,accountsNamesAndIds[0]?.cloudprovider,idTenant, allMonths.data ? allMonths.data[0]?.months : ""))    
      }
    },[allMonths,accountsNamesAndIds[0]])

useEffect(() => {
      if(cardUsageAccount === undefined){
        setNoData(true)
      }else{
        setNoData(false)
        if ( Array.isArray(accountsNamesAndIds) && idTenant&& accountsNamesAndIds[0]?.accountid && accountsNamesAndIds[0]?.cloudprovider && accountsNamesAndIds[0]?.accountName)  {
            setSeleccionado(Array.isArray(accountsNamesAndIds) ? accountsNamesAndIds[0]?.accountName : "")
        }
      }
    }, [accountsNamesAndIds]);
           
    const dateForDashboard = oneAccountData.lastUpdated
    let formattedDate = moment(dateForDashboard).format(
      "MMM DD YYYY HH:mm z"
    );
    const selectMonthFunc = (month) => {
      setSelectMonth(month)
  }
  
  
  const handleChange = (e) => {
      //const inputValue = e.target.value
      setSearch({
        ...search,
        [e.target.id]: e.target.value,
      });
      filtrarPorServicename(e.target.value);
    }

    //const  arrayNamesInSearch = []
    
    let prueba = []
    const filtrarPorServicename = (palabraClave) => {
      arrayAdvisorCards.filter((elemento) => {
        if (elemento.servicename.toLowerCase().includes(palabraClave.toLowerCase())) {
          prueba.push(elemento)
        }
      }
      )
      setArrayNamesInSearch(prueba)          
}

    const selectAndGetAccountData =(accountName,accountId,cloud,tenantId,month) => {
        setSearch({search:""})
        setArrayNamesInSearch([])
        setSeleccionado(accountName)
        selectMonthFunc(month)
        dispatch(getCardsByAccountId(userEmail,accountId,cloud,tenantId,month))
      }

 
      let splitcard = cardCutP?.map((el) => {


                        return el.recommendation.split(".")[0]
      
      })
    return (
        <>
      
        
        <Col  xl={12} >
        
          <Col md="12"  className="d-flex justify-content-end align-items-center"
       >
              <LastUpdateAlert formattedDate={formattedDate} dashboard={true} />
          </Col>
         
          <div style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",borderRadius:"10px"}}>
            <div  style={{width:'100%',height:'9vh'}}className="flex-wrap card-header d-flex justify-content-start">
                 
              <Dropdown>
                <Dropdown.Toggle
                  as={Button}
                  variant=" text-secondary"
                  id="dropdownMenuButton3"
                  aria-expanded="false"
                >
                  {seleccionado}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton3"
                >
          
                  {accountsNamesAndIds && accountsNamesAndIds?.map((el, indice) => (
                    <li className="card-title" key={indice}>
                      <Dropdown.Item
                        onClick={() =>{  selectAndGetAccountData(el.accountName,el.accountid,el.cloudprovider,el.tenantid,selectMonth);
                          setSelectedAccount({
                            accountName: el.accountName,
                            accountid: el.accountid,
                            cloudprovider: el.cloudprovider,
                            tenantid: el.tenantid,
                          });
                        }
                        

                        }   
                      >
                        {el.accountName}
                      </Dropdown.Item>
                    </li>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  as={Button}
                  variant=" text-secondary"
                  id="dropdownMenuButton3"
                  aria-expanded="false"
                 
                >
                  {selectMonth}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className="dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton3"
                >
        
                  {allMonths&& allMonths.data&& allMonths?.data.map((el, indice) => (
                    <li className="card-title" key={indice}>
                      <Dropdown.Item onClick={()=>  selectAndGetAccountData(selectedAccount.accountName,selectedAccount.accountid,selectedAccount.cloudprovider,selectedAccount.tenantid,el.months)}>
                       
                     
                        {el.months}
                      </Dropdown.Item>
                    </li>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown style={{width:'40%'}}>
            
                   <InputGroup style={{width:'100%'}}className="mb-3">
        <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
        <Form.Control
         type="text"
         value={search.search}
         onChange={(e) => handleChange(e)}
         id="search"
          placeholder="Service name"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </InputGroup>
              
              
              </Dropdown>
              <div className="d-flex justify-content-center " style={{marginTop:'5px',marginLeft:'5px'}}>
              
<div className="p-1" style={{ display: 'flex', marginLeft: '20px' }}>

</div>
              {advisorState !== "ready"  ? (
                <Spinner
                  animation="grow"
                  className="text-primary"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner> ): 
                
              (
                <div style={{textAlign:'center'}}>
                <Tooltip title="The advisor view analyzes two months and provides recommendations for each account service. These recommendations are made by artificial intelligence to optimize costs. The months are closed months, and the current month is not analyzed.">
                  <InfoIcon fontSize="large" style={{
                   margin: '0 auto'}} />
                </Tooltip>
              </div>                           
              )                          
             }
            </div>            
              </div>             
        {
         (
            <div style={{  height: '70vh',marginTop:'20px',marginRight:'5px',marginLeft:'5px'  }}>
       
    
    
    
  
    
     { advisorState === 'ready' ?(  <Row>

 {  arrayAdvisorCards?.length  > 0 ?
   arrayNamesInSearch?.length  > 0 ? searchCutP?.map((el, indice) => (

    <Col xl={6} key={indice}>
  <div className="card card-slide p-4" style={{ height: '45vh', overflowY: 'auto', margin: '15px' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <img style={{ width: 40, marginTop: -5 }} src={`/${el.cloud}.png`} alt={el.cloud} />
      <h4 className="pb-1">{el.servicename}</h4>
    </div>
    <div style={{ marginTop: '5px', fontSize: '15px', color: 'black' }}>
      <p>{el.recommendation.split(".")[0]}</p>
    </div>
    <div className="card-body" style={{ margin: '0px', padding: '0px', maxHeight: '28vh', overflowY: 'auto' }}>
      <div className="progress-detail" style={{ fontSize: '13px', margin: '0px', padding: '0px' }}>
        <p className="counter">{el.recommendation.substring(el.recommendation.indexOf('.') + 1).trim()}</p>
      </div>
    </div>
  </div>

  <div className="card card-slide p-4" style={{ margin: '10px' }}>
      <Row>
        <Col>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%', justifyContent: 'center', margin: '0px', padding: '0px' }}>
            <p style={{ fontSize: '12px', paddingTop: '0px', margin: '0' }}>Total cost:</p>
            <h5 style={{ fontSize: '18px', margin: '0' }} className="counter">
              $ {el.lastMonth}
            </h5>
            
            <div style={el.dataMonth.symbol === '-' ? {  color: 'green',marginBottom:'15px' } : {  color: 'red',marginBottom:'15px'}}>
              {el.dataMonth.symbol === '-' ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
            </div>
            <div >
              <h5 style={el.dataMonth.symbol === '-' ? { color: 'green', fontSize: '15px', margin: '0',marginBottom:'15px' } : { color: 'red', fontSize: '15px', margin: '0',marginBottom:'15px' }}>{`(${el.dataMonth.symbol}$${el.dataMonth.cost})`}</h5>
            </div>
         
          </div>
        </Col>
      </Row>
    </div>
</Col>
  ))    :advisorCards && advisorCards.message &&cardCutP?.map((el, indice) => (

    <Col xl={6} key={indice}>
    <div className="card card-slide p-4" style={{ height: '45vh', overflowY: 'auto', margin: '15px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <img style={{ width: 40, marginTop: -5 }} src={`/${el.cloud}.png`} alt={el.cloud} />
        <h4 className="pb-1">{el.servicename}</h4>
      </div>
      <div style={{ marginTop: '5px', fontSize: '15px', color: 'black' }}>
        <p>{el.recommendation.split(".")[0]}</p>
      </div>
      <div className="card-body" style={{ margin: '0px', padding: '0px', maxHeight: '28vh', overflowY: 'auto' }}>
        <div className="progress-detail" style={{ fontSize: '13px', margin: '0px', padding: '0px' }}>
          <p className="counter">{el.recommendation.substring(el.recommendation.indexOf('.') + 1).trim()}</p>
        </div>
      </div>
    </div>
  
    <div className="card card-slide p-4" style={{ margin: '10px' }}>
      <Row>
        <Col>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%', justifyContent: 'center', margin: '0px', padding: '0px' }}>
            <p style={{ fontSize: '12px', paddingTop: '0px', margin: '0' }}>Total cost:</p>
            <h5 style={{ fontSize: '18px', margin: '0' }} className="counter">
              $ {el.lastMonth}
            </h5>
            
            <div style={el.dataMonth.symbol === '-' ? {  color: 'green',marginBottom:'3px' } : {  color: 'red',marginBottom:'3px'}}>
              {el.dataMonth.symbol === '-' ? <ArrowCircleDownIcon /> : <ArrowCircleUpIcon />}
            </div>  
            <div >
              <h5 style={el.dataMonth.symbol === '-' ? { color: 'green', fontSize: '15px', margin: '0',marginBottom:'3px' } : { color: 'red', fontSize: '15px', margin: '0',marginBottom:'3px' }}>{`(${el.dataMonth.symbol}$${el.dataMonth.cost})`}</h5>
            </div>
         
          </div>
        </Col>
      </Row>
    </div>
  </Col>
)):
  


  ( <div style={{  display: 'flex',
  alignItems: 'center',   
     justifyContent: 'center',fontSize:'25px',
     height: '40vh',  }}>There are not enough costs available to perform analysis.      
     </div> 
     ) 
  }       
 
<div style={{marginTop:'3px'}}> <Paginado cardPerPage={cardPerPage}  allCards={arrayNamesInSearch.length > 0 ? arrayNamesInSearch?.length :advisorCards.message?.length} paginado={paginado} setCurrentPage={setCurrentPage} currentPage={currentPage}/>     </div>
  
        
            </Row> )
            
            :( <div style={{  display: 'flex',
     alignItems: 'center',   
        justifyContent: 'center',fontSize:'25px',
        height: '40vh',  }}>Loading data...     
        </div> 
        ) 
              
              }
            





            </div>

        //     <Chart
        //     className="d-activity"
        //     options={options.options}
        //     series={options.series}
        //     type={options.chart.type}
           
        //     height="500"
        //   />
        )
        //  : <div style={{  display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',fontSize:'25px',
        // height: '50vh',  }}>There are not enough costs available to perform the forecast analysis.</div>
        
        
        
        }      
        </div>
        </Col>
     
<div style={{paddingTop:'10px'}}>
      


            </div>
            
        </>
     
    
    )
}

export default Advisor;