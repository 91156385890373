import { getDataForConsolidatedCardFromDB,testAWSCredentialsToDB,setAWSCredentialsToDB ,testSnowflakeCredentialsToDB,testGcpCredentialsToDB,getCredentialsFromAccountFromDB,testAzureCredentialsToDB,getTopServiceByAccountDB,setScheduleRefreshToDB,getScheduleRefreshToDB,setGcpCredentialsToDB} from "../../../helpers/accounts"
import {setTopService, credentialsValid,processingRequest,credentialsInvalid,resetStateCredentials,readyRequest,updateDataAccountToEdit,setStateAccountSavedSuccessfully,deleteDataTopService,setDataToConsolidatedCard,setScheduleRefreshSlice } from "./accountSlice"
import {getUsageFromThisMonthAndLast} from "../costing/costingThunks"
import { changeStateToast } from "../toasts/toastSlice"
import { openModal } from "../modal/modalSlice"
import {setDataSavedSuccesTrue} from "../costing/costingSlice"


export const testAWSConnection = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        const respJson = await testAWSCredentialsToDB(body)
        
        if(respJson.status ==='true'){
            dispatch(credentialsValid())
        }else{
            dispatch(credentialsInvalid()) 
        }
      
    }
}
export const testSnowflakeConnection = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        const respJson = await testSnowflakeCredentialsToDB(body)
        
        if(respJson.status ==='true'){
            dispatch(credentialsValid())
        }else{
            dispatch(credentialsInvalid()) 
        }
      
    }
}
export const testGcpConnection = (body)=>{
   // console.log('bodycredentials',body)
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        const respJson = await testGcpCredentialsToDB(body)
        
        if(respJson.status ==='true'){
            dispatch(credentialsValid())
        }else{
            dispatch(credentialsInvalid()) 
        }
      
    }
}


export const testAzureConnection = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        const respJson = await testAzureCredentialsToDB(body)
        
        if(respJson.status ===200){
            dispatch(credentialsValid())
        }else{
            dispatch(credentialsInvalid()) 
        }
      
    }
}

export const setNewAWSCredentials = (body)=>{
    return async( dispatch ) => {
       // console.log('body',body)
        dispatch( processingRequest() )
        
        const respJson = await setAWSCredentialsToDB(body)
        if(respJson.status === 'true'){
            const parsedBody = JSON.parse(body);
         //   console.log(parsedBody.data.cloudProvider,'parsedBody.cloudProvider')
        if(parsedBody.data.cloudProvider === 'Snowflake'){
            console.log('entra')
            dispatch(resetStateCredentials())            
            dispatch(getUsageFromThisMonthAndLast({tenantId: parsedBody.idtenant,user: parsedBody.user}));
            dispatch(setStateAccountSavedSuccessfully({state:true})) 
            dispatch(openModal("SnowflakeContract"))
        }else{
            dispatch(resetStateCredentials())
           
            dispatch(getUsageFromThisMonthAndLast({tenantId: parsedBody.idtenant,user: parsedBody.user}));
            dispatch(setStateAccountSavedSuccessfully({state:true})) 
        }
        }
        else{
            dispatch(readyRequest())
            dispatch(changeStateToast({state: true}))
        }              
    }
}

export const setNewGCPCredentials = (body)=>{
    return async( dispatch ) => {
        dispatch( processingRequest() )
        
        const respJson = await setGcpCredentialsToDB(body)
        if(respJson.status === 'true'){
            
            //const parsedBody = JSON.parse(body);
            //console.log('parsedBody',parsedBody)
           // console.log(parsedBody.data.cloudProvider,'parsedBody.cloudProvider')       
            dispatch(resetStateCredentials())           
            dispatch(getUsageFromThisMonthAndLast({tenantId: body.idtenant,user: body.user}));
            dispatch(setStateAccountSavedSuccessfully({state:true})) 
        
        }
        else{
            dispatch(readyRequest())
            dispatch(changeStateToast({state: true}))
        }              
    }
}


//con esta funcio le mando el accountId y me devulve los datos de las credenciales de esa cuenta para ser editados
export const getCredentialsFromAccount = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        const respJson = await getCredentialsFromAccountFromDB(body)
        
        if(respJson.status ==='true'){
            dispatch(updateDataAccountToEdit(respJson.data))

            dispatch(readyRequest())
        }else{
            dispatch(credentialsInvalid()) 
        }
      
    }
}


export const getTopServiceByAccount = (body)=>{
    return async( dispatch ) => {
        
        dispatch( processingRequest() )
        dispatch(deleteDataTopService())
        const respJson = await getTopServiceByAccountDB(body)
        if(respJson.status === 200){
            dispatch(readyRequest())
            dispatch(setTopService(respJson.data.data))
        }
        else if(respJson.status === 204){
            dispatch(readyRequest())
        }
        else{
            dispatch(readyRequest())
            console.log('Error')
        }
      
    }
}



export const getDataForConsolidatedCard = (body)=>{
    return async( dispatch ) => {
        dispatch( processingRequest() )
        const resp = await getDataForConsolidatedCardFromDB(body)
            if(resp.status === 200){
                const respJson = await resp.json()
                dispatch(readyRequest())
                dispatch(setDataToConsolidatedCard(respJson.data))
            }
            else if(resp.status === 204){
                dispatch(readyRequest())
            }
            else{
                dispatch(readyRequest())
                console.log('Error')
            }
      
    }
}



export const setScheduleRefresh = (body)=>{
    return async( dispatch ) => {
        dispatch( processingRequest() )
        const resp = await setScheduleRefreshToDB(body)
            if(resp.status === 200){
                dispatch(setDataSavedSuccesTrue())
                dispatch(readyRequest())
            }
            else if(resp.status === 204){
                dispatch(readyRequest())
            }
            else{
                dispatch(readyRequest())
            }
      
    }
}


export const getScheduleRefresh = (body)=>{
    return async( dispatch ) => {
        dispatch( processingRequest() )
        const resp = await getScheduleRefreshToDB(body)
            if(resp.status === 200){
                const respJson = await resp.json()
                dispatch(setScheduleRefreshSlice(respJson.data))
                dispatch(readyRequest())
            }
            else if(resp.status === 204){
                dispatch(readyRequest())
            }
            else{
                dispatch(readyRequest())
            }
      
    }
}